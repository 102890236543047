.stu_area {
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-bottom: 2rem;
  &:nth-of-type(even) {
    background-color: #eee;
  }
  &_title {
    text-align: center;
    .headline {
      border-bottom: 0;
      h1, h2, h3 {
        font-size: 34px;
        padding-bottom: 20px;
      }
    }
  }
  &_news {
    .list-append {
      .list-entries {
        margin-bottom: 0 !important;
      }
      .list-pagination {
        margin-bottom: 40px;
      }
    }
  }
  &_dates {
    .ap-list-content {
      margin-bottom: 0;
    }
    .calendar-sheet {
      height: 100%;
    }
    .list-entry {
      width: 50%;
      display: inline-block;
      padding-right: 45px;
      padding-left: 15px;
      @media (max-width: $screen-mobile-max) {
        width: 100%;
      }
      .ap-teaser {
        background: #F9F9F9;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        @media (max-width: $screen-mobile-max) {
          flex-wrap: wrap;
        }
        .teaser-visual {
          margin-bottom: 0;
          padding-left: 0;
        }
        .teaser-body {
          margin-top: 15px;
          padding-right: 0;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .ap-list-group {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .fully {
              display: flex;
              flex-direction: column;
              padding-right: 15px;
              .teaser-date {
                order: 1
              }
              .teaser-title {
                order: 2
              }
            }
            .teaser-btn {
              align-self: flex-end;
            }
          }

        }
      }
    }
  }
  &_partner {
    .ap-slick-slider {
      margin-bottom: 0;
    }
  }
}
