.stu_header {
  margin-bottom: 45px;
  &_start {
    margin-bottom: 0;
  }
  @media (max-width: $screen-mobile-max) {
    border-bottom: 15px solid $main-theme;
  }
  &_bistumslinks {
    margin-bottom: 0;
  }
  &_logoarea {
    transition: max-height 2s ease;
    max-height: 250px;
    overflow: hidden;
    .bis-slider {
      display: flex;
    }
  }
  &_logo {
    width: 95px;
    margin-top: -10px;
    margin-bottom: -1px; // $nav-header-space-bottom
    @media (max-width: 767px) {
      margin-top: 5px;
    }
  }
  &_text {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 0;
    padding-bottom: 40px;
    @media (max-width: $screen-mobile-max) {
      padding-bottom: 30px;
    }
    .head { // Bischhöfliches Gymnasium
      min-height: 0;
      > * {
        color: $text-content;
        font-size: 1.5rem;
        margin-bottom: 0;
      }
    }
    .text {
      margin-top: 0 !important;
      h1, h2, h3, h4, h5 { // Sankt Ursula
        color: $main-theme;
        font-size: 3rem;
        margin-bottom: 0;
        font-weight: 800;
      }
      p { // Geilenkirchen
        font-size: 1.5rem;
        margin-top: -0.2rem;
        margin-bottom: 0;
        font-weight: 400;
      }
    }
  }
  &_secondnav {
    position: absolute;
    bottom: 0;
    margin-bottom: 5px;
    padding-right: 30px;
    ul.links {
      li>a {
        font-size: 12px;
      }
    }
  }
  .ap-slick-slider {
    margin-bottom: 0;
  }
  .nav-sidelogo {
    .img-responsive {
      width: 400px;
      padding: 1rem;
    }
  }
  .isfixed {
    .stu_header_logoarea {
      max-height: 0;
      transition: max-height 0s;
    }
  }
  .breadcrumbs {
    ul.breadcrumb {
      padding: 1rem 0;
    }
  }
}

.ap-header .head-navbar {
  background-color: $main-theme;
  .nav {
    @media (max-width: $screen-mobile-max) {
      & > li {
        & > a {
          background-color: $main-theme;
          &.dropdown-toggle::after {
            background-color: transparent !important;
          }
        }
        &:hover > a,
        &.active > a {
          background-color: $nav-toggle-bg-active;
        }
        &.open > a {
          background-color: #fff;
          &.dropdown-toggle::after {
            color: $main-theme !important;
          }
        }
      }
      .head {
        padding-left: 15px;
        h1, h2, h3 ,h4 ,h5 {
          font-size: 1.3rem;
          font-weight: 600;
          margin-top: 1rem;
        }
      }
    }
  }
}
