@charset "UTF-8";
/**********
 * Theme file: sytle-aachen-variables.scss
 *
 * Bistum Aachen CSS - Common SASS variable settings.
 */
/**********
 * Theme file: all-variables.scss
 *
 * ALL Bistuemers - Common SASS variable settings.
 */
/**********
 * Source file: apollo-variables.scss
 *
 * Variable definitions.
 * These is the minimal set of variables required to compile the Apollo SCSS.
 */
/**
 * General theme colors:
 *
 * Main theme color: #009bab
 * Theme hover color: #00565f
 * Theme complementary color: #00565f
 */
/**
 * Colors available for backgrounds:
 */
/* box-grey-light: #eeeeee */
/**
 * Colors available for coloring buttons, lists:
 */
/* color-grey-light: #eeeeee */
/* Arrows */
html.ap-keyboard-nav .slick-slider .slick-arrow::before,
.slick-slider:hover .slick-arrow::before {
  opacity: 0.5;
  transition: opacity 0.5s linear;
}
html.ap-keyboard-nav .slick-slider .slick-arrow:hover::before, html.ap-keyboard-nav .slick-slider .slick-arrow:focus::before,
.slick-slider:hover .slick-arrow:hover::before,
.slick-slider:hover .slick-arrow:focus::before {
  opacity: 1;
}
html.ap-keyboard-nav .slick-slider .slick-dots,
.slick-slider:hover .slick-dots {
  opacity: 1;
  transition: opacity 0.5s linear;
}

.slick-arrow {
  position: absolute;
  display: block;
  height: 40px;
  width: 40px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 1100;
}
.slick-arrow:hover, .slick-arrow:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-arrow.slick-disabled::before {
  opacity: 0.25;
}
.slick-arrow::before {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  background-size: 40px 40px;
  opacity: 0;
  transition: opacity 0.5s linear;
}

.slick-prev {
  left: 30px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: 30px;
}
.slick-prev::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNTYiIGhlaWdodD0iMjU2Ij4NCiAgPHBhdGggZD0iTTI1NSAxMjhhMTI3IDEyNyAwIDEgMS0yNTQtLjEgMTI3IDEyNyAwIDAgMSAyNTMuOS4xeiIvPg0KICA8cGF0aCBmaWxsPSIjZmZmIiBkPSJNMTQ0IDIyNGwzNi0zNi02MC02MCA2MC02MC0zNi0zNi05NiA5NnoiLz4NCjwvc3ZnPg0K");
}
[dir=rtl] .slick-prev::before {
  content: "";
}

.slick-next {
  right: 30px;
}
[dir=rtl] .slick-next {
  left: 30px;
  right: auto;
}
.slick-next::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNTYiIGhlaWdodD0iMjU2Ij4NCiAgPHBhdGggZD0iTTI1NSAxMjhhMTI3IDEyNyAwIDEgMS0yNTQtLjEgMTI3IDEyNyAwIDAgMSAyNTMuOS4xeiIvPg0KICA8cGF0aCBmaWxsPSIjZmZmIiBkPSJNMTEyIDIyNGwtMzYtMzYgNjAtNjAtNjAtNjAgMzYtMzYgOTYgOTZ6Ii8+DQo8L3N2Zz4NCg==");
}
[dir=rtl] .slick-next::before {
  content: "";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.slick-dots {
  position: absolute;
  bottom: 10px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  z-index: 1100;
  opacity: 0;
  transition: opacity 0.5s linear;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover::before, .slick-dots li button:focus::before {
  opacity: 1;
}
.slick-dots li button::before {
  content: " ";
  display: block;
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNTYiIGhlaWdodD0iMjU2Ij4NCiAgPHBhdGggZD0iTTI1NSAxMjhhMTI3IDEyNyAwIDEgMS0yNTQtLjEgMTI3IDEyNyAwIDAgMSAyNTMuOS4xeiIvPg0KICA8cGF0aCBmaWxsPSIjZmZmIiBkPSJNMjA4IDEyOGE4MCA4MCAwIDEgMS0xNjAgMCA4MCA4MCAwIDAgMSAxNjAgMHoiLz4NCjwvc3ZnPg==");
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button::before {
  color: #009bab;
  opacity: 1;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/**********
 * Source file: apollo-fonts.scss
 *
 * Font definitions.
 * Generated with the help of: https://google-webfonts-helper.herokuapp.com/
 */
/* fira-sans-300 - latin_latin-ext */
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Fira Sans Light"), local("FiraSans-Light"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/fira-sans-v8-latin_latin-ext-300.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/fira-sans-v8-latin_latin-ext-300.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* fira-sans-300italic - latin_latin-ext */
@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: 300;
  src: local("Fira Sans Light Italic"), local("FiraSans-LightItalic"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/fira-sans-v8-latin_latin-ext-300italic.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/fira-sans-v8-latin_latin-ext-300italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* fira-sans-regular - latin_latin-ext */
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Fira Sans Regular"), local("FiraSans-Regular"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/fira-sans-v8-latin_latin-ext-regular.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/fira-sans-v8-latin_latin-ext-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* fira-sans-italic - latin_latin-ext */
@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: 400;
  src: local("Fira Sans Italic"), local("FiraSans-Italic"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/fira-sans-v8-latin_latin-ext-italic.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/fira-sans-v8-latin_latin-ext-italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* fira-sans-700 - latin_latin-ext */
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Fira Sans Bold"), local("FiraSans-Bold"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/fira-sans-v8-latin_latin-ext-700.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/fira-sans-v8-latin_latin-ext-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* fira-sans-700italic - latin_latin-ext */
@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: 700;
  src: local("Fira Sans Bold Italic"), local("FiraSans-BoldItalic"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/fira-sans-v8-latin_latin-ext-700italic.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/fira-sans-v8-latin_latin-ext-700italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-300 - latin_latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: local("Montserrat Light"), local("Montserrat-Light"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/montserrat-v12-latin_latin-ext-300.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/montserrat-v12-latin_latin-ext-300.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-300italic - latin_latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  src: local("Montserrat Light Italic"), local("Montserrat-LightItalic"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/montserrat-v12-latin_latin-ext-300italic.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/montserrat-v12-latin_latin-ext-300italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-regular - latin_latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/montserrat-v12-latin_latin-ext-regular.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/montserrat-v12-latin_latin-ext-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-italic - latin_latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: local("Montserrat Italic"), local("Montserrat-Italic"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/montserrat-v12-latin_latin-ext-italic.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/montserrat-v12-latin_latin-ext-italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700 - latin_latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/montserrat-v12-latin_latin-ext-700.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/montserrat-v12-latin_latin-ext-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700italic - latin_latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  src: local("Montserrat Bold Italic"), local("Montserrat-BoldItalic"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/montserrat-v12-latin_latin-ext-700italic.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/montserrat-v12-latin_latin-ext-700italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-300 - latin_latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/open-sans-v13-latin_latin-ext-300.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/open-sans-v13-latin_latin-ext-300.woff") format("woff");
}
/* open-sans-300italic - latin_latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: local("Open Sans Light Italic"), local("OpenSansLight-Italic"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/open-sans-v13-latin_latin-ext-300italic.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/open-sans-v13-latin_latin-ext-300italic.woff") format("woff");
}
/* open-sans-regular - latin_latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/open-sans-v13-latin_latin-ext-regular.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/open-sans-v13-latin_latin-ext-regular.woff") format("woff");
}
/* open-sans-italic - latin_latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/open-sans-v13-latin_latin-ext-italic.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/open-sans-v13-latin_latin-ext-italic.woff") format("woff");
}
/* open-sans-700 - latin_latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/open-sans-v13-latin_latin-ext-700.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/open-sans-v13-latin_latin-ext-700.woff") format("woff");
}
/* open-sans-700italic - latin_latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/open-sans-v13-latin_latin-ext-700italic.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/open-sans-v13-latin_latin-ext-700italic.woff") format("woff");
}
/* prompt-300 - latin-ext_latin */
@font-face {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 300;
  src: local("Prompt Light"), local("Prompt-Light"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/prompt-v1-latin-ext_latin-300.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/prompt-v1-latin-ext_latin-300.woff") format("woff");
}
/* prompt-300italic - latin-ext_latin */
@font-face {
  font-family: "Prompt";
  font-style: italic;
  font-weight: 300;
  src: local("Prompt Light Italic"), local("Prompt-LightItalic"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/prompt-v1-latin-ext_latin-300italic.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/prompt-v1-latin-ext_latin-300italic.woff") format("woff");
}
/* prompt-regular - latin-ext_latin */
@font-face {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 400;
  src: local("Prompt"), local("Prompt-Regular"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/prompt-v1-latin-ext_latin-regular.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/prompt-v1-latin-ext_latin-regular.woff") format("woff");
}
/* prompt-italic - latin-ext_latin */
@font-face {
  font-family: "Prompt";
  font-style: italic;
  font-weight: 400;
  src: local("Prompt Italic"), local("Prompt-Italic"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/prompt-v1-latin-ext_latin-italic.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/prompt-v1-latin-ext_latin-italic.woff") format("woff");
}
/* prompt-700 - latin-ext_latin */
@font-face {
  font-family: "Prompt";
  font-style: normal;
  font-weight: 700;
  src: local("Prompt Bold"), local("Prompt-Bold"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/prompt-v1-latin-ext_latin-700.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/prompt-v1-latin-ext_latin-700.woff") format("woff");
}
/* prompt-700italic - latin-ext_latin */
@font-face {
  font-family: "Prompt";
  font-style: italic;
  font-weight: 700;
  src: local("Prompt Bold Italic"), local("Prompt-BoldItalic"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/prompt-v1-latin-ext_latin-700italic.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/prompt-v1-latin-ext_latin-700italic.woff") format("woff");
}
/* roboto-300 - latin_latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/roboto-v18-latin_latin-ext-300.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/roboto-v18-latin_latin-ext-300.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-300italic - latin_latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/roboto-v18-latin_latin-ext-300italic.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/roboto-v18-latin_latin-ext-300italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-regular - latin_latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/roboto-v18-latin_latin-ext-regular.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/roboto-v18-latin_latin-ext-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-italic - latin_latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: local("Roboto Italic"), local("Roboto-Italic"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/roboto-v18-latin_latin-ext-italic.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/roboto-v18-latin_latin-ext-italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin_latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/roboto-v18-latin_latin-ext-700.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/roboto-v18-latin_latin-ext-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700italic - latin_latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/roboto-v18-latin_latin-ext-700italic.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/roboto-v18-latin_latin-ext-700italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* titillium-web-300 - latin_latin-ext */
@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 300;
  src: local("Titillium Web Light"), local("TitilliumWeb-Light"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/titillium-web-v6-latin_latin-ext-300.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/titillium-web-v6-latin_latin-ext-300.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* titillium-web-300italic - latin_latin-ext */
@font-face {
  font-family: "Titillium Web";
  font-style: italic;
  font-weight: 300;
  src: local("Titillium Web Light Italic"), local("TitilliumWeb-LightItalic"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/titillium-web-v6-latin_latin-ext-300italic.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/titillium-web-v6-latin_latin-ext-300italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* titillium-web-regular - latin_latin-ext */
@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 400;
  src: local("Titillium Web Regular"), local("TitilliumWeb-Regular"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/titillium-web-v6-latin_latin-ext-regular.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/titillium-web-v6-latin_latin-ext-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* titillium-web-italic - latin_latin-ext */
@font-face {
  font-family: "Titillium Web";
  font-style: italic;
  font-weight: 400;
  src: local("Titillium Web Italic"), local("TitilliumWeb-Italic"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/titillium-web-v6-latin_latin-ext-italic.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/titillium-web-v6-latin_latin-ext-italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* titillium-web-700 - latin_latin-ext */
@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 700;
  src: local("Titillium Web Bold"), local("TitilliumWeb-Bold"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/titillium-web-v6-latin_latin-ext-700.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/titillium-web-v6-latin_latin-ext-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* titillium-web-700italic - latin_latin-ext */
@font-face {
  font-family: "Titillium Web";
  font-style: italic;
  font-weight: 700;
  src: local("Titillium Web Bold Italic"), local("TitilliumWeb-BoldItalic"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/titillium-web-v6-latin_latin-ext-700italic.woff2") format("woff2"), url("../../../../system/modules/org.opencms.apollo.theme/fonts/titillium-web-v6-latin_latin-ext-700italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/**********
 * Source file: apollo-basics.scss
 *
 * Basic font size, margin definitions.
 */
html {
  height: 100vh;
  /* set viewport constraint */
  min-height: 100%;
  /* enforce height */
}

* {
  border-radius: 0;
}

html {
  font-size: 10px;
  /* base font size for rem units */
}
@media (max-width: 991px) {
  html {
    font-size: 12px;
  }
}
html.opencms-page-editor {
  padding-top: 52px;
}
html *:focus,
html .btn:focus,
html .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button:focus,
.ap.type-wallsio:not(.external-cookie-notice) html .wallsio-load-more-button:focus,
html .dropdown > a:focus,
html .dropdown-menu > a:focus {
  outline: none;
  box-shadow: none;
}
html.ap-keyboard-nav *,
html.ap-keyboard-nav .dropdown > a,
html.ap-keyboard-nav .dropdown-menu > a {
  /* // this may have been required but apparently is no londer needed for safari (AK: 15.02.18)
  @media screen and (-webkit-min-device-pixel-ratio:0) {
      // force non-standard outline style for webkit
      &:focus {
          outline-style: auto;
      }
  }
  */
}
html.ap-keyboard-nav *:focus,
html.ap-keyboard-nav .dropdown > a:focus,
html.ap-keyboard-nav .dropdown-menu > a:focus {
  outline-color: #00565f;
  outline-width: 3px;
  outline-style: solid;
  outline-offset: -2px;
  box-shadow: inset 0px 0px 4px 1px #00565f, 0px 0px 7px 2px #00565f;
  z-index: 155000;
}

body,
.ap-default-font,
.ap-download-list.dl-list-compact .dl-content h3,
.ap-contact.compact h3 {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.5;
}

.lead,
.ap-lead {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 1.4;
}
@media (min-width: 768px) {
  .lead,
.ap-lead {
    font-size: 2rem;
  }
}

body {
  color: #585f69;
  background-color: #fff;
}
body #apollo-page {
  max-width: 100vw;
  overflow: hidden;
}

/* Headlines */
h1, .h1, .ap-header .bis-site .sitename h2,
h2, .h2, .onclick-activation .oa-heading,
h3, .h3, .direktorium .rangH .dheader, .ap-compact .teaser-title,
h4, .h4, .direktorium .rangF .dheader, .image-info .subtitle.header, .image-info .subtitle.hcentered,
h5, .h5,
h6, .h6 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #585f69;
  margin-top: 0;
  margin-bottom: 10px;
  text-shadow: none;
}

.ap-plain h3, .ap-plain .h3, .ap-plain .direktorium .rangH .dheader, .direktorium .rangH .ap-plain .dheader, .ap-plain .ap-compact .teaser-title, .ap-compact .ap-plain .teaser-title,
.ap-plain h4, .ap-plain .h4, .ap-plain .direktorium .rangF .dheader, .direktorium .rangF .ap-plain .dheader, .ap-plain .image-info .subtitle.header, .image-info .ap-plain .subtitle.header, .ap-plain .image-info .subtitle.hcentered, .image-info .ap-plain .subtitle.hcentered,
.ap-plain h5, .ap-plain .h5,
.ap-plain h6, .ap-plain .h6 {
  margin-bottom: 5px;
}

h1, .h1, .ap-header .bis-site .sitename h2 {
  font-size: 2.8rem;
  line-height: 1.25;
}

h2, .h2, .onclick-activation .oa-heading {
  font-size: 2.4rem;
  line-height: 1.25;
}

h3, .h3, .direktorium .rangH .dheader, .ap-compact .teaser-title {
  font-size: 2rem;
  line-height: 1.25;
}

h4, .h4, .direktorium .rangF .dheader, .image-info .subtitle.header, .image-info .subtitle.hcentered {
  font-size: 1.8rem;
  line-height: 1.25;
}

h5, .h5 {
  font-size: 1.8rem;
  line-height: 1.25;
}

h6, .h6 {
  font-size: 1.8rem;
  line-height: 1.25;
}

@media (max-width: 991px) {
  h1, .h1, .ap-header .bis-site .sitename h2 {
    font-size: 2.4rem;
  }

  h2, .h2, .onclick-activation .oa-heading {
    font-size: 2.2rem;
  }
}
/* Tiny MCE fixes for inline editing, otherwise line hight changes */
body .mce-content-body p, body .mce-content-body div {
  line-height: 1.5;
}
body .mce-content-body .lead, body .mce-content-body.lead, body .mce-content-body.lead p, body .mce-content-body.lead div {
  line-height: 1.4;
}
body .mce-content-body h1 {
  line-height: 1.25;
}
body .mce-content-body h2 {
  line-height: 1.25;
}
body .mce-content-body h3 {
  line-height: 1.25;
}
body .mce-content-body h4 {
  line-height: 1.25;
}
body .mce-content-body h5 {
  line-height: 1.25;
}
body .mce-content-body h6 {
  line-height: 1.25;
}

/* Headline with underline effect */
.headline {
  display: block;
  margin-bottom: 20px;
}

.headline {
  border-bottom: 1px dotted #eeeeee;
}

.headline h2,
.headline h3,
.headline h4 {
  display: inline-block;
  margin-bottom: -2px;
  padding-bottom: 5px;
  border-bottom: 2px solid #009bab;
}

/* Links */
a, a:active {
  color: #009bab;
}
a:focus, a:hover {
  color: #00565f;
  transition: color 0.33s, background-color 0.33s, border-color 0.33s;
}
a:hover {
  outline: none;
}

a.external::after {
  font-family: FontAwesome;
  content: " ";
}

.no-external a.external::after,
.ap-iconbox a.external::after,
.ap-image-section a.external::after {
  content: "";
  display: none;
}

a, a:focus {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a.uncolored, a.uncolored:active {
  color: #585f69;
}
a.uncolored:focus, a.uncolored:hover {
  color: #00565f;
}
a.uncolored.fully:focus, a.uncolored.fully:hover {
  text-decoration: none;
  color: #585f69;
}

/* Lazy loading image background - Animated SVG */
/*
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" version="1" viewBox="0 0 16 16">
  <style>
    @keyframes kr{from{transform: rotate(0)}to{transform: rotate(360deg)}}
  </style>
  <g style="animation-name:kr;animation-duration:2s;animation-iteration-count:infinite;animation-timing-function:linear;transform-origin:50% 50%;width:16px;height:16px">
    <path fill="none" stroke="#000" stroke-dasharray="32.4" stroke-linecap="round" stroke-width="2.1" d="M8 1.125A6.875 6.875 0 1 1 1.125 8" style="animation-name:kr;animation-duration:1.33s;animation-iteration-count:infinite;animation-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transform-origin:50% 50%"/>
  </g>
</svg>
*/
@media not print {
  img.lazyloading, img.lazyload {
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmVyc2lvbj0iMSIgdmlld0JveD0iMCAwIDE2IDE2Ij4NCiAgPHN0eWxlPg0KICAgIEBrZXlmcmFtZXMga3J7ZnJvbXt0cmFuc2Zvcm06IHJvdGF0ZSgwKX10b3t0cmFuc2Zvcm06IHJvdGF0ZSgzNjBkZWcpfX0NCiAgPC9zdHlsZT4NCiAgPGcgc3R5bGU9ImFuaW1hdGlvbi1uYW1lOmtyO2FuaW1hdGlvbi1kdXJhdGlvbjoyczthbmltYXRpb24taXRlcmF0aW9uLWNvdW50OmluZmluaXRlO2FuaW1hdGlvbi10aW1pbmctZnVuY3Rpb246bGluZWFyO3RyYW5zZm9ybS1vcmlnaW46NTAlIDUwJTt3aWR0aDoxNnB4O2hlaWdodDoxNnB4Ij4NCiAgICA8cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiMwMDAiIHN0cm9rZS1kYXNoYXJyYXk9IjMyLjQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyLjEiIGQ9Ik04IDEuMTI1QTYuODc1IDYuODc1IDAgMSAxIDEuMTI1IDgiIHN0eWxlPSJhbmltYXRpb24tbmFtZTprcjthbmltYXRpb24tZHVyYXRpb246MS4zM3M7YW5pbWF0aW9uLWl0ZXJhdGlvbi1jb3VudDppbmZpbml0ZTthbmltYXRpb24tdGltaW5nLWZ1bmN0aW9uOmN1YmljLWJlemllcigwLjQsIDAsIDAuMiwgMSk7dHJhbnNmb3JtLW9yaWdpbjo1MCUgNTAlIi8+DQogIDwvZz4NCjwvc3ZnPg==") no-repeat scroll 50% 50% #f6f6f6;
    background-size: 24px 24px;
    background-color: #f6f6f6;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3) inset;
    opacity: 0.25;
  }
}
img.lazyloaded {
  background: initial;
  box-shadow: initial;
}

/* "Reveal" effect added to images in the main content sections */
main img.lazyloaded,
aside img.lazyloaded {
  opacity: 1;
  transition: opacity 750ms;
}

/* Make image boxes get the correct size before loading the actual image  */
.image-src-box {
  width: 100%;
}
.image-src-box img {
  width: 100%;
}
@media screen {
  .image-src-box.presized:not(.use-ratio) {
    position: relative;
    height: 0;
    display: block;
  }
  .image-src-box.presized:not(.use-ratio) img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
  .image-src-box.presized.use-ratio {
    aspect-ratio: auto;
    width: 100%;
    height: auto;
  }
  .image-src-box.presized.use-ratio img {
    height: auto;
  }
}
@media print {
  .image-src-box.presized {
    padding-bottom: 0 !important;
  }
}

.noise-image, .ap-meta::after,
.ap-meta-info::after,
.ap-anchor::after,
.ap-container::after,
.ap-edit-info::after {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-repeat: repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyBAMAAADsEZWCAAAAHlBMVEUAAAD///////////////////////////////////8kfJuVAAAACnRSTlMAIgQNCB4bERUYt5wRYgAAA+ZJREFUOMt9lEtT2lAYhr9zciHZnXMSSNjlBJFml0Cx7S7gBd1BQMFdAmp1lwTsZReKqN0laK3+205nOp1OZ9p/8M7zzPMCnhKUUE0svfCVk46oyvXpt4ArM5CuzrRwzyrTnIrukkDg+WRYW76zIeozWVAlRKO945nz6uDkEIkVGAUDOHgR3swn5uohb3po1tQtL6SfUc/9CC2yRQYo+JLAHBqINeiqBHn3zDFB1W1r15BDJiOUbyF5jY+3/cHUrMFClHitfRtmrGK6DQulG2Y6SDfG0FhosaPssV1rbcFL1oFveqeWlCMXcrcYEtTrXpJUU3WkPwiBI/rZggKJh4i+FYSujc83JFzeEPDKo/mpDi6OqZ2YbqyK291ifUBrDLno6YWCSoCIZILGohC3SbSR9gce/6RUvwCCRmj164nh+wFrTS4UxKMVTsEAb9q9b9Y2qYd7vid+zxmiLrkK3TMguO0lfkGGr09u1pvOWBAAleY3lgZ/kCoRoEltIuSVreVyBNFlPzfq6HLBbeqrJEe5pLLv7nMP5H9NgOpVUfGJRL5Ymb5ulRS+f7YqrfLkCfY7GerK5J5W6PUtKn9EbzqFR0RSBVHTJ7ojnuIdJFDitd6FboaE8gyB1m8mERY5eTk+EUj4GuPpqH5d8zCACJr9xlJaMwBUtc1BKArgWeIY7HMLF92tonQJ9Tz1M1brB4FlHLnwNr5VE87ueubbR4hzZZGiO1LscBUQ5OIhPnGfoSMWXigMzAx8RnkPIJ7hDHaooS4kwNsBOLp9RD5kGBTlvDUTPC0X9zXYVfMO53Y5JSiAXU5dEjvKaZJo6zM0kMiF2XiVxwaQDhxjvHzsAuwsM36nbk8V2ClttYBsLQRy9BlZVEPykyAfuIpDdXIRgetNqb3XidzolhoQ7nZvxqUhBwTwhJcXcmWtorRfuesyrkOhQhX6BeBHs45FphhEu44Ogc8PkTJ63Rlw6AkM7A+N0zvh9c0D0wp/IL63wY5qwP9VHTThhp6GXBR6lSogxkB9wMR3Awa/HfIOILyJY5dGUBsa+n+2+ePg/mDoElOfWPsfrsbuRpRpmjTnYD8nUvtYRA2S8qRVCX3J7S87HulB+TsyhBF7YwmZaCWErMLbqYyLFOD5hchsblKSjR34Wlbf99U4ZiSx4eyxPTwEFJVvVV88qB+Wrm3f+SkNZMG3tuvdcfD3MUBZ8nbdaWVvZIJuRcqSRsYkpONYA3XnsXzUPmlKmEiUlF41LCXdmM0hh19krQgL5KJULKdNwu54XN6HT5jKeN1wz6WpTCnH/mRbtockvocqqrDz9qch46kTvqqEv0v7AUw2CT/ylENXAAAAAElFTkSuQmCC);
}

.ap-list-tile,
.ap-image-section {
  width: 100%;
}

/* Default margins */
.element,
.ap {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .ap-xs {
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .ap-sm {
    margin-bottom: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .ap-md {
    margin-bottom: 20px;
  }
}
@media (min-width: 1200px) {
  .ap-lg {
    margin-bottom: 20px;
  }
}
/* Extend Bootstrap badges */
.badge {
  border-radius: 4px !important;
}

span.badge {
  color: #fff;
  font-weight: normal;
  padding: 4px 7px;
}

/* Colored badges */
span.badge-grey-light {
  color: #585f69;
  background: #eeeeee;
}

/* Row wrapper */
.area-body .ap-row-wrapper::before, .area-body .ap-row-wrapper::after {
  content: " ";
  display: table;
}
.area-body .ap-row-wrapper:after {
  clear: both;
}

/* Disabled elements, either in edit mode or because of configuration errors like missing API key */
.placeholder {
  position: relative;
  background-color: #eee;
}
.placeholder::after {
  position: absolute;
  display: block;
  bottom: 0;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  font-family: FontAwesome;
  font-weight: normal;
  font-size: 40px;
  color: rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -50%);
  text-align: center;
}

.opencms-page-editor .placeholder {
  cursor: pointer;
}
.opencms-page-editor .placeholder::before {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  content: attr(data-placeholder) " ";
  display: block;
  padding: 10px;
  text-align: center;
  background-color: #ffa826;
  color: #fff;
}
.opencms-page-editor .placeholder.error::before {
  background-color: #b31b34;
}

/* Basic Print support */
@media print {
  html {
    font-size: 8px;
  }

  header,
footer,
aside {
    display: none !important;
  }

  main {
    width: 100% !important;
  }
  main > .container > .row > div {
    width: 100%;
    max-width: initial;
  }

  body {
    font-size: 12pt;
    line-height: 1.35;
    padding: 0;
    margin: 0;
  }

  img,
.ap,
.element,
.ap-image,
.animated-box,
.image-outer-box,
.image-src-box,
.ap-teaser {
    page-break-inside: avoid;
  }
}
@media print {
  #apollo-sass-version::after {
    content: "print";
  }
}
@media screen {
  #apollo-sass-version::after {
    content: "screen";
  }
}

/**********
 * Source file: apollo-bootstrap.scss
 *
 * Bootstrap style adjustments.
 */
.tooltip {
  z-index: 150000;
  font-size: 1.4rem;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.5;
}
.tooltip .tooltip-inner {
  padding: 12px 16px;
  background-color: #222;
  color: #fff;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
  text-align: left;
}
.tooltip.top {
  margin-top: -6px;
  padding-right: 15px;
  padding-left: 15px;
}
.tooltip.top .tooltip-arrow {
  bottom: -5px;
  border-width: 10px 10px 0;
  border-top-color: #222;
  margin-left: -10px;
}

/* Extend Bootstrap label, used in the Release notes */
span.label {
  font-size: 11px;
  font-weight: normal;
  padding: 4px 7px;
  border-radius: 0;
}

/* Extend Bootstrap forms, adjustment for corners and colors */
.input-group .form-control,
select.form-control,
.form-control {
  box-shadow: none;
  border-radius: 0;
}

.input-group-addon {
  border-radius: 0;
  color: #585f69;
  background: #fff;
}

/* Extend Bootstrap alert, removes the round corners */
.alert {
  border-radius: 0;
}

/* Adjust Bootstrap modal */
.modal-body,
.modal-footer {
  padding: 1rem;
}

/**********
 * Source file: apollo-navigation.scss
 *
 * Styles for the header, footer and side navigation.
 */
/* The Apollo header */
.ap-header {
  margin-bottom: 20px;
  background: #fff;
  position: relative;
}
.ap-header .head-container:empty {
  display: none;
}
.ap-header .navbar-collapse {
  position: relative;
  border-top: 0;
}
.ap-header .head-navbar .nav-sidelogo {
  display: none;
}
@media (max-width: 991px) {
  .ap-header .navbar-header {
    float: none;
  }
  .ap-header .navbar-collapse {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .ap-header .navbar-collapse .container {
    padding-left: 0;
    padding-right: 0;
  }
  .ap-header .navbar-collapse.collapse {
    display: none !important;
  }
  .ap-header .navbar-collapse.collapse.in {
    display: block !important;
    overflow-y: auto !important;
  }
  .ap-header .head-visual {
    position: relative;
  }
  .ap-header .head-container:empty {
    display: block;
    min-height: 48px;
  }
  .ap-header .head-navbar {
    pointer-events: none;
    border-bottom: none;
  }
  .ap-header .head-navbar .nav-sidelogo {
    display: block;
  }
}
@media (min-width: 992px) {
  .ap-header .head {
    position: relative;
    min-height: 38px;
  }
  .ap-header .head-navbar {
    position: relative;
    bottom: 0;
    pointer-events: none;
    border-bottom: none;
    width: 100%;
  }
  .ap-header .head-container {
    border-bottom: none;
  }
  .ap-header .pullnav-true .head-navbar {
    position: absolute;
    z-index: 149999;
  }
  .ap-header .pullnav-true.isfixed .head-navbar,
.ap-header .pullnav-false .head-navbar,
.ap-header .pullnav-css .head-navbar {
    position: relative;
  }
}
.ap-header #nav-toggle-label {
  display: inherit;
  margin-bottom: 0;
  max-width: none;
  pointer-events: auto;
}
.ap-header #nav-toggle-box {
  display: none;
}
.ap-header .nav-toggle {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 38px;
  height: 38px;
  font-size: 0;
  text-indent: -9999px;
  box-shadow: none;
  border-radius: 0;
  border: none;
  cursor: pointer;
  transition: background 0.33s;
  color: #fff;
  background-color: #009bab;
  z-index: 150001;
  position: relative;
  float: right;
}
.ap-header .nav-toggle:focus, .ap-header .nav-toggle:hover {
  background: #00565f;
}
.ap-header .nav-toggle span {
  display: block;
  position: absolute;
  top: 18px;
  left: 8px;
  right: 8px;
  height: 2px;
  background: #fff;
  transition: background 0s 0.33s;
}
.ap-header .nav-toggle span::before, .ap-header .nav-toggle span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  content: "";
  transition-duration: 0.33s, 0.33s;
  transition-delay: 0.33s, 0s;
}
.ap-header .nav-toggle span::before {
  top: -8px;
  transition-property: top, transform;
}
.ap-header .nav-toggle span::after {
  bottom: -8px;
  transition-property: bottom, transform;
}
.ap-header .nav-toggle.active {
  background-color: #00565f;
}
.ap-header .nav-toggle.active span {
  background: none;
}
.ap-header .nav-toggle.active span::before, .ap-header .nav-toggle.active span::after {
  transition-delay: 0s, 0.33s;
}
.ap-header .nav-toggle.active span::before {
  top: 0;
  transform: rotate(45deg);
}
.ap-header .nav-toggle.active span::after {
  bottom: 0;
  transform: rotate(-45deg);
}
@media (min-width: 992px) {
  .ap-header .nav-toggle {
    display: none;
  }
}
@media (max-width: 496px) {
  .ap-header .nav-toggle {
    width: 29px;
    height: 29px;
  }
  .ap-header .nav-toggle span {
    top: 13px;
    left: 6px;
    right: 6px;
    height: 2px;
  }
  .ap-header .nav-toggle span::before, .ap-header .nav-toggle span::after {
    height: 2px;
  }
  .ap-header .nav-toggle span::before {
    top: -6px;
  }
  .ap-header .nav-toggle span::after {
    bottom: -6px;
  }
}
@media (max-width: 991px) {
  .ap-header .head-toggle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    pointer-events: none;
  }
  .ap-header .head.no-visual > .head-visual > .head-toggle {
    position: relative;
    bottom: initial;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
@media (min-width: 992px) {
  .ap-header .head.no-visual > .head-navbar {
    position: relative;
  }
}

/* Fixed headers */
.ap-header .sticky > .isfixed .hidden-fixed,
.ap-header .sticky > .notfixed .hidden-notfixed {
  display: none;
}

@keyframes fixHeader {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 0.97;
    transform: none;
  }
}
@media (min-width: 992px) {
  html.opencms-page-editor .ap-header .sticky.csssetting > .isfixed, .ap-header .sticky.csssetting > .isfixed,
.ap-header .sticky.always > .isfixed,
.ap-header .sticky.upscroll > .isfixed {
    position: fixed;
    top: 0;
    padding-top: 0;
    width: 100%;
    z-index: 150000;
    animation-duration: 1s;
    animation-name: fixHeader;
    opacity: 0.97;
    background: #fff;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
  }
  html.opencms-page-editor .ap-header .sticky.csssetting > .isfixed,
html.opencms-page-editor .ap-header .sticky.always > .isfixed,
html.opencms-page-editor .ap-header .sticky.upscroll > .isfixed {
    padding-top: 52px;
  }
}
/* Adjustments for model group editor */
html.opencms-group-editor.opencms-page-editor .ap-header .sticky > .isfixed .hidden-fixed,
html.opencms-group-editor.opencms-page-editor .ap-header .sticky > .notfixed .hidden-notfixed {
  display: block;
  opacity: 0.5;
  background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
}
@media (min-width: 992px) {
  html.opencms-group-editor.opencms-page-editor .ap-header .hidden-md,
html.opencms-group-editor.opencms-page-editor .ap-header .hidden-lg {
    display: block !important;
    opacity: 0.5;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
  }
}
html.opencms-group-editor .area-head {
  margin-bottom: 0;
}

/* Responsive navigation */
.ap-header .nav {
  pointer-events: auto;
}
.ap-header .nav > li {
  position: relative;
}
.ap-header .nav > li > a {
  background-color: transparent;
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
  position: relative;
  bottom: 0;
  padding: 9px 20px;
}
.ap-header .nav > li > a::after {
  position: absolute;
  display: inline-block;
  font-family: FontAwesome;
}
@media (min-width: 992px) {
  .ap-header .nav {
    float: right;
  }
  .ap-header .nav > li > a {
    margin-bottom: 0;
  }
  .ap-header .nav > li > a:focus {
    background-color: transparent;
    border-bottom: 0;
  }
  .ap-header .nav > li.dropdown > a {
    cursor: default;
  }
  .ap-header .nav > li.dropdown > a::after {
    line-height: 20px;
    content: "";
    right: 2px;
  }
  .ap-header .nav > li.dropdown > a.nav-label {
    cursor: pointer;
  }
  .ap-header .nav > li.dropdown > a.nav-handle {
    position: absolute;
    top: 0;
    display: inline-block;
    background: transparent;
    border: none;
    width: 100%;
    right: 0;
    padding-left: 0;
    padding-right: 0;
    z-index: 999;
  }
  .ap-header .nav > li.dropdown > a.nav-handle::after {
    content: "";
  }
  .ap-header .nav > li.dropdown .dropdown-menu {
    animation-name: fadeTopMenu;
    animation-duration: 0.33s;
    animation-fill-mode: both;
    transform-origin: top;
  }
  .ap-header .nav > li.dropdown .dropdown-submenu .dropdown-menu {
    animation-name: fadeRightMenu;
    transform-origin: left;
  }
  .ap-header .nav > li > a:focus,
.ap-header .nav > li > a:hover, .ap-header .nav > li.active > a, .ap-header .nav > li.active > a:focus, .ap-header .nav > li.active > a:hover, .ap-header .nav > li.open > a, .ap-header .nav > li.open > a:focus, .ap-header .nav > li.open > a:hover {
    color: #009bab;
    background-color: #fff;
    margin-bottom: 0;
    border-bottom: none;
  }
  .ap-header .nav > li.active > a {
    color: #fff;
    background-color: transparent;
  }
  .ap-header .nav > li > a.nav-handle, .ap-header .nav > li.active > a.nav-handle {
    background-color: transparent;
  }
  .ap-header .nav > li.open > a.nav-handle {
    display: none;
  }
  .ap-header .nav > li.open-left > .dropdown-menu {
    right: 0;
    left: auto;
  }
  .ap-header .nav > li li.dropdown-submenu.open-left > .dropdown-menu,
.ap-header .nav > li li.dropdown-submenu.open-left li.dropdown-submenu > .dropdown-menu {
    left: auto;
    right: 200px;
    transform-origin: right;
  }
  .ap-header .nav > li li.dropdown-submenu.open-left li.dropdown-submenu.open-right > .dropdown-menu,
.ap-header .nav > li li.dropdown-submenu.open-left li.dropdown-submenu.open-right li.dropdown-submenu > .dropdown-menu {
    left: 100%;
    right: auto;
    transform-origin: left;
  }
}
.ap-header .nav #searchButtonHeader .search-form {
  padding: 20px;
}
.ap-header .nav #searchButtonHeader .form-control {
  height: initial;
  padding: 7px;
  line-height: 2.1rem;
  width: 300px;
}
.ap-header .nav #searchButtonHeader.dropdown > a {
  padding-right: 2px;
}
.ap-header .nav #searchButtonHeader.dropdown > a::after {
  content: "";
  right: 0;
}
@media (max-width: 991px) {
  .ap-header .nav {
    margin: 0;
    float: none !important;
  }
  .ap-header .nav #searchButtonHeader {
    display: none;
  }
  .ap-header .nav > li {
    float: none;
  }
  .ap-header .nav > li > a {
    background: #fff;
    font-size: 15px;
    padding: 8px 15px;
    bottom: 0px;
    border-bottom: solid 1px #eeeeee;
  }
  .ap-header .nav li.dropdown > a.dropdown-toggle::after {
    content: "";
    font-size: 22.75px;
    padding-left: 7px;
    padding-right: 7px;
    top: 4px;
    border-radius: 0;
    line-height: 28px;
    width: 28px;
    background-color: #fff;
    color: #fff;
    right: 15px;
    transform: rotate(0);
    transition: transform 0.33s;
  }
  .ap-header .nav li.dropdown > a.dropdown-toggle.nav-handle {
    position: absolute;
    right: 0;
    width: 50px;
    top: 0;
    height: 28px;
    display: inline-block;
    background: transparent;
    border: none;
    padding-left: 0;
    padding-right: 0;
  }
  .ap-header .nav li.dropdown > a.dropdown-toggle.nav-handle::after {
    right: auto;
  }
  .ap-header .nav li.dropdown.open > a::after {
    transform: rotate(-180deg);
    transition: transform 0.33s;
    background-color: #009bab;
  }
  .ap-header .nav li.active.open > a, .ap-header .nav li.active > a {
    color: #fff;
    background-color: #fff;
    border-color: #eeeeee;
  }
  .ap-header .nav li > a:focus, .ap-header .nav li.active > a:focus, .ap-header .nav li.open > a, .ap-header .nav li.open > a:focus {
    color: #009bab;
    background-color: #eeeef0;
    border-color: #eeeeee;
  }
  .ap-header .nav li.active > a.nav-handle:focus {
    color: #fff;
  }
  .ap-header .nav li > a.nav-handle, .ap-header .nav li.active > a.nav-handle, .ap-header .nav li.open > a.nav-handle {
    background-color: transparent;
  }
  .ap-header .nav li:first-child > a {
    border-top: solid 1px #eeeeee;
  }
  .ap-header .nav li > .dropdown-menu {
    transition: max-height 0.33s cubic-bezier(0, 0.65, 0.35, 1);
    max-height: 0;
    display: block;
    overflow: hidden;
  }
  .ap-header .nav li > .dropdown-menu div, .ap-header .nav li > .dropdown-menu li {
    display: block;
  }
  .ap-header .nav li.open > .dropdown-menu {
    transition: max-height 0.66s ease-in;
    max-height: 2000px;
  }
}

@media (min-width: 992px) {
  html.ap-keyboard-nav .ap-header .nav > li.dropdown > a:not(.nav-label):focus::after {
    content: "";
    background-color: #009bab;
    color: #eeeef0;
    border-radius: 0;
    right: -3px;
    line-height: 20px;
    padding-left: 5px;
    padding-right: 5px;
    outline: none;
  }
  html.ap-keyboard-nav .ap-header .nav > li.dropdown > a.nav-label:focus::after {
    content: "";
  }
}

@media (min-width: 992px) {
  html.noscript .ap-header .nav > li.dropdown:hover > .dropdown-menu {
    display: block;
  }
}
@media (max-width: 991px) {
  html.noscript .ap-header #nav-toggle-box:checked ~ div.mainheader .head-navbar .collapse {
    display: block !important;
  }
  html.noscript .ap-header .nav > li.dropdown {
    display: block;
  }
  html.noscript .ap-header .nav > li.dropdown > .dropdown-menu {
    max-height: none;
  }
  html.noscript .ap-header .nav > li.dropdown > .dropdown-menu li {
    display: block;
  }
}

.head-overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background-color: #000;
  z-index: 149999;
  opacity: 0;
  transition: opacity 0.5s, height 0.001s 0.5s, width 0.001s 0.5s;
}

@media (max-width: 991px) {
  #apollo-page {
    position: relative;
    right: 0;
    transition: right 0.5s;
  }

  .ap-header .mainheader .head-navbar {
    position: fixed;
    right: -265px;
    transition: right 0.5s;
    top: 0;
    height: 100vh;
    background-color: #eeeeee;
    z-index: 150000;
    pointer-events: auto;
    overflow-x: hidden;
  }
  .ap-header .mainheader .head-navbar .navbar-collapse {
    background-color: #fff;
    padding-top: 68px;
  }
  .ap-header .mainheader .head-navbar .navbar-collapse .container {
    width: 265px;
  }
  .ap-header .mainheader .head-navbar .navbar-collapse.collapse {
    display: block !important;
  }
  .ap-header .mainheader .head-navbar .navbar-collapse.collapsing {
    transition: height 0.01s;
  }
  .ap-header .mainheader .head-toggle {
    position: fixed;
    z-index: 150001;
    width: 100%;
    top: 15px;
  }
  .ap-header .mainheader .head-toggle {
    padding-right: 30px;
  }

  .ap-header.has-sidelogo .mainheader .head-navbar .navbar-collapse {
    padding-top: 0;
  }

  html.opencms-page-editor .ap-header .mainheader .head-navbar .navbar-collapse {
    padding-top: 120px;
  }
  html.opencms-page-editor .ap-header .mainheader .head-toggle {
    top: 67px;
  }
  html.opencms-page-editor .ap-header.has-sidelogo .mainheader .head-navbar .navbar-collapse {
    padding-top: 52px;
  }

  html.active-nav {
    overflow-x: hidden;
    overflow: hidden;
  }

  html.noscript #nav-toggle-box:checked ~ div.mainheader .head-navbar,
html.active-nav .ap-header .mainheader .head-navbar {
    right: 0;
  }
  html.noscript #nav-toggle-box:checked ~ div.mainheader li.dropdown > a.nav-handle::after,
html.noscript #nav-toggle-box:checked ~ div.mainheader li.dropdown > a::after,
html.active-nav .ap-header .mainheader li.dropdown > a.nav-handle::after,
html.active-nav .ap-header .mainheader li.dropdown > a::after {
    right: 30px;
  }
  html.noscript #nav-toggle-box:checked ~ div.mainheader li.dropdown a,
html.active-nav .ap-header .mainheader li.dropdown a {
    padding-right: 45px !important;
  }
  html.noscript #nav-toggle-box:checked ~ div.mainheader .head-overlay,
html.active-nav .ap-header .mainheader .head-overlay {
    width: 100vw;
    height: 100vh;
    opacity: 0.75;
    transition: opacity 0.5s;
  }
}
@media (max-width: 991px) {
  .ap-header .mainheader .head-navbar {
    right: -265px;
  }
}
@media (max-width: 991px) and (min-width: 265px) {
  .ap-header .mainheader .head-navbar {
    right: -60vw;
  }
}
@media (max-width: 991px) {
  .ap-header .mainheader .head-navbar .navbar-collapse .container {
    min-width: 265px;
    width: 60vw;
  }
}
@media (max-width: 767px) and (min-width: 265px) {
  .ap-header .mainheader .head-navbar {
    right: -90vw;
  }
}
@media (max-width: 767px) {
  .ap-header .mainheader .head-navbar .navbar-collapse .container {
    width: 90vw;
  }
}
/* Dropdown menus in the header */
.ap-header .nav .dropdown-menu {
  border: none;
  padding: 0;
  border-radius: 0;
}
.ap-header .nav .dropdown-menu > li > a {
  color: #585f69;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #eeeeee;
  padding: 5px 25px;
}
@media (max-width: 991px) {
  .ap-header .nav .dropdown-menu > li > a {
    color: #585f69;
    background-color: #fff;
  }
}
.ap-header .nav .dropdown-menu > li.open > a {
  color: #009bab;
  background-color: #eeeef0;
}
.ap-header .nav .dropdown-menu > li.active > a {
  color: #585f69;
  background-color: #eeeef0;
}
@media (min-width: 992px) {
  .ap-header .nav .dropdown-menu > li.active > a:focus, .ap-header .nav .dropdown-menu > li.active > a:hover,
.ap-header .nav .dropdown-menu > li > a:focus,
.ap-header .nav .dropdown-menu > li > a:hover {
    color: #009bab;
    background-color: #eeeef0;
  }
}
.ap-header .nav .dropdown-menu > li a.nav-handle {
  background-color: transparent !important;
}
.ap-header .nav .dropdown-menu .dropdown-submenu {
  position: relative;
}
.ap-header .nav .dropdown-menu .dropdown-submenu > .dropdown-menu {
  top: 0px;
  left: 100%;
  margin-top: -2px;
  margin-left: 0px;
}
@media (min-width: 992px) {
  .ap-header .nav .dropdown-menu .dropdown-submenu:hover > .dropdown-menu {
    display: block;
  }
}
.ap-header .nav .dropdown-menu .dropdown-submenu > a.dropdown-toggle::after {
  position: absolute;
  display: inline-block;
  font-family: FontAwesome;
}
.ap-header .nav .dropdown-menu .dropdown-submenu > a.nav-handle {
  position: absolute;
  display: inline-block;
}
@media (min-width: 992px) {
  .ap-header .nav .dropdown-menu {
    min-width: 200px;
    z-index: 150000;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    border-top: solid 2px #009bab;
    border-bottom: solid 2px #009bab;
  }
  .ap-header .nav .dropdown-menu .dropdown-submenu > a.dropdown-toggle::after {
    right: 9px;
    font-size: 13px;
    content: "";
    font-weight: normal;
  }
  .ap-header .nav .dropdown-menu .dropdown-submenu > a.nav-handle {
    background: transparent;
    border: none;
    width: 20px;
    padding-left: 0;
    padding-right: 0;
    top: 0;
    right: 0;
  }
  .ap-header .nav .dropdown-menu .dropdown-submenu.open-left a.dropdown-toggle::after {
    content: "";
  }
  .ap-header .nav .dropdown-menu .dropdown-submenu.open-left .dropdown-submenu.open-right a.dropdown-toggle::after {
    content: "";
  }
  .ap-header .nav .dropdown-menu li a {
    font-size: 13px;
    font-weight: normal;
    padding: 8px 20px;
  }
  .ap-header .nav .dropdown-menu .sidebar-nav li a {
    padding: 8px 20px 8px 2px;
  }
  .ap-header .nav .dropdown-menu li:last-child > a {
    border-bottom: none;
  }
}
@media (max-width: 991px) {
  .ap-header .nav .dropdown-menu {
    position: static;
    box-shadow: none;
    float: none;
    width: auto;
  }
  .ap-header .nav .dropdown-menu > li a {
    font-size: 13px;
    line-height: 20px;
    font-weight: normal;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .ap-header .nav .dropdown-menu > li > a {
    padding-left: 30px;
  }
  .ap-header .nav .dropdown-menu > li > ul > li > a {
    padding-left: 45px;
  }
  .ap-header .nav .dropdown-menu > li > ul > li > ul > li a {
    padding-left: 60px;
  }
  .ap-header .nav .dropdown-menu > li > ul > li > ul > li > ul > li a {
    padding-left: 75px;
  }
  .ap-header .nav .dropdown-menu > li > ul > li > ul > li > ul > li > ul > li a {
    padding-left: 90px;
  }
  .ap-header .nav .dropdown-menu > li > ul > li > ul > li > ul > li > ul > li > ul > li a {
    padding-left: 105px;
  }
  .ap-header .nav .dropdown-menu > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li a {
    padding-left: 120px;
  }
  .ap-header .nav .dropdown-menu > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li a {
    padding-left: 135px;
  }
  .ap-header .nav .dropdown-menu > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li a {
    padding-left: 150px;
  }
  .ap-header .nav .dropdown-menu .open {
    display: block;
  }
  .ap-header .nav .dropdown-menu .dropdown-submenu > .dropdown-menu {
    margin-top: 0px;
    border-left: 0;
    border-right: 0;
  }
  .ap-header .nav .dropdown-menu.pull-right {
    float: none !important;
  }
}

/* Megamenu */
#megamenu-editor .container {
  padding: 0;
}
#megamenu-editor .container .ap-header .mega-menu .container {
  width: inherit;
}
#megamenu-editor .dropdown .dropdown-menu {
  max-height: none;
  display: block;
  overflow: visible;
  position: relative;
}

.mega-menu .nav > li.dropdown.mega {
  position: static;
}
.mega-menu .container {
  position: relative;
}
.mega-menu .dropdown-menu {
  left: auto;
}
.mega-menu .dropdown-megamenu {
  background-color: #fff;
}
.mega-menu .dropdown-megamenu .ap-linksequence {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .mega-menu {
    box-shadow: none;
  }
  .mega-menu .dropdown-megamenu .sidebar-nav {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  .mega-menu .ap-section,
.mega-menu .sidebar-nav,
.mega-menu .ap-sidebar-nav {
    margin-bottom: 0;
  }
  .mega-menu .dropdown-megamenu .sidebar-nav {
    border: none;
  }
  .mega-menu .dropdown-megamenu .sidebar-nav li {
    border: none;
  }
  .mega-menu .dropdown-megamenu .sidebar-nav li a {
    padding-left: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #eeeeee;
  }
}
@media (min-width: 992px) {
  .mega-menu div.dropdown-menu {
    width: calc(100% + 10px);
    margin-left: -5px;
    position: absolute;
    left: 0;
  }
  .mega-menu .nav .dropdown-megamenu {
    padding: 20px 20px 0 20px;
  }
}
.mega-menu .ap-linksequence.boxed li > a {
  border: 1px solid #eeeeee;
  border-top: none;
  background-color: #fff;
  color: #585f69;
  padding: 5px 25px;
  margin: 0;
}
.mega-menu .ap-linksequence.boxed li > a:focus, .mega-menu .ap-linksequence.boxed li > a:hover {
  background-color: #eeeef0;
  color: #009bab;
}
@media (min-width: 992px) {
  .mega-menu .ap-linksequence.boxed li:first-child > a {
    border-top: 1px solid #eeeeee;
  }
  .mega-menu .ap-linksequence.boxed li:last-child > a {
    border-bottom: 1px solid #eeeeee;
  }
}
@media (max-width: 991px) {
  .mega-menu .ap-linksequence.boxed li > a {
    border-right: none;
    border-left: none;
  }
}

/* Breadcrumb navigation */
.breadcrumbs {
  overflow: hidden;
  padding: 0 0 0 0;
  border-bottom: solid 1px #eeeeee;
  background: #eee;
}
@media (max-width: 991px) {
  .breadcrumbs {
    display: none;
  }
}
.breadcrumbs > .container {
  padding-left: 15px;
  padding-right: 15px;
}
.breadcrumbs h1 {
  color: #585f69;
  font-size: 22px;
  margin-top: 8px;
}
.breadcrumbs .breadcrumb {
  padding-right: 0;
  background: none;
  font-size: 13px;
  line-height: 1.25;
  margin-bottom: 0;
}
.breadcrumbs .breadcrumb a {
  color: #585f69;
}
.breadcrumbs .breadcrumb li.active,
.breadcrumbs .breadcrumb li a:focus,
.breadcrumbs .breadcrumb li a:hover {
  color: #00565f;
  text-decoration: none;
}
@media (max-width: 496px) {
  .breadcrumbs h1.pull-left,
.breadcrumbs ul.pull-right {
    width: 100%;
    text-align: center;
  }
  .breadcrumbs h1.pull-left {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Footer */
.area-foot {
  padding-top: 20px;
  background: transparent;
  color: #585f69;
}
.area-foot *:not(a):not(span):not(.btn) {
  color: #585f69;
}
.area-foot a:not(.btn) {
  color: #666;
}
.area-foot a:not(.btn):focus, .area-foot a:not(.btn):hover {
  color: #00565f;
}
.area-foot.subfoot,
.area-foot .subfoot {
  background: #c1c1c1;
  color: #585f69;
  padding-top: 20px;
  padding-bottom: 20px;
}
.area-foot.subfoot *:not(a):not(span),
.area-foot .subfoot *:not(a):not(span) {
  color: #585f69;
}
.area-foot.subfoot a:not(.btn),
.area-foot .subfoot a:not(.btn) {
  color: #666;
}
.area-foot.subfoot a:not(.btn):focus, .area-foot.subfoot a:not(.btn):hover,
.area-foot .subfoot a:not(.btn):focus,
.area-foot .subfoot a:not(.btn):hover {
  color: #00565f;
}

/* "Back to top" icon on the lower right of display */
#topcontrol {
  color: #fff;
  background: #009bab;
  z-index: 149999;
  width: 38px;
  height: 38px;
  font-size: 22.75px;
  line-height: 34px;
  position: relative;
  right: 30px !important;
  bottom: 15px !important;
  border-radius: 0 !important;
  pointer-events: none;
}
#topcontrol.visible {
  pointer-events: auto;
}
#topcontrol:hover {
  background-color: #00565f;
  transition: all 0.33s;
}
#topcontrol::after {
  width: 38px;
  content: "";
  position: absolute;
  text-align: center;
  font-family: FontAwesome;
}
@media (max-width: 496px) {
  #topcontrol {
    width: 29px;
    height: 29px;
  }
  #topcontrol::after {
    width: 29px;
    line-height: 26px;
  }
}

/* Sidebar navigation */
.sidebar-nav {
  border: 1px solid #eeeeee;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .sidebar-nav {
    margin-left: -15px;
    margin-right: -15px;
    border-left: none;
    border-right: none;
  }
}
.sidebar-nav > li:first-child {
  border-top: none;
}
.sidebar-nav li {
  background: #fff;
  border-top: 1px solid #eeeeee;
  border-bottom: none;
  border-left: none;
  border-right: none;
  margin-bottom: 0;
  font-size: 13px;
  padding: 0;
}
.sidebar-nav li:hover {
  background: #eeeef0;
}
.sidebar-nav li a {
  cursor: pointer;
  display: block;
  padding: 8px 20px 8px 2px;
}
.sidebar-nav li a .fa {
  padding-right: 8px;
}
.sidebar-nav li a.nav-handle {
  display: inline-block;
  width: 20px;
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  top: 0;
  right: 0;
}
.sidebar-nav li a:not(.btn) {
  color: #585f69;
}
.sidebar-nav li a:not(.btn):focus, .sidebar-nav li a:not(.btn):hover {
  color: #009bab;
  background: #eeeef0;
  text-decoration: none;
}
.sidebar-nav li .list-group-item {
  position: relative;
}
.sidebar-nav li ul {
  padding: 0;
  list-style: none;
}
.sidebar-nav li ul li {
  border: none;
  border-top: 1px solid #eeeeee;
}
.sidebar-nav li:first-child, .sidebar-nav li:last-child {
  border-radius: 0;
}
.sidebar-nav li > ul li a {
  padding-left: 4px;
}
.sidebar-nav li > ul > li > ul li a {
  padding-left: 6px;
}
.sidebar-nav li > ul > li > ul > li > ul li a {
  padding-left: 8px;
}
.sidebar-nav li > ul > li > ul > li > ul > li > ul li a {
  padding-left: 10px;
}
.sidebar-nav li.currentpage {
  background: #eeeef0;
}
.sidebar-nav li.currentpage > a {
  color: #585f69;
}
.sidebar-nav li.currentpage:focus, .sidebar-nav li.currentpage:hover {
  border-color: #eeeeee;
  background: #eeeef0;
}
.sidebar-nav li.currentpage:focus > a, .sidebar-nav li.currentpage:hover > a {
  color: #009bab;
}
.sidebar-nav .nav-toggle::after {
  top: 7px;
  right: 10px;
  color: #585f69;
  font-size: 13px;
  position: absolute;
  font-weight: normal;
  display: inline-block;
  font-family: FontAwesome;
  pointer-events: none;
  content: "";
  transform: rotate(-180deg) translate(0px, 2px);
  transition: transform 0.33s;
}
.sidebar-nav .nav-toggle.collapsed::after {
  transform: rotate(0) translate(0px, 0px);
  transition: transform 0.33s;
}

/* Options for sidebar */
@media (max-width: 767px) {
  .ap-sidebar-nav > .sidebar-nav {
    box-shadow: none;
  }
  .ap-sidebar-nav > .sidebar-nav .nav-toggle::after {
    right: 25px;
  }
}
.ap-sidebar-nav > .sidebar-nav {
  box-shadow: none;
}
@media (min-width: 768px) {
  .ap-sidebar-nav > .sidebar-nav {
    border: none;
  }
}
@media (max-width: 767px) {
  .ap-sidebar-nav > .sidebar-nav > li a {
    padding-left: 15px;
  }
}

/**********
 * Source file: apollo-navigation-visual.scss
 *
 * Styles for the header navigation.
 */
/**********
 * Source file: apollo-tiles.scss
 *
 * Styles used for floating tiles and squares.
 * Used e.g. by the image gallery.
 */
/**
 * Squares
 *
 * For the basics of this square tile solution, see
 * http://stackoverflow.com/questions/20456694/grid-of-responsive-squares
 */
/* Base square definition */
.ap-square {
  float: left;
  position: relative;
  width: 100%;
  padding: 0 0 100% 0 !important;
}

/* Required inner square class, the "position: absolute" does the trick */
.ap-square .content {
  position: absolute !important;
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 5;
}

/* Allows to set the margin in the squares */
.ap-square.square-m-2 .content {
  height: calc(100% - 4px);
  width: calc(100% - 4px);
  margin: 2px;
}

.ap-square.square-m-5 .content {
  height: calc(100% - 10px);
  width: calc(100% - 10px);
  margin: 5px;
}

.ap-square.square-m-10 .content {
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  margin: 10px;
}

.ap-square.square-m-20 .content {
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  margin: 20px;
}

@media (max-width: 767px) {
  .ap-square.square-xs-12.square-m-2 .content {
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    margin: 15px;
  }

  .ap-square.square-xs-12.square-m-5 .content {
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    margin: 15px;
  }

  .ap-square.square-xs-12.square-m-10 .content {
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    margin: 15px;
  }

  .ap-square.square-xs-12.square-m-20 .content {
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    margin: 15px;
  }
}
/* Table needed for aligment of content inside square */
.ap-square .table {
  display: table;
  height: 100%;
  width: 100%;
}

/* Table cell needed for aligment of content inside square */
.ap-square .table-cell {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  width: 100%;
}

.ap-square .content .text-overlay .square-copyright {
  display: inline-block;
  color: #000;
  background-color: rgba(255, 255, 255, 0.6);
  opacity: 0.8;
  position: absolute;
  bottom: 0;
  pointer-events: none;
  z-index: 7;
  box-shadow: none;
  right: 0;
  left: initial;
  text-align: right;
  padding: 2px 4px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 8px;
  line-height: 1;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  background-color: transparent;
}

/* Tile basic definition */
.ap-tile {
  float: left;
  position: relative;
  min-height: 1px;
  width: calc(100% - 0px);
  margin: 0px;
}

/* Fixed with columns base */
.fixcol, .fixcol-lg-150,
.fixcol-lg-150-rest, .fixcol-md-150,
.fixcol-md-150-rest, .fixcol-sm-150,
.fixcol-sm-150-rest, .fixcol-xs-150,
.fixcol-xs-150-rest, .fixcol-lg-125,
.fixcol-lg-125-rest, .fixcol-md-125,
.fixcol-md-125-rest, .fixcol-sm-125,
.fixcol-sm-125-rest, .fixcol-xs-125,
.fixcol-xs-125-rest, .fixcol-lg-75,
.fixcol-lg-75-rest, .fixcol-md-75,
.fixcol-md-75-rest, .fixcol-sm-75,
.fixcol-sm-75-rest, .fixcol-xs-75,
.fixcol-xs-75-rest {
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

/* xs screen size squares */
.square-xs-12 {
  width: 100%;
  padding-bottom: 100% !important;
}

.square-xs-6 {
  width: 50%;
  padding-bottom: 50% !important;
}

/* xs screen size tiles */
.tile-xs-8 {
  width: calc(66.6666666667% - 0.5px);
  margin: 0;
}

.tile-xs-6 {
  width: calc(50% - 0.5px);
  margin: 0;
}

.tile-xs-4 {
  width: calc(33.3333333333% - 0.5px);
  margin: 0;
}

.tile-xs-3 {
  width: calc(25% - 0.5px);
  margin: 0;
}

.tile-xs-2 {
  width: calc(16.6666666667% - 0.5px);
  margin: 0;
}

/* xs screen size fixcols */
.fixcol-xs-75 {
  width: 105px;
}

.fixcol-xs-75-rest {
  width: calc(100% - 105px);
}

.fixcol-xs-125 {
  width: 155px;
}

.fixcol-xs-125-rest {
  width: calc(100% - 155px);
}

.fixcol-xs-150 {
  width: 180px;
}

.fixcol-xs-150-rest {
  width: calc(100% - 180px);
}

@media (min-width: 768px) {
  /* sm screen size squares */
  .square-sm-12 {
    width: 100%;
    padding-bottom: 100% !important;
  }

  .square-sm-6 {
    width: 50%;
    padding-bottom: 50% !important;
  }

  .square-sm-4 {
    width: 33.3333333333%;
    padding-bottom: 33.3333333333% !important;
  }

  .container.square-m-2 {
    width: 724px;
    padding: 0;
  }

  .container.square-m-5 {
    width: 728px;
    padding: 0;
  }

  .container.square-m-10 {
    width: 740px;
    padding: 0;
  }

  .container.square-m-20 {
    width: 760px;
    padding: 0;
  }

  /* sm screen size tiles squares */
  .tile-sm-8 {
    width: calc(66.6666666667% - 0.5px);
    margin: 0;
  }

  .tile-sm-6 {
    width: calc(50% - 0.5px);
    margin: 0;
  }

  .tile-sm-4 {
    width: calc(33.3333333333% - 0.5px);
    margin: 0;
  }

  .tile-sm-3 {
    width: calc(25% - 0.5px);
    margin: 0;
  }

  .tile-sm-2 {
    width: calc(16.6666666667% - 0.5px);
    margin: 0;
  }

  /* sm screen size fixcols */
  .fixcol-sm-75 {
    width: 105px;
  }

  .fixcol-sm-75-rest {
    width: calc(100% - 105px);
  }

  .fixcol-sm-125 {
    width: 155px;
  }

  .fixcol-sm-125-rest {
    width: calc(100% - 155px);
  }

  .fixcol-sm-150 {
    width: 180px;
  }

  .fixcol-sm-150-rest {
    width: calc(100% - 180px);
  }
}
@media (min-width: 992px) {
  /* md screen size squares */
  .square-md-12 {
    width: 100%;
    padding-bottom: 100% !important;
  }

  .square-md-6 {
    width: 50%;
    padding-bottom: 50% !important;
  }

  .square-md-4 {
    width: 33.3333333333%;
    padding-bottom: 33.3333333333% !important;
  }

  .square-md-3 {
    width: 25%;
    padding-bottom: 25% !important;
  }

  .container.square-m-2 {
    width: 944px;
    padding: 0;
  }

  .container.square-m-5 {
    width: 948px;
    padding: 0;
  }

  .container.square-m-10 {
    width: 960px;
    padding: 0;
  }

  .container.square-m-20 {
    width: 980px;
    padding: 0;
  }

  /* md screen size tiles */
  .tile-md-8 {
    width: calc(66.6666666667% - 0.5px);
    margin: 0;
  }

  .tile-md-6 {
    width: calc(50% - 0.5px);
    margin: 0;
  }

  .tile-md-4 {
    width: calc(33.3333333333% - 0.5px);
    margin: 0;
  }

  .tile-md-3 {
    width: calc(25% - 0.5px);
    margin: 0;
  }

  .tile-md-2 {
    width: calc(16.6666666667% - 0.5px);
    margin: 0;
  }

  /* md screen size fixcols */
  .fixcol-md-75 {
    width: 105px;
  }

  .fixcol-md-75-rest {
    width: calc(100% - 105px);
  }

  .fixcol-md-125 {
    width: 155px;
  }

  .fixcol-md-125-rest {
    width: calc(100% - 155px);
  }

  .fixcol-md-150 {
    width: 180px;
  }

  .fixcol-md-150-rest {
    width: calc(100% - 180px);
  }
}
@media (min-width: 1200px) {
  /* lg screen size squares */
  .square-lg-12 {
    width: 100%;
    padding-bottom: 100% !important;
  }

  .square-lg-8 {
    width: 66.6666666667%;
    padding-bottom: 66.6666666667% !important;
  }

  .square-lg-6 {
    width: 50%;
    padding-bottom: 50% !important;
  }

  .square-lg-4 {
    width: 33.3333333333%;
    padding-bottom: 33.3333333333% !important;
  }

  .square-lg-3 {
    width: 25%;
    padding-bottom: 25% !important;
  }

  .square-lg-2 {
    width: 16.6666666667%;
    padding-bottom: 16.6666666667% !important;
  }

  .container.square-m-2 {
    width: 1144px;
    padding: 0;
  }

  .container.square-m-5 {
    width: 1148px;
    padding: 0;
  }

  .container.square-m-10 {
    width: 1160px;
    padding: 0;
  }

  .container.square-m-20 {
    width: 1180px;
    padding: 0;
  }

  /* lg screen size tiles */
  .tile-lg-8 {
    width: calc(66.6666666667% - 0.5px);
    margin: 0;
  }

  .tile-lg-6 {
    width: calc(50% - 0.5px);
    margin: 0;
  }

  .tile-lg-4 {
    width: calc(33.3333333333% - 0.5px);
    margin: 0;
  }

  .tile-lg-3 {
    width: calc(25% - 0.5px);
    margin: 0;
  }

  .tile-lg-2 {
    width: calc(16.6666666667% - 0.5px);
    margin: 0;
  }

  /* lg screen size fixcols */
  .fixcol-lg-75 {
    width: 105px;
  }

  .fixcol-lg-75-rest {
    width: calc(100% - 105px);
  }

  .fixcol-lg-125 {
    width: 155px;
  }

  .fixcol-lg-125-rest {
    width: calc(100% - 155px);
  }

  .fixcol-lg-150 {
    width: 180px;
  }

  .fixcol-lg-150-rest {
    width: calc(100% - 180px);
  }
}
/* hand optimized specials for 4x3 and 16x9 square tiles */
.ap-square.square-16-9.square-xs-12 {
  padding-bottom: calc(56.25% + 20px) !important;
}

.ap-square.square-4-3.square-xs-12 {
  padding-bottom: calc(75% + 10px) !important;
}

.ap-list-content.ap-square-list .square-item-20 {
  margin-left: -15px !important;
  margin-right: -15px !important;
  margin-top: -20px !important;
  margin-bottom: 20px !important;
}
@media (min-width: 768px) {
  .ap-list-content.ap-square-list .square-item-20 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .ap-square.square-4-3.square-xs-12, .ap-square.square-4-3.square-sm-12 {
    padding-bottom: 580px !important;
  }
  .ap-square.square-4-3.square-sm-6 {
    padding-bottom: 295px !important;
  }

  .ap-square.square-16-9.square-xs-12, .ap-square.square-16-9.square-sm-12 {
    padding-bottom: 428px !important;
  }
  .ap-square.square-16-9.square-sm-6 {
    padding-bottom: 223px !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .ap-square.square-4-3.square-xs-12, .ap-square.square-4-3.square-sm-12, .ap-square.square-4-3.square-md-12 {
    padding-bottom: 745px !important;
  }
  .ap-square.square-4-3.square-sm-6, .ap-square.square-4-3.square-md-6 {
    padding-bottom: 377px !important;
  }

  .ap-square.square-16-9.square-xs-12, .ap-square.square-16-9.square-sm-12, .ap-square.square-16-9.square-md-12 {
    padding-bottom: 546px !important;
  }
  .ap-square.square-16-9.square-sm-6, .ap-square.square-16-9.square-md-6 {
    padding-bottom: 282px !important;
  }
}
@media (min-width: 1200px) {
  .ap-square.square-4-3.square-sm-12, .ap-square.square-4-3.square-md-12, .ap-square.square-4-3.square-lg-12 {
    padding-bottom: 895px !important;
  }
  .ap-square.square-4-3.square-sm-8, .ap-square.square-4-3.square-md-8, .ap-square.square-4-3.square-lg-8 {
    padding-bottom: 600px !important;
  }
  .ap-square.square-4-3.square-sm-6, .ap-square.square-4-3.square-md-6, .ap-square.square-4-3.square-lg-6 {
    padding-bottom: 452px !important;
  }
  .ap-square.square-4-3.square-sm-4, .ap-square.square-4-3.square-md-4, .ap-square.square-4-3.square-lg-4 {
    padding-bottom: 300px !important;
  }
  .ap-square.square-4-3.square-sm-3, .ap-square.square-4-3.square-md-3, .ap-square.square-4-3.square-lg-3 {
    padding-bottom: 226px !important;
  }

  .ap-square.square-16-9.square-sm-12, .ap-square.square-16-9.square-md-12, .ap-square.square-16-9.square-lg-12 {
    padding-bottom: 654px !important;
  }
  .ap-square.square-16-9.square-sm-8, .ap-square.square-16-9.square-md-8, .ap-square.square-16-9.square-lg-8 {
    padding-bottom: 460px !important;
  }
  .ap-square.square-16-9.square-sm-6, .ap-square.square-16-9.square-md-6, .ap-square.square-16-9.square-lg-6 {
    padding-bottom: 354px !important;
  }
  .ap-square.square-16-9.square-sm-4, .ap-square.square-16-9.square-md-4, .ap-square.square-16-9.square-lg-4 {
    padding-bottom: 230px !important;
  }
  .ap-square.square-16-9.square-sm-3, .ap-square.square-16-9.square-md-3, .ap-square.square-16-9.square-lg-3 {
    padding-bottom: 177px !important;
  }
}
.ap-square .text-overlay {
  position: absolute;
  bottom: 0;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.65);
  width: 100%;
  z-index: 5;
}
.ap-square .text-overlay h2, .ap-square .text-overlay h3, .ap-square .text-overlay div {
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  font-size: 1.6rem;
  line-height: 1.2;
  margin: 0;
}
.ap-square .text-overlay div.teaser-date + h2,
.ap-square .text-overlay h2 + h3 {
  margin-top: 5px;
}
.ap-square .text-overlay div,
.ap-square .text-overlay h3 {
  font-size: 1.2rem;
}
.ap-square a:hover {
  text-decoration: none;
}
@media (max-width: 767px) {
  .ap-square.text-below-on-xs:not(.play) {
    padding: 0 !important;
  }
  .ap-square.text-below-on-xs:not(.play).square-4-3.square-xs-12, .ap-square.text-below-on-xs:not(.play).square-16-9.square-xs-12 {
    padding-bottom: 0 !important;
  }
  .ap-square.text-below-on-xs:not(.play) .content {
    height: auto !important;
    position: relative !important;
  }
  .ap-square.text-below-on-xs:not(.play) .content .centered.image {
    position: static;
    transition: none;
    transform: none;
  }
  .ap-square.text-below-on-xs:not(.play) .content .text-overlay {
    position: relative !important;
  }
  .ap-square.text-below-on-xs:not(.play) .image-outer-box,
.ap-square.text-below-on-xs:not(.play) .image-inner-box,
.ap-square.text-below-on-xs:not(.play) .image-src-box {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .ap-square.text-below-on-xs:not(.play) .text-overlay {
    background: #272727;
  }
}
.ap-square.min-height-px .text-overlay {
  min-height: calc(3.84rem + 20px);
}
.ap-square.ap-section.square-xs-6 .text-overlay h2, .ap-square.ap-section.square-xs-6 .text-overlay h3, .ap-square.ap-section.square-xs-6 .text-overlay div {
  font-size: 1.4rem;
}
.ap-square.ap-section.square-xs-6 .text-overlay h3 {
  font-size: 1.12rem;
}
.ap-square.ap-section.square-xs-6.min-height-px .text-overlay {
  min-height: calc(3.36rem + 20px);
}
@media (min-width: 768px) {
  .ap-square.min-height .text-overlay {
    min-height: 20%;
  }
  .ap-square.min-height.square-large .text-overlay {
    min-height: initial;
  }
  .ap-square.square-large .text-overlay {
    padding: 10px 20px;
  }
  .ap-square.square-large .text-overlay h2 {
    font-size: 2rem;
  }
  .ap-square.square-large .text-overlay div,
.ap-square.square-large .text-overlay h3 {
    font-size: 1.6rem;
  }
  .ap-square.square-small .text-overlay h3 {
    display: none;
  }
}
@media (min-width: 1200px) {
  .ap-square.square-large.square-4-3 .preview:not(.video) {
    width: 105%;
  }
  .ap-square.square-large.square-16-9 .preview:not(.video) {
    width: 102%;
  }
  .ap-square .text-overlay {
    padding: 8px 10px;
  }
  .ap-square.min-height .text-overlay {
    min-height: 40%;
  }
  .ap-square.min-height.square-large .text-overlay {
    min-height: 18.5%;
  }
  .ap-square.ap-section.square-xs-6.min-height-px .text-overlay {
    min-height: calc(3.36rem + 10px);
  }
}

/**********
 * Source file: apollo-animation.scss
 *
 * CSS animations.
 */
@media screen and (min-width: 768px) {
  /**
   * Shadow animation effect.
   * HTML structure:
   *
   * <div class="ap-shadow-animation">      <-- Effect trigger box
   *     <div classs="animated-box">        <-- Animated box
   *         ...
   *     </div>
   *     Other content
   * </div>
   *
   * This effect does two animations at once.
   * The effect trigger box gets the shadow, and the animated box is shrunk.
   **/
  html:not(.mobile) .ap-shadow-animation {
    transition: box-shadow 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  html:not(.mobile) .ap-shadow-animation:hover {
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
  }
  html:not(.mobile) .ap-shadow-animation:hover .teaser-body.animated,
html:not(.mobile) .ap-shadow-animation:hover .teaser-link.animated, html:not(.mobile) .ap-shadow-animation:hover.animated-box .text-box,
html:not(.mobile) .ap-shadow-animation:hover .animated-box .text-box {
    transform: scale(0.925);
  }
  html:not(.mobile) .ap-shadow-animation:hover.animated-box .animated-box {
    transform: initial;
  }
  html:not(.mobile) .ap-shadow-animation:hover .teaser-visual + .teaser-body.animated {
    transform-origin: center top;
  }
  html:not(.mobile) .ap-shadow-animation:hover .teaser-link.animated {
    left: 3.1%;
    bottom: 1.55%;
  }
  html:not(.mobile) .ap-shadow-animation .teaser-body.animated,
html:not(.mobile) .ap-shadow-animation .teaser-link.animated, html:not(.mobile) .ap-shadow-animation.animated-box .text-box,
html:not(.mobile) .ap-shadow-animation .animated-box .text-box {
    box-shadow: none;
    transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    z-index: 4;
  }

  /**
   * Static shadow effect.
   **/
  html:not(.mobile) .ap-shadow-static {
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
  }
  html:not(.mobile) .ap-shadow-static .teaser-body.animated,
html:not(.mobile) .ap-shadow-static .teaser-link.animated, html:not(.mobile) .ap-shadow-static.animated-box .text-box,
html:not(.mobile) .ap-shadow-static .animated-box .text-box {
    transform: scale(0.925);
  }
  html:not(.mobile) .ap-shadow-static.animated-box .animated-box {
    transform: initial;
  }
  html:not(.mobile) .ap-shadow-static .teaser-visual + .teaser-body.animated {
    transform-origin: center top;
  }
  html:not(.mobile) .ap-shadow-static .teaser-link.animated {
    left: 3.1%;
    bottom: 1.55%;
  }

  /**
   * Shadow inset animation effect ('sunken shadow').
   * HTML structure:
   *
   * <div class="ap-inset-animation">      <-- Effect trigger box
   *     <div classs="animated-box">       <-- Animated box
   *         ...
   *     </div>
   *     Other content
   * </div>
   *
   * This effect does two animations at once.
   * The effect trigger box gets the inset shadow, and the animated box is shrunk.
   **/
  html:not(.mobile) .ap-inset-animation {
    transition: box-shadow 0.35s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  html:not(.mobile) .ap-inset-animation:hover {
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3) inset;
  }
  html:not(.mobile) .ap-inset-animation:hover .teaser-body.animated,
html:not(.mobile) .ap-inset-animation:hover .teaser-link.animated,
html:not(.mobile) .ap-inset-animation:hover .animated-box {
    transform: scale(0.925);
  }
  html:not(.mobile) .ap-inset-animation:hover .teaser-link.animated {
    left: 3.1%;
    bottom: 1.55%;
  }
  html:not(.mobile) .ap-inset-animation .teaser-body.animated,
html:not(.mobile) .ap-inset-animation .teaser-link.animated,
html:not(.mobile) .ap-inset-animation .animated-box {
    box-shadow: none;
    transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    z-index: 4;
  }

  /**
   * Raise box effect.
   * HTML structure:
   *
   * <div class="ap-raise-animation">      <-- Effect trigger box
   *     <div classs="animated-box">       <-- Animated box
   *         ...
   *     </div>
   *     Other content
   * </div>
   **/
  html:not(.mobile) .ap-raise-animation:hover.animated-box,
html:not(.mobile) .ap-raise-animation:hover .animated-box {
    transform: scale(1.075);
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
  }
  html:not(.mobile) .ap-raise-animation:hover.animated-box .animated-box {
    transform: initial;
    box-shadow: initial;
  }
  html:not(.mobile) .ap-raise-animation:hover .teaser-body.animated,
html:not(.mobile) .ap-raise-animation:hover .teaser-link.animated,
html:not(.mobile) .ap-raise-animation:hover .text-box {
    transform: scale(0.93);
  }
  html:not(.mobile) .ap-raise-animation:hover .teaser-visual + .teaser-body.animated {
    transform-origin: center top;
  }
  html:not(.mobile) .ap-raise-animation:hover .teaser-link.animated {
    left: 3.1%;
    bottom: 1.55%;
  }
  html:not(.mobile) .ap-raise-animation .text-box {
    border: 0 solid #fff;
    transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  html:not(.mobile) .ap-raise-animation .teaser-body.animated,
html:not(.mobile) .ap-raise-animation .teaser-link.animated, html:not(.mobile) .ap-raise-animation.animated-box,
html:not(.mobile) .ap-raise-animation .animated-box {
    box-shadow: none;
    transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    z-index: 4;
  }

  /**
   * "Remove margins" effect.
   *
   * Can be used to achive an effect similar to "raise box" for images.
   * May be used as alternative when the image sits inside an "overflow: hidden" box.
   */
  html:not(.mobile) .ap-margin-animation:hover.animated-box,
html:not(.mobile) .ap-margin-animation:hover .animated-box {
    margin: 0 !important;
  }
  html:not(.mobile) .ap-margin-animation.animated-box,
html:not(.mobile) .ap-margin-animation .animated-box {
    box-shadow: none;
    transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    z-index: 4;
  }
}
/**
 * Button animation effect.
 * HTML structure:
 *
 * <div class="ap-button-animation">      <-- Effect trigger box
 *     <div classs="button-box">          <-- Place marker box
 *         <a href="..."></a>             <-- Animated link button
 *     </div>
 *     Other content
 * </div>
 **/
html .ap-button-animation .button-box > span,
html .ap-button-animation .button-box > a {
  bottom: 18px;
  position: absolute;
  display: inline-block;
  right: -10px;
  transition: right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), background-color 0.33s, border-color 0.33s;
}
html .ap-button-animation.ap-list-tile .button-box > span {
  right: 10px;
  bottom: 10px;
}
html .ap-button-animation .button-box > span {
  line-height: 2.1rem;
}
html .ap-button-animation .button-box {
  position: relative;
}

html:not(.mobile) .ap-button-animation:hover .button-box > span, html:not(.mobile) .ap-button-animation:hover .button-box > a {
  right: 10px;
}
html:not(.mobile) .ap-button-animation.ap-list-tile:hover .button-box > span {
  right: 20px;
}

/**
 * Ken Burns animation effect.
 * HTML structure:
 *
 * <div class="ap-kenburns-animation">    <-- Effect trigger box
 *     <div classs="image-outer-box">     <-- Efect limitation box
 *         <div class="image-inner-box">  <-- Animated box, should be directly in limitation box
 *             ...
 *         </div>
 *     </div>
 *     Other content
 * </div>
 **/
html:not(.mobile) .ap-kenburns-animation:hover .image-inner-box {
  transform: scale(1.25) rotate(2deg);
}
html:not(.mobile) .ap-kenburns-animation .image-inner-box {
  transition: transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 750ms;
}
html:not(.mobile) .ap-kenburns-animation.image-outer-box,
html:not(.mobile) .ap-kenburns-animation .image-outer-box {
  overflow: hidden;
  z-index: 5;
}

/**
 * Static image border
 **/
.ap-image-border .ap-image .image-outer-box,
.ap-image-border.ap-image .image-outer-box {
  border: 1px solid #eeeeee;
}

/**
 * Clickme / Showme animation.
 * This effect relies on jQuery to work, it is NOT pure CSS.
 * HTML structure:
 *
 * <div class="clickme-showme">    <-- Outer wrapper
 *     <div classs="clickme">      <-- Content initially hidden
 *         <!-- Some markup here -->
 *     </div>
 *     <div classs="showme">       <-- Content initially visible
 *         <!-- Some mearkup here -->
 *     </div>
 * </div>
 **/
.clickme-showme {
  cursor: pointer;
}
.clickme-showme > .clickme {
  display: none;
}
.clickme-showme > .showme {
  display: block;
}

/**
 * Menu animations used in the navigation.
 */
@keyframes fadeTopMenu {
  from {
    opacity: 0;
    transform: scaleY(0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeRightMenu {
  from {
    opacity: 0;
    transform: scaleX(0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeInMenu {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/**
 * Spinner animation, displays a rotating "currently loading" symbol.
 * Used e.g. by the list and the image gallery.
 */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.list-spinner,
.spinner {
  top: 45%;
  width: 30px;
  height: auto;
  font-size: 30px;
  position: absolute;
  transform: translateY(-50%);
  z-index: 1000;
  left: 50%;
}
.list-spinner, a .list-spinner,
.spinner,
a .spinner {
  color: #fff;
}
.list-spinner .fa-spinner,
.spinner .fa-spinner {
  animation: spinner 1s infinite;
  animation-timing-function: steps(4, end);
}

.spinnerInnerBox {
  position: relative;
  left: -150%;
  background-color: black;
  width: 300%;
  padding: 100%;
  border-radius: 12px;
  line-height: 0;
  opacity: 0.65;
}

.spinnerBottom {
  top: initial;
  position: fixed;
  bottom: 80px;
}

/* Speeding / slow down up animate.css effects */
.animated.quick {
  animation-duration: 0.5s;
}

.animated.slow {
  animation-duration: 4s;
}

/* Parallax Background effect with background cover image */
.ap-parallax-bg {
  background-image: none;
  background-position: 50% 0px;
  background-size: cover;
  background-clip: border-box;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

@media (min-width: 992px) {
  html:not(.mobile) .ap-parallax-bg {
    background-attachment: fixed;
  }
}

/**********
 * Source file: apollo-blog.scss
 *
 * Styles used by the "Blog Article" element
 */
.ap-decoy.disabled {
  background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
}

/**********
 * Source file: apollo-button.scss
 *
 * Bootstrap extended, colored buttons.
 */
/* Extend bootstrap default buttons */
.btn, .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button {
  padding: 8px 16px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  line-height: 2.1rem;
  min-width: 0;
  text-align: center;
  box-shadow: none;
  border: none;
  border-radius: 0;
  text-transform: none;
  margin: 0;
  transition: all 0.33s;
  background-color: #009bab;
  color: #fff;
  border-color: #00565f;
}
.btn:focus, .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button:focus, .btn:hover, .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button:hover, .btn:active, .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button:active, .btn.active, .ap.type-wallsio:not(.external-cookie-notice) .active.wallsio-load-more-button, .open .dropdown-toggle .btn, .open .dropdown-toggle .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .open .dropdown-toggle .wallsio-load-more-button {
  color: #fff;
  background-color: #00565f;
  border-color: #00565f;
  transition: all 0.33s;
}
.btn.disabled, .ap.type-wallsio:not(.external-cookie-notice) .disabled.wallsio-load-more-button, .btn[disabled], .ap.type-wallsio:not(.external-cookie-notice) [disabled].wallsio-load-more-button, fieldset[disabled] .btn, fieldset[disabled] .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) fieldset[disabled] .wallsio-load-more-button {
  background-color: #00565f;
  border-color: #00565f;
}
.btn:active, .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button:active {
  box-shadow: none;
  color: #fff;
}

/* Button sizes */
.btn.btn-lg, .ap.type-wallsio:not(.external-cookie-notice) .btn-lg.wallsio-load-more-button {
  padding: 10px 24px;
  font-size: 1.8rem;
  min-width: 0;
}

.btn.btn-sm, .ap.type-wallsio:not(.external-cookie-notice) .btn-sm.wallsio-load-more-button {
  padding: 6px 12px;
  min-width: 0;
}

.btn.btn-xs, .ap.type-wallsio:not(.external-cookie-notice) .btn-xs.wallsio-load-more-button {
  font-size: 1.2rem;
  padding: 2px 10px;
  min-width: 0;
}

.btn-default {
  background-color: #009bab;
  color: #fff;
  border-color: #00565f;
}
.btn-default:focus, .btn-default:hover, .btn-default:active, .btn-default.active, .open .dropdown-toggle .btn-default {
  color: #fff;
  background-color: #00565f;
  border-color: #00565f;
  transition: all 0.33s;
}
.btn-default.disabled, .btn-default[disabled], fieldset[disabled] .btn-default {
  background-color: #00565f;
  border-color: #00565f;
}

/* Colored buttons */
.color-grey-light .btn, .color-grey-light .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .color-grey-light .wallsio-load-more-button,
.btn.color-grey-light,
.ap.type-wallsio:not(.external-cookie-notice) .color-grey-light.wallsio-load-more-button {
  background-color: #eeeeee;
  color: #585f69;
  border-color: #00565f;
}
.color-grey-light .btn:focus, .color-grey-light .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button:focus, .ap.type-wallsio:not(.external-cookie-notice) .color-grey-light .wallsio-load-more-button:focus, .color-grey-light .btn:hover, .color-grey-light .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button:hover, .ap.type-wallsio:not(.external-cookie-notice) .color-grey-light .wallsio-load-more-button:hover, .color-grey-light .btn:active, .color-grey-light .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button:active, .ap.type-wallsio:not(.external-cookie-notice) .color-grey-light .wallsio-load-more-button:active, .color-grey-light .btn.active, .color-grey-light .ap.type-wallsio:not(.external-cookie-notice) .active.wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .color-grey-light .active.wallsio-load-more-button, .open .dropdown-toggle .color-grey-light .btn, .open .dropdown-toggle .color-grey-light .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .open .dropdown-toggle .color-grey-light .wallsio-load-more-button,
.btn.color-grey-light:focus,
.ap.type-wallsio:not(.external-cookie-notice) .color-grey-light.wallsio-load-more-button:focus,
.btn.color-grey-light:hover,
.ap.type-wallsio:not(.external-cookie-notice) .color-grey-light.wallsio-load-more-button:hover,
.btn.color-grey-light:active,
.ap.type-wallsio:not(.external-cookie-notice) .color-grey-light.wallsio-load-more-button:active,
.btn.color-grey-light.active,
.ap.type-wallsio:not(.external-cookie-notice) .color-grey-light.active.wallsio-load-more-button,
.open .dropdown-toggle .btn.color-grey-light,
.open .dropdown-toggle .ap.type-wallsio:not(.external-cookie-notice) .color-grey-light.wallsio-load-more-button,
.ap.type-wallsio:not(.external-cookie-notice) .open .dropdown-toggle .color-grey-light.wallsio-load-more-button {
  color: #fff;
  background-color: #00565f;
  border-color: #00565f;
  transition: all 0.33s;
}
.color-grey-light .btn.disabled, .color-grey-light .ap.type-wallsio:not(.external-cookie-notice) .disabled.wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .color-grey-light .disabled.wallsio-load-more-button, .color-grey-light .btn[disabled], .color-grey-light .ap.type-wallsio:not(.external-cookie-notice) [disabled].wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .color-grey-light [disabled].wallsio-load-more-button, fieldset[disabled] .color-grey-light .btn, fieldset[disabled] .color-grey-light .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) fieldset[disabled] .color-grey-light .wallsio-load-more-button,
.btn.color-grey-light.disabled,
.ap.type-wallsio:not(.external-cookie-notice) .color-grey-light.disabled.wallsio-load-more-button,
.btn.color-grey-light[disabled],
.ap.type-wallsio:not(.external-cookie-notice) .color-grey-light[disabled].wallsio-load-more-button,
fieldset[disabled] .btn.color-grey-light,
fieldset[disabled] .ap.type-wallsio:not(.external-cookie-notice) .color-grey-light.wallsio-load-more-button,
.ap.type-wallsio:not(.external-cookie-notice) fieldset[disabled] .color-grey-light.wallsio-load-more-button {
  background-color: #00565f;
  border-color: #00565f;
}

.color-grey-light .dropdown-menu > .active > a,
.color-grey-light .dropdown-menu > .active > a:focus,
.color-grey-light .dropdown-menu > .active > a:hover {
  color: #585f69;
  background-color: #eeeeee;
}

/**********
 * Source file: apollo-contact.scss
 *
 * Styles related to the "contact information" element.
 */
.ap-contact .fn,
.ap-contact .org,
.ap-contact .adr,
.ap-contact .title,
.ap-contact .note,
.ap-contact .mail,
.ap-contact .card {
  margin-top: 5px;
  margin-bottom: 5px;
}
.ap-contact .ap-contactlink {
  margin-top: 5px;
}
.ap-contact .image-outer-box {
  margin-bottom: 5px;
}
.ap-contact.compact .fn,
.ap-contact.compact .org,
.ap-contact.compact .adr,
.ap-contact.compact .title,
.ap-contact.compact .note,
.ap-contact.compact .ap-contactlink:not(.btn),
.ap-contact.compact .mail {
  margin-top: 0;
  margin-bottom: 0;
}
.ap-contact.compact .card {
  margin-bottom: 0;
}
.ap-contact .fn {
  margin-top: 0;
}
.ap-contact .title {
  font-style: italic;
}
.ap-contact .tablerow {
  display: table-row;
}
.ap-contact .tablerow > span {
  padding-right: 5px;
  display: table-cell;
}
.ap-contact .only-icon {
  padding-left: 5px;
}
.ap-contact .fa {
  margin-right: 5px;
  width: 15px;
}
.ap-contact .img-responsive {
  width: 100%;
}

/**********
 * Source file: apollo-containers.scss
 *
 * Empty container and other info markers.
 * Since this is considered to be part of the "System" rather than the template,
 * most settings here are fixed with "important".
 */
#ap-megamenu-head, #ap-modelinfo, .ap-edit-info, .ap-container {
  padding: 10px !important;
  text-align: center !important;
  overflow: hidden !important;
  z-index: 5 !important;
  white-space: normal !important;
  min-height: 100px !important;
  position: relative;
  font-family: "Open Sans", sans-serif !important;
}
#ap-megamenu-head .head, #ap-modelinfo .head, .ap-edit-info .head, .ap-container .head {
  font-family: "Open Sans", sans-serif !important;
  font-size: 22px !important;
  font-weight: normal !important;
  margin: 5px !important;
  line-height: 1.2em !important;
}
#ap-megamenu-head .text, #ap-modelinfo .text, .ap-edit-info .text, .ap-container .text {
  font-family: "Open Sans", sans-serif !important;
  margin: 5px !important;
  font-size: 14px !important;
  line-height: 1.2em !important;
}

.ap-edit-info, .ap-container {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  border-radius: 8px !important;
}

.ap-container .head {
  color: #555 !important;
}
.ap-container .text {
  color: #7f8691 !important;
}
.ap-container .text.capital {
  text-transform: capitalize !important;
}
.ap-container .main,
.ap-container .small {
  color: #7f8691 !important;
}

.ap-edit-info .head,
.ap-edit-info .text,
.ap-edit-info .small {
  color: #fff !important;
}

/* Empty container markers */
.ap-container.box-area {
  background-color: rgba(255, 201, 210, 0.75) !important;
  background: linear-gradient(166deg, #ffc9d2 0%, #ff96a8 100%) !important;
}

.ap-container.box-segment {
  background-color: rgba(255, 227, 231, 0.75) !important;
  background: linear-gradient(166deg, #ffe3e7 0%, #ffb0bb 100%) !important;
}

.ap-container.box-grid {
  background-color: rgba(255, 218, 199, 0.75) !important;
  background: linear-gradient(166deg, #ffdac7 0%, #ffb894 100%) !important;
}

.ap-container.box-row {
  background-color: rgba(255, 229, 191, 0.75) !important;
  background: linear-gradient(166deg, #ffe5bf 0%, #ffd08c 100%) !important;
}

.ap-container.box-element {
  background-color: rgba(232, 243, 248, 0.75) !important;
  background: linear-gradient(166deg, #e8f3f8 0%, #c1dfec 100%) !important;
}

.ap-container.box-special {
  background-color: rgba(239, 250, 180, 0.75) !important;
  background: linear-gradient(166deg, #effab4 0%, #e5f784 100%) !important;
}

/* Page information markers (new element, page reload) */
.ap-edit-info.box-info {
  background-color: #ffa826 !important;
  background: linear-gradient(166deg, #ffb240 0%, #ff9e0d 100%) !important;
}

.ap-edit-info.box-warn {
  background-color: #8b1528 !important;
  background: linear-gradient(166deg, #a1182e 0%, #751222 100%) !important;
}

.ap-edit-info.box-noheight {
  min-height: 0 !important;
}

.ap-edit-info.new-element .txt {
  display: inline-block;
  padding: 2px 12px !important;
  border-radius: 4px !important;
  color: #fff !important;
  background-color: #2197db !important;
}

.ap-meta::after,
.ap-meta-info::after,
.ap-anchor::after,
.ap-container::after,
.ap-edit-info::after {
  content: " ";
}

/* Model group marker */
#ap-modelinfo-border {
  border: 4px solid #eed2ee !important;
  padding: 0px !important;
}

#ap-modelinfo {
  background-color: rgba(238, 210, 238, 0.75) !important;
}
#ap-modelinfo .head {
  color: #555 !important;
}
#ap-modelinfo .text,
#ap-modelinfo .small {
  color: #7f8691 !important;
}

/* Mega menu editor head */
#ap-megamenu-head {
  background-color: #ffc9d2 !important;
  min-height: initial !important;
}
#ap-megamenu-head .head {
  color: #555 !important;
}
#ap-megamenu-head .text {
  color: #555 !important;
}

/* Label colors */
.ap-label-reusegroup, .ap-label-copygroup, .ap-label-special, .ap-label-detailonly, .ap-label-detail, .ap-label-author, .ap-label-editor, .ap-label-developer {
  display: inline;
  font-size: 12px !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: normal !important;
  position: relative;
  top: -5px !important;
  padding: 2px 5px !important;
  margin-left: 5px !important;
  border-radius: 4px !important;
  white-space: nowrap !important;
  color: #fff !important;
  text-transform: uppercase !important;
}

.ap-label-developer {
  background-color: #b31b34 !important;
}

.ap-label-editor {
  background-color: #ffa826 !important;
}

.ap-label-author {
  background-color: #144382 !important;
}

.ap-label-detail {
  background-color: #1bb39a !important;
}

.ap-label-detailonly {
  background-color: #1bb39a !important;
}

.ap-label-special {
  background-color: #ff264a !important;
}

.ap-label-copygroup {
  background-color: #ff264a !important;
}

.ap-label-reusegroup {
  background-color: #2197db !important;
}

/* Anchor link and admin markers */
.ap-meta-info {
  position: relative;
  transition: all 0.33s;
  border-radius: 4px !important;
  color: #fff !important;
  background-color: #ffa826 !important;
  background: linear-gradient(166deg, #ffb240 0%, #ff9e0d 100%) !important;
}

.btn.ap-meta-info:focus, .ap.type-wallsio:not(.external-cookie-notice) .ap-meta-info.wallsio-load-more-button:focus, .btn.ap-meta-info:hover, .ap.type-wallsio:not(.external-cookie-notice) .ap-meta-info.wallsio-load-more-button:hover, .btn.ap-meta-info:active, .ap.type-wallsio:not(.external-cookie-notice) .ap-meta-info.wallsio-load-more-button:active {
  background: #00565f !important;
  background-color: #00565f !important;
  color: #fff !important;
  transition: all 0.33s;
}

.ap-anchor-set {
  position: relative;
  visibility: hidden;
}

.ap-anchor-none {
  display: none;
}

.ap-meta,
.ap-anchor {
  min-height: 25px !important;
  display: inline-block;
  padding-right: 25px !important;
}
.ap-meta .marker,
.ap-meta .empty,
.ap-meta .target,
.ap-anchor .marker,
.ap-anchor .empty,
.ap-anchor .target {
  border-radius: 4px !important;
  color: #fff !important;
  background-color: #ffa826 !important;
  background: linear-gradient(166deg, #ffb240 0%, #ff9e0d 100%) !important;
  font-family: "Open Sans", sans-serif !important;
  padding: 4px 8px !important;
}
.ap-meta.error .marker,
.ap-anchor.error .marker {
  background: #b31b34 !important;
}

/* Badges for user and screen size */
.badge-screensize,
.badge-user {
  color: #555;
  background: #ffa826;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 12px;
  padding: 5px;
  top: -7px;
  left: 4px;
  position: relative;
  border-radius: 4px;
}

.badge-screensize {
  text-transform: uppercase;
  left: 0;
}

/**********
 * Source file: apollo-event.scss
 *
 * Styles for the "Event" element
 */
/* Event detail page */
.ap-detail-page.ap-event .ap-event-header {
  margin-bottom: 20px;
}
.ap-detail-page.ap-event .ap-event-header .subheadline {
  margin-top: -15px;
  margin-bottom: 15px;
}
.ap-detail-page.ap-event .location .locname {
  margin-bottom: 5px;
}
.ap-detail-page.ap-event .location .adressInfo {
  margin-top: 10px;
  margin-bottom: 0;
}
.ap-detail-page.ap-event .location .adressInfo p:last-child {
  margin-bottom: 0;
}

/* Event teaser with calender sheet */
html:not(.mobile) .ap-calendar-sheet-teaser.ap-raise-animation:hover .animated-box {
  box-shadow: 5px 5px 8px -1px rgba(0, 0, 0, 0.3);
}
html:not(.mobile) .ap-calendar-sheet-teaser.ap-raise-animation .calendar-sheet {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}
html:not(.mobile) .ap-calendar-sheet-teaser .calendar-sheet-parent > a:not(.btn).link,
html:not(.mobile) .ap-calendar-sheet-teaser .calendar-sheet-parent > a:not(.btn).link:hover {
  color: #585f69;
  text-decoration: none;
}

@media (max-width: 767px) {
  html:not(.mobile) .ap-calendar-sheet-teaser.ap-raise-animation:hover .calendar-sheet.animated-box {
    transform: none;
    box-shadow: none;
  }

  .ap-calendar-sheet-teaser .calendar-sheet-parent {
    padding: 0;
    margin-bottom: 5px;
  }
}
/* Calendar sheet base */
.calendar-sheet {
  background: #e8e8e8;
}
.calendar-sheet .day-number {
  color: #009bab;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .calendar-sheet {
    border: 1px solid #e8e8e8;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
    text-align: center;
    min-width: 90px;
  }
  .calendar-sheet:before {
    border-bottom: 20px solid #009bab;
    display: block;
    content: "";
    margin: 0 10%;
  }
  .calendar-sheet > span {
    display: block;
  }
  .calendar-sheet .day-name,
.calendar-sheet .month-year {
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .calendar-sheet {
    border-bottom: 2px solid #009bab;
    display: inline-block;
    width: 100%;
    padding: 10px 0;
  }
  .calendar-sheet .day-name {
    margin-right: 15px;
    float: right;
  }
  .calendar-sheet .month-year,
.calendar-sheet .day-number {
    margin-left: 15px;
    float: left;
  }
  .calendar-sheet .day-name,
.calendar-sheet .day-number,
.calendar-sheet .month-year {
    line-height: 30px;
  }
}

/* Colored calendar sheets */
@media (max-width: 767px) {
  .color-grey-light .calendar-sheet {
    border-top-color: #eeeeee;
  }
}
.color-grey-light .calendar-sheet:before {
  border-color: #eeeeee;
}
.color-grey-light .calendar-sheet .day-number {
  color: #eeeeee;
}

/**********
 * Source file: apollo-imageseries.scss
 *
 * Styles used by the "Image Series" element
 */
/* Image series base classes */
.ap-imageseries.ap-element .images {
  font-weight: bold;
}
.ap-imageseries > .series {
  margin-left: -2px;
  margin-right: -2px;
  position: relative;
}
.ap-imageseries > .series > .images {
  margin-top: -2px;
  margin-bottom: -2px;
}
.ap-imageseries > .series > button.btn, .ap.type-wallsio:not(.external-cookie-notice) .ap-imageseries > .series > button.wallsio-load-more-button {
  margin-top: 4px;
  margin-left: 2px;
  margin-right: 2px;
  width: calc(100% - 4px);
}
.ap-imageseries > .series .imagedata {
  display: none;
}
.ap-imageseries .ap-image {
  position: relative;
}
.ap-imageseries .ap-image .centered {
  display: inline-block;
  color: #fff;
  position: absolute;
  right: 16px;
  bottom: 12px;
  text-shadow: 1px 1px 1px #000;
  font-size: 20px;
  pointer-events: none;
  z-index: 7;
}
.ap-imageseries .ap-square .content .copyright {
  position: static;
}

html.noscript .ap-imageseries .series {
  display: none;
}

.pswp .title {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;
}
.pswp .copyright {
  color: #aaa;
  font-size: 10px;
}
.pswp .pswp__caption__center {
  max-width: 420px;
}
@media (min-width: 420px) {
  .pswp .pswp__caption__center {
    max-width: 85%;
  }
}

.pswp__img {
  background-color: #eee;
}

/* Image gallery image enter animation, used when the image gallery is displayed */
.comein {
  transform: translateY(120px);
  animation: come-in 0.8s ease forwards;
}
.comein:nth-child(even) {
  transform: translateY(150px);
  animation-duration: 1s;
}

@keyframes come-in {
  to {
    transform: translateY(0);
  }
}
/* Zoom overlay effect used by image series as well as the 'image zoom' formatter */
a:focus .zoom-overlay .zoom-icon,
.zoom:hover .zoom-overlay .zoom-icon {
  opacity: 1;
  transform: scale(1);
  border: 0;
  box-shadow: none;
}

.zoom-overlay {
  cursor: zoom-in;
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  transition: all 0.1s ease-in-out 0s;
}

.zoom-overlay .zoom-icon {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transform: scale(0);
  transition: all 0.4s ease-in-out 0s;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
}
.zoom-overlay .zoom-icon:before {
  background: transparent;
}
.zoom-overlay .zoom-icon:after {
  background: transparent;
}

.zoom-overlay .zoom-icon span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 30px;
  color: #fff;
}

.animated-box .zoomer {
  position: relative;
  cursor: zoom-in;
}
.animated-box .zoomer::after {
  font-family: FontAwesome;
  content: "";
  display: inline-block;
  color: #fff;
  position: absolute;
  right: 16px;
  bottom: 12px;
  text-shadow: 1px 1px 1px #000;
  font-size: 20px;
  pointer-events: none;
  z-index: 7;
}

a .animated-box .zoomer {
  cursor: pointer;
}
a .animated-box .zoomer::after {
  content: "";
}

/**********
 * Source file: apollo-jobs.scss
 *
 * Styles used by the "Job Offer" element
 */
/**********
 * Source file: apollo-linksequence.scss
 *
 * Styles related to the "link sequence" element.
 */
.ap-linksequence ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.ap-linksequence.custom-icon li, .ap-linksequence.default-icon li {
  padding-left: 20px;
}
.ap-linksequence.custom-icon li::before, .ap-linksequence.default-icon li::before {
  float: left;
  margin-left: -20px;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  line-height: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ap-linksequence.custom-icon.ap-sidebar-nav li::before, .ap-linksequence.default-icon.ap-sidebar-nav li::before {
  padding: 8px 20px 8px 2px;
  padding-right: 0;
  color: #585f69;
  margin-left: -18px;
}
.ap-linksequence.default-icon li::before {
  font-family: sans-serif;
  font-size: 24px;
  content: "•";
  line-height: 2.1rem;
}
.ap-linksequence.boxed li {
  position: relative;
  padding: 10px 10px 10px 20px;
  margin-bottom: -1px;
  border: 1px solid #eeeeee;
  text-decoration: none;
}
.ap-linksequence.boxed li::before {
  margin-left: -15px;
}
.ap-linksequence.boxed.no-icon li {
  padding-left: 10px;
}
.ap-linksequence li a:focus, .ap-linksequence li a:hover {
  text-decoration: underline;
}
.ap-linksequence .fa {
  padding-right: 8px;
  color: #585f69;
}

/* Row formatted link sequence */
.ap-linksequence-row ul {
  margin: 0;
  padding-left: 0;
}
.ap-linksequence-row ul li {
  display: inline;
  list-style: outside none none;
  position: relative;
}
.ap-linksequence-row ul li:not(:last-child)::after {
  content: "|";
  padding-left: 5px;
  padding-right: 4px;
}

/* Header formatted link sequence */
.ap-linksequence-hf {
  width: 100%;
}
.ap-linksequence-hf::before, .ap-linksequence-hf::after {
  content: " ";
  display: table;
}
.ap-linksequence-hf:after {
  clear: both;
}
.ap-linksequence-hf ul.links {
  margin: 0;
  padding: 0;
  font-size: 12px;
}
.ap-linksequence-hf ul.links > li {
  display: inline;
  list-style: outside none none;
  position: relative;
}
.ap-linksequence-hf ul.links > li.ap-languages::before {
  content: "";
  font-family: FontAwesome;
}
.ap-linksequence-hf ul.links > li + li::before {
  content: "";
  border-right: 1px solid #eeeeee;
  margin: 0 9px 0 7px;
}
.ap-linksequence-hf ul.links > li > a {
  color: #fff;
  text-transform: uppercase;
}
.ap-linksequence-hf ul.languages {
  position: absolute;
  top: calc(100% + 4px);
  left: -7px;
  list-style: none;
  min-width: 100px;
  border: none;
  border-top: 1px solid #eeeeee;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  z-index: 101;
}
.ap-linksequence-hf ul.languages::after {
  top: -8px;
  width: 0;
  height: 0;
  left: 4px;
  content: "";
  display: block;
  position: absolute;
  border-bottom: 8px solid #eeeeee;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-left-style: inset;
  border-right-style: inset;
}
.ap-linksequence-hf ul.languages::before {
  position: absolute;
  width: 100%;
  content: "";
  background-color: transparent;
  min-height: 12px;
  top: -12px;
}
.ap-linksequence-hf ul.languages li a {
  color: #585f69;
  background-color: #fff;
  display: block;
  font-size: 12px;
  padding: 2px 20px 2px 10px;
  text-transform: uppercase;
}
.ap-linksequence-hf ul.languages li.active {
  position: relative;
}
.ap-linksequence-hf ul.languages li.active::after {
  content: "";
  font-family: FontAwesome;
  position: absolute;
  right: 5px;
  top: 0;
}
.ap-linksequence-hf ul.languages li.active a {
  color: #585f69;
  background-color: #eeeef0;
}
.ap-linksequence-hf ul.languages li {
  border-bottom: 1px solid #eeeeee;
}
.ap-linksequence-hf ul.languages li a:hover,
.ap-linksequence-hf ul.languages li.active a:hover {
  color: #009bab;
  background-color: #eeeef0;
  transition: background-color 0.5s;
  text-decoration: none;
}
.ap-linksequence-hf .dropdown > a {
  cursor: default;
}
.ap-linksequence-hf .dropdown > a:hover {
  text-decoration: none;
}

/**********
 * Source file: apollo-map.scss
 *
 * Styles used by the "Location Map" element
 */
.type-map .mapbuttons {
  margin-top: 10px;
}
.type-map .mapbuttons .btn, .type-map .mapbuttons .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .type-map .mapbuttons .wallsio-load-more-button {
  margin-top: 5px;
  margin-right: 5px;
}
.type-map .presized:not(.enlarged) {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}
.type-map .presized:not(.enlarged) .mapwindow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 5;
}
.type-map .presized:not(.enlarged) .mapwindow.placeholder::after {
  content: "";
}
.type-map .presized.enlarged {
  padding-bottom: 0 !important;
}
.type-map .map-marker {
  color: #585f69;
  font-size: 1.4rem;
  font-weight: 300;
}
.type-map .map-marker .markhead {
  font-family: "Open Sans", sans-serif;
  font-size: 1.8rem;
  font-weight: normal;
  margin-bottom: 5px;
}
.type-map .map-marker .marktxt {
  line-height: 1.2;
}
.type-map .map-marker .markroute .head {
  margin-top: 5px;
}
.type-map .map-marker .markroute .btn, .type-map .map-marker .markroute .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .type-map .map-marker .markroute .wallsio-load-more-button {
  margin-top: 5px;
}
.type-map .map-marker .markroute .form-control {
  margin-top: 5px;
  height: 27px;
  padding: 3px 6px;
}

.map-osm .mapboxgl-map {
  color: #585f69;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.2;
}
.map-osm .mapboxgl-marker {
  cursor: pointer;
}
.map-osm .mapboxgl-popup-content {
  border-radius: 0;
  padding: 10px 20px 10px 10px;
}
.map-osm .mapboxgl-popup-close-button {
  font-size: 20px;
}
.map-osm .mapboxgl-popup-close-button:hover {
  background-color: #fff;
  opacity: 0.8;
  color: #009bab;
}

/**********
 * Source file: apollo-media.scss
 *
 * Styles used by the "Media" element
 */
.ap-media.ap-square .preview {
  height: 100%;
}
@media (max-width: 767px) {
  .ap-media.text-below-on-xs.ap-square .preview .image-src-box {
    position: relative;
  }
  .ap-media.text-below-on-xs.ap-square .preview .image-src-box > img {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: none !important;
  }
  .ap-media.text-below-on-xs.square-4-3 .preview .image-src-box {
    padding-bottom: 75%;
  }
  .ap-media.text-below-on-xs.square-16-9 .preview .image-src-box {
    padding-bottom: 56.25%;
  }
  .ap-media .title-media-overlay .heading {
    font-size: 18px;
  }
  .ap-media .title-media-overlay .subheading {
    font-size: 16px;
  }
  .ap-media .text-media-overlay {
    font-size: 1.12rem;
  }
}
.ap-media .preview {
  position: relative;
  cursor: pointer;
  height: 100%;
}
.ap-media .preview .centered {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.ap-media .preview .icon {
  color: #fff;
  text-shadow: 1px 1px 1px #000, 0px 0px 5px rgba(0, 0, 0, 0.5);
  font-size: 16px;
  text-align: center;
  z-index: 6;
}
.ap-media .preview .icon span {
  opacity: 0.7;
  display: block;
  font-size: 60px;
}
.ap-media .preview .icon div {
  opacity: 1;
}
.ap-media .preview .title-media-overlay,
.ap-media .preview .text-media-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 20px;
  line-height: 1.2;
  text-shadow: 1px 1px 1px #000, 0px 0px 1px #000;
  margin: 0;
  color: #fff;
  z-index: 7;
}
.ap-media .preview .title-media-overlay {
  top: 10px;
  bottom: initial;
  max-height: 75%;
  overflow: hidden;
  font-size: 16px;
}
.ap-media .preview .title-media-overlay h1, .ap-media .preview .title-media-overlay h2, .ap-media .preview .title-media-overlay h3,
.ap-media .preview .title-media-overlay .heading,
.ap-media .preview .title-media-overlay .subheading {
  color: #fff;
  text-shadow: 1px 1px 1px #000, 0px 0px 1px #000;
}
.ap-media .preview .title-media-overlay .heading,
.ap-media .preview .title-media-overlay .subheading {
  margin-bottom: 0;
}
.ap-media .preview .title-media-overlay .heading + .subheading {
  margin-top: 10px;
}
.ap-media .preview .text-media-overlay.has-date .teaser-length {
  float: right;
}
.ap-media .preview:hover .icon span {
  opacity: 1;
}
.ap-media .preview.placeholder {
  cursor: auto;
}
.ap-media .preview.placeholder::after {
  content: "";
}
.ap-media .teaser-visual {
  position: relative;
}
.ap-media .teaser-visual.play .content {
  background-color: transparent !important;
}
.ap-media .teaser-visual.play.flexible {
  padding-bottom: 0 !important;
}
.ap-media .teaser-visual.play.flexible .content {
  position: static;
}
.ap-media .teaser-visual .content:not(.compact) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 5;
}
.ap-media .teaser-visual .content.compact .title-media-overlay,
.ap-media .teaser-visual .content.compact .text-media-overlay {
  position: relative;
}
.ap-media .teaser-visual .content.compact.heading-overlay .preview .icon {
  text-align: right;
  padding-right: 20px;
}
.ap-media .teaser-visual .content.compact.heading-overlay.has-icon .title-media-overlay {
  padding-right: 80px;
}
.ap-media .teaser-visual .content.compact.heading-above .preview .icon {
  position: static;
  transform: none;
  padding-top: 10px;
}
.ap-media .content {
  background-color: #000;
}
.ap-media .content.bg-css {
  background-color: #009bab;
}
.ap-media .content:not(.flexible) iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ap-media .preview.video .centered.image {
  width: 101%;
}
@media (min-width: 1200px) {
  .ap-media.square-large.square-16-9 .preview.video .centered.image {
    width: 104%;
  }
}
.ap-media .fitin {
  width: 100%;
  height: 100%;
}
.ap-media .presized {
  position: relative;
  width: 100%;
  height: initial;
}
.ap-media .presized:not(.enlarged) {
  padding-bottom: 100%;
  overflow: hidden;
}
.ap-media .presized.enlarged {
  padding-bottom: 0 !important;
}
.ap-media .presized.enlarged .content {
  position: static;
  height: auto;
}

.onclick-activation {
  position: relative;
  cursor: pointer;
  margin-bottom: 20px;
}
.onclick-activation .oa-heading,
.onclick-activation .oa-notice {
  color: #fff;
  text-shadow: 1px 1px 1px #000, 0px 0px 1px #000;
  padding: 0 20px;
  margin: 0;
  z-index: 7;
}
.onclick-activation .oa-icon {
  color: #fff;
  text-shadow: 1px 1px 1px #000, 0px 0px 5px rgba(0, 0, 0, 0.5);
  font-size: 16px;
  text-align: center;
  z-index: 6;
}
.onclick-activation .oa-icon span {
  opacity: 0.7;
  display: block;
  font-size: 60px;
}
.onclick-activation .oa-icon div {
  opacity: 1;
}
.onclick-activation .centered {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.onclick-activation:hover .oa-icon span {
  opacity: 1;
}
.onclick-activation.no-image {
  background-color: #009bab;
}
.onclick-activation.compact .oa-heading,
.onclick-activation.compact .oa-notice {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}
.onclick-activation.compact .centered {
  top: 10px;
  left: auto;
  transform: none;
}
.onclick-activation.compact .oa-icon {
  text-align: right;
  padding-right: 20px;
}
.onclick-activation.compact.has-icon .oa-heading {
  padding-right: 80px;
}
.onclick-activation.presized .oa-heading,
.onclick-activation.presized .oa-notice {
  position: absolute;
}
.onclick-activation.presized .oa-heading {
  top: 20px;
}
.onclick-activation.presized .oa-notice {
  bottom: 10px;
}
@media (max-width: 767px) {
  .onclick-activation.presized.no-image .oa-heading,
.onclick-activation.presized.no-image .oa-notice, .onclick-activation.presized.has-image .oa-heading,
.onclick-activation.presized.has-image .oa-notice {
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .onclick-activation.presized.no-image .centered, .onclick-activation.presized.has-image .centered {
    top: 10px;
    left: auto;
    transform: none;
  }
  .onclick-activation.presized.no-image .oa-icon, .onclick-activation.presized.has-image .oa-icon {
    text-align: right;
    padding-right: 20px;
  }
  .onclick-activation.presized.no-image.has-icon .oa-heading, .onclick-activation.presized.has-image.has-icon .oa-heading {
    padding-right: 80px;
  }
  .onclick-activation.presized.no-image {
    padding-bottom: 0 !important;
  }
  .onclick-activation.presized.no-image .oa-heading {
    top: auto;
  }
  .onclick-activation.presized.no-image .oa-notice {
    bottom: auto;
  }
  .onclick-activation.presized.has-image .oa-heading,
.onclick-activation.presized.has-image .oa-notice {
    position: absolute;
  }
  .onclick-activation.presized.has-image .oa-heading {
    top: 0;
  }
  .onclick-activation.presized.has-image .oa-notice {
    bottom: 0;
  }
  .onclick-activation .oa-heading {
    font-size: 18px;
    line-height: 1.1;
  }
  .onclick-activation .oa-notice {
    font-size: 1.12rem;
    line-height: 1.2;
  }
}

/**********
 * Source file: apollo-poi.scss
 *
 * Styles used by the "POI - Point of Interest" element
 */
.poi-head {
  position: relative;
}

@media (min-width: 768px) {
  .accessible {
    position: absolute;
    right: 0;
    margin-bottom: 0;
  }
}
.ap-square.acc-icon {
  width: 35px;
  padding-bottom: 35px !important;
  display: inline-block;
  color: #fff;
  background-color: #009bab;
  border-radius: 0;
  margin-right: 10px;
  font-size: 2rem;
}
.ap-square.acc-icon:last-of-type {
  margin-right: 0;
}
.ap-square.acc-icon .acc-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
}
.ap-square.acc-icon .acc-wc.acc-inner {
  transform: translateX(-50%) translateY(-50%) scale(0.85);
  top: 60%;
  left: 38%;
}
.ap-square.acc-icon .acc-wc.acc-add {
  position: absolute;
  display: flex;
  top: 55%;
  left: 40%;
  transform: translateX(-20%) translateY(-90%) scale(0.6);
}
.ap-square.acc-icon .fa {
  font-weight: normal;
}
.ap-square.acc-icon .fa-male {
  position: relative;
  margin: 0 1px 0 2px;
  font-weight: normal;
}
.ap-square.acc-icon .fa-male::after {
  display: block;
  content: "";
  border-right: 1px solid #fff;
  position: absolute;
  height: 100%;
  bottom: 0;
  right: -2px;
}
.ap-square.acc-icon .fa-female {
  font-weight: normal;
  transform: scale(0.85, 1);
}

/**********
 * Source file: apollo-privacy-policy.scss
 */
/* Privacy banner */
.pp-banner .container {
  display: flex;
  flex-direction: column;
}
.pp-banner .container > div:not(:last-of-type) {
  margin-bottom: 10px;
}
.pp-banner .banner {
  display: none;
  box-shadow: 0px -3px 10px 2px rgba(0, 0, 0, 0.3);
  background-color: #333;
  opacity: 0.95;
  color: #fff;
  padding: 20px;
  width: 100vw;
}
.pp-banner .banner.fixed {
  z-index: 150100;
  left: 0;
  position: fixed;
}
.pp-banner .banner.fixed.top {
  top: 0;
}
.pp-banner .banner.fixed.bottom {
  bottom: 0;
}
.pp-banner * {
  color: #fff;
}
.pp-banner .title {
  display: inline-block;
  max-width: calc(100% - 195px - $default-margin);
  font-size: 2.4rem;
  line-height: 1.25;
  margin-bottom: 10px;
}
.pp-banner .message {
  order: 2;
}
.pp-banner .message p:last-of-type {
  margin-bottom: 0;
}
.pp-banner .message a {
  font-weight: bold;
}
.pp-banner .selection {
  display: flex;
  align-items: center;
  order: 3;
}
.pp-banner .selection .options {
  flex-grow: 1;
}
.pp-banner .selection .options label {
  margin-right: 20px;
  margin-bottom: 0;
  padding-left: 30px;
  cursor: pointer;
  position: relative;
}
.pp-banner .selection .options label input {
  position: absolute;
  left: -9999px;
}
.pp-banner .selection .options label i {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  outline: none;
  background-color: #fff;
}
.pp-banner .selection .options label input + i::after {
  position: absolute;
  opacity: 0;
  content: "";
  top: -4px;
  left: -1px;
  font-size: 23px;
  line-height: 23px;
  font-family: FontAwesome;
  font-weight: normal;
  text-align: center;
}
.pp-banner .selection .options label input:checked + i::after {
  opacity: 1;
  color: #29ce3f;
}
.pp-banner .selection .options label input[disabled] + i {
  background-color: #eeeeee;
}
.pp-banner .selection .options label input[disabled] + i::after {
  color: #585f69;
}
.pp-banner .buttons .btn, .pp-banner .buttons .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .pp-banner .buttons .wallsio-load-more-button {
  font-size: 1.8rem;
  min-width: 100px;
}
.pp-banner .buttons .btn:last-of-type, .pp-banner .buttons .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button:last-of-type, .ap.type-wallsio:not(.external-cookie-notice) .pp-banner .buttons .wallsio-load-more-button:last-of-type {
  margin-left: 20px;
}
.pp-banner .buttons .btn-accept {
  background-color: #29ce3f;
  color: #fff;
  border-color: #1efc3b;
}
.pp-banner .buttons .btn-accept:focus, .pp-banner .buttons .btn-accept:hover, .pp-banner .buttons .btn-accept:active, .pp-banner .buttons .btn-accept.active, .open .dropdown-toggle .pp-banner .buttons .btn-accept {
  color: #fff;
  background-color: #1efc3b;
  border-color: #1efc3b;
  transition: all 0.33s;
}
.pp-banner .buttons .btn-accept.disabled, .pp-banner .buttons .btn-accept[disabled], fieldset[disabled] .pp-banner .buttons .btn-accept {
  background-color: #1efc3b;
  border-color: #1efc3b;
}
.pp-banner .buttons .btn-save {
  background-color: #29ce3f;
  color: #fff;
  border-color: #1efc3b;
}
.pp-banner .buttons .btn-save:focus, .pp-banner .buttons .btn-save:hover, .pp-banner .buttons .btn-save:active, .pp-banner .buttons .btn-save.active, .open .dropdown-toggle .pp-banner .buttons .btn-save {
  color: #fff;
  background-color: #1efc3b;
  border-color: #1efc3b;
  transition: all 0.33s;
}
.pp-banner .buttons .btn-save.disabled, .pp-banner .buttons .btn-save[disabled], fieldset[disabled] .pp-banner .buttons .btn-save {
  background-color: #1efc3b;
  border-color: #1efc3b;
}
.pp-banner .btn-close {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 2.5rem;
  line-height: 1;
  padding: 5px 8px;
}
.pp-banner .btn-close:not(:hover):not(:focus) {
  background-color: transparent;
}
.pp-banner .links {
  order: 4;
  font-size: 80%;
}
.pp-banner .links ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.pp-banner .links li {
  display: inline;
  margin: 0;
}
.pp-banner .links li + li {
  margin-left: 20px;
}
.pp-banner .links a {
  font-weight: bold;
}
@media (max-width: 1199px) {
  .pp-banner .title {
    text-align: center;
    font-size: 1.8rem;
    max-width: 100%;
    display: block;
    float: none;
  }
  .pp-banner .container {
    padding: 0;
  }
  .pp-banner .selection {
    font-size: 1.25rem;
    flex-direction: column;
    align-items: flex-start;
  }
  .pp-banner .selection .options {
    flex-grow: initial;
    margin-bottom: 10px;
  }
  .pp-banner .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .pp-banner .buttons .btn, .pp-banner .buttons .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .pp-banner .buttons .wallsio-load-more-button {
    margin: 0;
    font-size: 1.5rem;
  }
  .pp-banner .btn-close {
    font-size: 1.5rem;
    right: 10px;
  }
  .pp-banner .message {
    font-size: 1.2rem;
    text-align: justify;
    -webkit-hyphens: auto;
            hyphens: auto;
  }
  .pp-banner .links {
    font-size: 1rem;
  }
}
@media (max-width: 991px) {
  .pp-banner .selection {
    order: 6;
    margin-bottom: 0 !important;
  }
  .pp-banner .buttons {
    justify-content: space-between;
  }
  .pp-banner .btn-close {
    right: 5px;
    top: 8px;
    padding: 4px 6px;
  }
  .pp-banner .links {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .pp-banner .selection .options {
    display: flex;
    flex-direction: column;
  }
  .pp-banner .selection .options label + label {
    margin-top: 5px;
  }
  .pp-banner .message {
    margin-bottom: 5px !important;
  }
  .pp-banner .buttons .btn, .pp-banner .buttons .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .pp-banner .buttons .wallsio-load-more-button {
    font-size: 1.25rem;
    padding: 6px 12px;
  }
  .pp-banner .buttons .btn:last-of-type, .pp-banner .buttons .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button:last-of-type, .ap.type-wallsio:not(.external-cookie-notice) .pp-banner .buttons .wallsio-load-more-button:last-of-type {
    margin-left: 0;
  }
  .pp-banner .links li {
    display: list-item;
  }
  .pp-banner .links li + li {
    margin-left: 0;
  }
}

#privacy-policy-banner-noscript .banner {
  display: block;
}
#privacy-policy-banner-noscript .banner .message {
  font-size: 2.8rem;
  line-height: 1.25;
}

html.opencms-page-editor .pp-toggle .toggle-check + .toggle-label, .pp-toggle .toggle-check[disabled] + .toggle-label {
  pointer-events: none;
  cursor: auto;
}
html.opencms-page-editor .pp-toggle .toggle-check + .toggle-label .toggle-inner::before, .pp-toggle .toggle-check[disabled] + .toggle-label .toggle-inner::before,
html.opencms-page-editor .pp-toggle .toggle-check + .toggle-label .toggle-inner::after,
.pp-toggle .toggle-check[disabled] + .toggle-label .toggle-inner::after {
  background-color: #eeeeee !important;
  color: #aaa !important;
}

.pp-toggle {
  position: relative;
  display: flex;
}
.pp-toggle + .pp-toggle {
  margin-top: 20px;
}
.pp-toggle .toggle-label {
  display: block;
  cursor: pointer;
  min-height: 42px;
  margin-bottom: 0;
}
.pp-toggle .toggle-label:hover .toggle-inner::before, .pp-toggle .toggle-label:hover .toggle-inner::after {
  color: #585f69 !important;
  background-color: #fff !important;
}
.pp-toggle .toggle-text {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 1;
  margin-left: 20px;
}
.pp-toggle .toggle-box {
  width: 200px;
  position: relative;
  display: block;
  overflow: hidden;
  border: 1px solid #585f69;
  border-radius: 0;
}
.pp-toggle .toggle-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
}
.pp-toggle .toggle-inner::before, .pp-toggle .toggle-inner::after {
  display: block;
  float: left;
  width: 50%;
  height: 40px;
  line-height: 40px;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
  box-sizing: border-box;
}
.pp-toggle .toggle-inner::before {
  content: attr(data-checked) " ";
  padding-left: 10px;
  background-color: #29ce3f;
  color: #fff;
}
.pp-toggle .toggle-inner::after {
  content: attr(data-unchecked) " ";
  padding-right: 10px;
  background-color: #eeeeee;
  color: #585f69;
  text-align: right;
}
.pp-toggle .toggle-slider {
  display: block;
  width: 36px;
  margin: 2px;
  background-color: #eeeeee;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 158px;
  border: 1px solid #585f69;
  border-radius: 0;
}
.pp-toggle.animated .toggle-inner {
  transition: margin 0.3s ease-in 0s;
}
.pp-toggle.animated .toggle-slider {
  transition: all 0.3s ease-in 0s;
}
.pp-toggle .toggle-check {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.pp-toggle .toggle-check:checked + .toggle-label .toggle-inner {
  margin-left: 0 !important;
}
.pp-toggle .toggle-check:checked + .toggle-label .toggle-slider {
  right: 0px !important;
}
@media (max-width: 991px) {
  .pp-toggle {
    flex-direction: column;
  }
  .pp-toggle .toggle-text {
    margin-left: 0;
    margin-bottom: 10px;
  }
  .pp-toggle .toggle-label {
    order: 1;
  }
}

.pp-element {
  margin-top: 20px;
}

#pp-matomo-jst > div + div {
  margin-top: 10px;
}
#pp-matomo-jst .jst-msg > *:last-child {
  margin-bottom: 0;
}
#pp-matomo-jst .jst-btn label.checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  margin-bottom: 0;
  cursor: pointer;
}
#pp-matomo-jst .jst-btn label.checkbox input {
  position: absolute;
  left: -9999px;
}
#pp-matomo-jst .jst-btn label.checkbox i {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  outline: none;
  border-width: 1px;
  border-style: solid;
  border-color: #585f69;
  border-radius: 0;
  background-color: #fff;
}
#pp-matomo-jst .jst-btn label.checkbox span {
  display: block;
  flex-shrink: 10;
}
#pp-matomo-jst .jst-btn label.checkbox input:checked + i::after {
  color: #585f69;
  position: absolute;
  z-index: 10;
  content: "";
  top: -5px;
  left: -1px;
  font-size: 23px;
  line-height: 23px;
  font-family: FontAwesome;
  font-weight: normal;
  text-align: center;
  overflow: visible;
}

html.keyboard-nav .pp-toggle .toggle-check:focus + .toggle-label .toggle-box {
  outline-color: #00565f;
  outline-width: 3px;
  outline-style: solid;
  outline-offset: -2px;
  box-shadow: inset 0px 0px 4px 1px #00565f, 0px 0px 7px 2px #00565f;
  z-index: 155000;
}

#apollo-page .external-cookie-notice {
  color: #585f69;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: scroll !important;
  padding: 20px;
  cursor: auto;
  font: normal 14px/1.5 "Helvetica Neue", Helvetica, Arial, sans-serif;
}
@media (min-width: 1200px) {
  #apollo-page .external-cookie-notice {
    font-size: 1.4rem;
  }
}
#apollo-page .external-cookie-notice .cookie-header {
  font-size: 125%;
}
#apollo-page .external-cookie-notice .cookie-header,
#apollo-page .external-cookie-notice .cookie-message,
#apollo-page .external-cookie-notice .cookie-switch {
  margin-bottom: 10px;
}
#apollo-page .external-cookie-notice .cookie-footer {
  font-size: 85%;
  color: #585f69;
}
#apollo-page .external-cookie-notice .cookie-footer > *:last-child {
  margin-bottom: 0;
}
#apollo-page .external-cookie-notice .pp-toggle .toggle-label {
  min-height: 22px;
}
#apollo-page .external-cookie-notice .pp-toggle .toggle-box {
  width: 150px;
}
#apollo-page .external-cookie-notice .pp-toggle .toggle-slider {
  width: 16px;
  right: 128px;
  background-color: #585f69;
}
#apollo-page .external-cookie-notice .pp-toggle .toggle-inner::before, #apollo-page .external-cookie-notice .pp-toggle .toggle-inner::after {
  height: 20px;
  line-height: 20px;
  font-size: 85%;
}
#apollo-page .external-cookie-notice .pp-toggle .toggle-inner::after {
  background-color: #eee;
}
#apollo-page .external-cookie-notice .pp-toggle .toggle-text {
  font-size: inherit;
}

#apollo-page .modal {
  z-index: 151000;
}
#apollo-page .modal .modal-dialog.cookie-notice .external-cookie-notice {
  padding: 1rem;
}
#apollo-page .modal .modal-dialog.cookie-notice .external-cookie-notice .modal-footer {
  border-top: none;
}
#apollo-page .modal .modal-dialog.cookie-notice .external-cookie-notice .modal-footer .btn + .btn, #apollo-page .modal .modal-dialog.cookie-notice .external-cookie-notice .modal-footer .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button + .btn, .ap.type-wallsio:not(.external-cookie-notice) #apollo-page .modal .modal-dialog.cookie-notice .external-cookie-notice .modal-footer .wallsio-load-more-button + .btn, #apollo-page .modal .modal-dialog.cookie-notice .external-cookie-notice .modal-footer .ap.type-wallsio:not(.external-cookie-notice) .btn + .wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) #apollo-page .modal .modal-dialog.cookie-notice .external-cookie-notice .modal-footer .btn + .wallsio-load-more-button, #apollo-page .modal .modal-dialog.cookie-notice .external-cookie-notice .modal-footer .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button + .wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) #apollo-page .modal .modal-dialog.cookie-notice .external-cookie-notice .modal-footer .wallsio-load-more-button + .wallsio-load-more-button {
  margin-left: 20px;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #444;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  /*
      body {
        min-width: $print-body-min-width !important;
      }
      .container {
        min-width: $print-body-min-width !important;
      }
  */
  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #444 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #444;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #444;
  }
}
/**********
 * Source file: apollo-search.scss
 *
 * Styles used by the search function display formatter.
 */
.ap-search hr {
  margin: 10px 0;
}
.ap-search .panel {
  margin-bottom: 10px;
}
.ap-search .panel-default > .panel-heading,
.ap-search .panel-default > .panel-footer {
  padding: 5px 15px;
}
.ap-search .form-control,
.ap-search .input-group-btn .btn,
.ap-search .input-group-btn .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button,
.ap.type-wallsio:not(.external-cookie-notice) .ap-search .input-group-btn .wallsio-load-more-button {
  line-height: 1;
  height: 34px;
  padding: 6px 12px;
}
.ap-search .form-control:focus {
  border-color: #00565f;
}
.ap-search select.form-control {
  -webkit-appearance: none;
  border-radius: 0px;
}

/**********
 * Source file: apollo-section.scss
 *
 * Styles used by the "content section" element.
 */
.ap-section .copyright,
.ap-paragraph .copyright,
.ap-teaser .copyright,
.ap-element .copyright,
.ap-detail-page .copyright {
  position: relative;
}
.ap-section .copyright > div,
.ap-paragraph .copyright > div,
.ap-teaser .copyright > div,
.ap-element .copyright > div,
.ap-detail-page .copyright > div {
  display: inline-block;
  color: #000;
  background-color: rgba(255, 255, 255, 0.6);
  opacity: 0.8;
  position: absolute;
  bottom: 0;
  pointer-events: none;
  z-index: 7;
  box-shadow: none;
  right: 0;
  left: initial;
  text-align: right;
  padding: 2px 4px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 8px;
  line-height: 1;
}

@media (min-width: 992px) {
  .ap-section .ap-paragraph .col-md-4.ap,
.ap-detail-page .ap-paragraph .col-md-4.ap {
    margin-bottom: 0;
  }
}

.ap-section .ap-image-section .img-responsive {
  width: 100%;
}
.ap-section .ap-image-section a:hover {
  text-decoration: none;
}
.ap-section.head-only {
  margin-bottom: 0;
}
.ap-section .head + .text,
.ap-section .head + .ap-image-section,
.ap-section .ap-image-section + .head,
.ap-section .ap-image-section + .text,
.ap-section .ap-image-section .image-outer-box + .image-info {
  margin-top: 10px;
}
@media (min-width: 768px) {
  .ap-section.head-link {
    position: relative;
  }
  .ap-section.head-link > a.right-link {
    position: absolute;
    bottom: 3px;
    right: 0;
  }
}

.same-height .ap-section {
  position: relative;
}
.same-height .ap-section > .text {
  padding-bottom: calc(16px + 2.1rem);
}
.same-height .ap-section > .link {
  position: absolute;
  bottom: 0;
}

.ap-paragraph .content div:last-of-type p:last-child {
  margin-bottom: 0;
}

/* Jumbotron formatter */
.ap-section.jumbotron {
  border-radius: 0;
  padding: 30px 15px;
}
.ap-section.jumbotron h1, .ap-section.jumbotron .h1, .ap-section.jumbotron .ap-header .bis-site .sitename h2, .ap-header .bis-site .sitename .ap-section.jumbotron h2 {
  line-height: 1;
}
.ap-section.jumbotron:not(.box) {
  background-color: #eee;
}
@media (min-width: 768px) {
  .ap-section.jumbotron {
    padding: 45px 60px;
  }
}

/* Icon box formatter */
.ap-iconbox {
  background-color: #009bab;
  overflow: hidden;
  z-index: 5;
  padding: 20px;
  text-align: center;
}
.ap-iconbox *:not(a):not(span) {
  color: #fff;
}
.ap-iconbox .text-box a {
  text-decoration: none;
}
.ap-iconbox .text-box a:hover {
  text-decoration: none;
}
.ap-iconbox .text-box h3 {
  margin-bottom: 25px;
}
.ap-iconbox .text-box div.icon-box {
  width: 60px;
  font-size: 32px;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .ap-iconbox .text-box {
    margin: -10px -5px -15px -5px;
  }
  .ap-iconbox .text-box h3 {
    margin-bottom: 15px;
  }
  .ap-iconbox .text-box div.icon-box {
    width: 40px;
    font-size: 24px;
    margin-bottom: 15px;
  }
}

/* Detail pages */
.ap-detail-page,
.ap-paragraph {
  clear: both;
  margin-bottom: 20px;
}
.ap-detail-page span.detail-icon, .ap-detail-page i.detail-icon,
.ap-paragraph span.detail-icon,
.ap-paragraph i.detail-icon {
  color: #009bab;
  width: 35px;
  height: 35px;
  font-size: 20px;
  line-height: 35px;
  margin-bottom: 5px;
  text-align: center;
  display: inline-block;
  border: solid 1px #009bab;
}

.ap-detail-page .ap-paragraph.ap-no-clear {
  clear: none;
}
.ap-detail-page .visual-image {
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.ap-detail-page .visual-image.ap-parallax-bg {
  height: 400px;
}
.ap-detail-page .visual-image.ap-parallax-bg .copyright {
  position: static;
}
.ap-detail-page .visual-image .ap-image .image-outer-box {
  min-height: 200px;
}
.ap-detail-page .visual-image .visual-overlay {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 80%;
  pointer-events: none;
  z-index: 7;
  transform: translate(-50%, -50%);
}
.ap-detail-page .visual-image .visual-overlay h1,
.ap-detail-page .visual-image .visual-overlay h2,
.ap-detail-page .visual-image .visual-overlay h3 {
  margin: 0;
  text-align: center;
  font-weight: 300;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.ap-detail-page .visual-image .visual-overlay h1 {
  font-size: 48px;
  line-height: 1.15em;
}
.ap-detail-page .visual-image .visual-overlay h2 {
  font-size: 24px;
  line-height: 1.15em;
  margin-top: 10px;
}
.ap-detail-page .visual-image .visual-overlay h3 {
  font-size: 18px;
  line-height: 1.15em;
  margin-top: 10px;
}
.ap-detail-page .visual-image .visual-darken {
  position: absolute;
  pointer-events: none;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 7;
  background-image: linear-gradient(rgba(99, 99, 99, 0), rgba(99, 99, 99, 0.3) 30%, rgba(99, 99, 99, 0.3) 70%, rgba(99, 99, 99, 0));
}
.ap-detail-page .visual-image .visual-subtitle {
  margin-top: 10px;
  line-height: 1.15;
  font-style: italic;
}
.ap-detail-page .visual-header .headline {
  margin-bottom: 5px;
}
.ap-detail-page .visual-info {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.15;
}
.ap-detail-page .visual-info .images,
.ap-detail-page .visual-info .author,
.ap-detail-page .visual-info .location,
.ap-detail-page .visual-info .infogroup,
.ap-detail-page .visual-info .type,
.ap-detail-page .visual-info .performer,
.ap-detail-page .visual-info .date {
  float: none;
  display: flex;
}
.ap-detail-page .visual-info .images::before,
.ap-detail-page .visual-info .author::before,
.ap-detail-page .visual-info .location::before,
.ap-detail-page .visual-info .infogroup::before,
.ap-detail-page .visual-info .type::before,
.ap-detail-page .visual-info .performer::before,
.ap-detail-page .visual-info .date::before {
  vertical-align: top;
  display: inline-block;
  width: 25px;
  font-family: FontAwesome;
  padding-right: 10px;
}
.ap-detail-page .visual-info .images > div,
.ap-detail-page .visual-info .author > div,
.ap-detail-page .visual-info .location > div,
.ap-detail-page .visual-info .type > div,
.ap-detail-page .visual-info .performer > div,
.ap-detail-page .visual-info .date > div {
  display: inline-block;
}
.ap-detail-page .visual-info .author::before {
  content: "";
}
.ap-detail-page .visual-info .images::before {
  content: "";
}
.ap-detail-page .visual-info .location::before {
  content: "";
}
.ap-detail-page .visual-info .location .locname {
  margin-top: 0;
}
.ap-detail-page .visual-info .date {
  float: right;
}
.ap-detail-page .visual-info .date::before {
  content: "";
}
.ap-detail-page .visual-info .type {
  float: right;
}
.ap-detail-page .visual-info .type::before {
  content: "";
}
.ap-detail-page .visual-info .performer {
  float: right;
}
.ap-detail-page .visual-info .performer::before {
  content: "";
}
.ap-detail-page .visual-info .infogroup {
  display: inline-block;
  float: right;
}
.ap-detail-page .visual-info .infogroup > div {
  float: none;
}
.ap-detail-page .visual-info .infogroup > div + div {
  margin-top: 10px;
}
.ap-detail-page .visual-text {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .ap-detail-page .sideImage.visual-image {
    width: calc(((100% + 30px) / 100) * 41.6);
    background-color: #fff;
  }
  .ap-detail-page .sideImage.visual-image.right {
    padding-left: 30px;
    float: right;
  }
  .ap-detail-page .sideImage.visual-image.left {
    padding-right: 30px;
    float: left;
  }
  .ap-detail-page .sideImage.visual-info > div.infogroup > div,
.ap-detail-page .sideImage.visual-info .date {
    float: none;
    margin-bottom: 10px;
  }
  .ap-detail-page .sideImage.visual-info > div:last-of-type {
    margin-bottom: 0px;
  }
  .ap-detail-page .sideImage.visual-info .infogroup {
    display: inline;
    float: none;
  }
  .ap-detail-page .sideImage.visual-info .images,
.ap-detail-page .sideImage.visual-info .author,
.ap-detail-page .sideImage.visual-info .location,
.ap-detail-page .sideImage.visual-info .type,
.ap-detail-page .sideImage.visual-info .performer,
.ap-detail-page .sideImage.visual-info .date {
    display: flex;
  }
  .ap-detail-page .sideImage.visual-header {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .ap-detail-page .visual-info > div,
.ap-detail-page .visual-info .infogroup {
    display: block;
    float: none;
    line-height: 1.25em;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .ap-detail-page .visual-info .date {
    float: none;
  }
  .ap-detail-page .visual-info > div:last-of-type {
    margin-bottom: 0px;
  }
  .ap-detail-page .visual-image .ap-image .image-outer-box {
    min-height: 100px;
  }
  .ap-detail-page .visual-image .visual-overlay h1,
.ap-detail-page .visual-image .visual-overlay h2,
.ap-detail-page .visual-image .visual-overlay h3 {
    font-weight: normal;
  }
}
@media (max-width: 496px) {
  .ap-detail-page .visual-image .visual-overlay h1 {
    font-size: 32px;
    line-height: 1.15em;
  }
}

.ap-teaser .teaser-body .teaser-date {
  font-style: italic;
}

.reorder {
  display: table;
  width: 100%;
}
.reorder .reorder-top {
  display: table-header-group;
}
.reorder .reorder-middle {
  display: table-row-group;
}
.reorder .reorder-bottom {
  display: table-footer-group;
}

.ap-image-margin .ap-image-section {
  margin-bottom: 20px;
}

/* Image descriptions */
.image-info .subtitle {
  margin-top: 10px;
  line-height: 1.15;
}
.image-info .subtitle.header, .image-info .subtitle.hcentered {
  margin-top: 10px;
}
.image-info .subtitle.basic, .image-info .subtitle.centered {
  font-style: italic;
}
.image-info .subtitle.centered, .image-info .subtitle.hcentered {
  text-align: center;
}
.image-info .text {
  margin-top: 10px;
}

/* Colored rows have a default color */
.ap-colored-row {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #eee;
}

/* Remove the bottom margin of all sections, usually required for colored template rows */
.ap-tile .ap-iconbox,
.ap-tile .ap-section,
.no-section-margin .ap-iconbox,
.no-section-margin .ap-section,
.ap-colored-row .ap-iconbox,
.ap-colored-row .ap-section {
  margin-bottom: 0px;
}

section.colored-row {
  display: block;
  padding-top: 40px;
  padding-bottom: 40px;
}
section.colored-row:not(.switch-color):nth-of-type(odd) {
  background-color: #eee;
}
section.colored-row.switch-color:nth-of-type(even) {
  background-color: #eee;
}

/* Apply "no-gutter" to a bootsrap "row" in order to remove the default padding from
   the included divs. To be used to create full width screen elements using a structure like this:
   div.container-fluid > div.row.no-gutter > div.col-xs-12. */
.no-gutter,
.row.no-gutter > div {
  padding-left: 0;
  padding-right: 0;
}

/* Remove the negative margin from a bootstrap row. */
.row.no-margin {
  margin-left: 0;
  margin-right: 0;
}

.no-ap {
  margin-bottom: 0;
}
.no-ap .ap {
  margin-bottom: 0;
}

.no-headline-margin .headline {
  margin-bottom: 0;
}

/* Image / text flip animation, see https://davidwalsh.name/css-flip#ieflip */
.ap.imageflip {
  perspective: 1000px;
  transform-style: preserve-3d;
  position: relative;
}
.ap.imageflip:hover {
  z-index: 50;
}
.ap.imageflip:hover .back {
  transform: rotateY(0deg);
  visibility: visible;
  opacity: 1;
}
.ap.imageflip:hover .front:not(.noflip) {
  transform: rotateY(180deg);
  pointer-events: none;
}
.ap.imageflip .flipper {
  width: 100%;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}
.ap.imageflip .front,
.ap.imageflip .back {
  width: 100%;
  backface-visibility: hidden;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: absolute;
  top: 0;
  left: 0;
}
.ap.imageflip .front {
  z-index: 2;
  transform: rotateY(0deg);
}
.ap.imageflip .back {
  transform: rotateY(-180deg);
  visibility: hidden;
  opacity: 0;
}
.ap.imageflip .text-section {
  padding: 20px;
  height: 100%;
  overflow: auto;
  background-color: #eee;
}
.ap.imageflip .text-section.front:not(.noflip) {
  overflow: hidden;
}
.ap.imageflip.color-white .text-section {
  background-color: #fff;
  border: 1px solid #eee;
}
.ap.imageflip.color-grey .text-section {
  background-color: #eee;
}
.ap.imageflip.color-theme .text-section {
  background-color: #009bab;
  color: #fff;
}
.ap.imageflip.color-theme .text-section *:not(a):not(span) {
  color: #fff;
}
.ap.imageflip.color-theme .text-section .btn, .ap.imageflip.color-theme .text-section .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .ap.imageflip.color-theme .text-section .wallsio-load-more-button {
  border-color: #fff;
  background-color: #fff;
  color: #585f69;
}
.ap.imageflip.p-small .back {
  padding: 10px;
}
.ap.imageflip.ap-tile {
  border-radius: 0;
}
.ap.imageflip.ap-tile .image-src-box,
.ap.imageflip.ap-tile .text-section {
  border-radius: 0;
}

@media (min-width: 992px) {
  html:not(.mobile) .ap-flipraise-animation .ap.imageflip:hover .back,
html:not(.mobile) .ap-flipraise-animation.ap.imageflip:hover .back {
    top: -40px;
    left: -40px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    width: calc(100% + 80px);
    height: calc(100% + 80px);
  }
  html:not(.mobile) .ap-flipraise-animation .ap.imageflip:hover .back.text-section,
html:not(.mobile) .ap-flipraise-animation.ap.imageflip:hover .back.text-section {
    padding: 20px;
  }
}
/**********
 * Source file:  apollo-sitemap.scss
 *
 * Styles used by the "sitemap" element.
 */
.ap-sitemap {
  margin-bottom: 10px;
}
.ap-sitemap ul,
.ap-sitemap li {
  margin: 0;
  padding: 0;
  list-style: none;
  background: transparent;
}
.ap-sitemap li.top {
  float: left;
  width: 100%;
  padding-bottom: 10px;
}
.ap-sitemap li.top a {
  color: #fff;
  background-color: #009bab;
  font-weight: bold;
  border-radius: 0;
  font-size: 1em;
  line-height: 1;
  padding: 6px 10px;
  width: 100%;
  display: block;
  height: 100%;
  text-decoration: none;
  position: relative;
}
.ap-sitemap li.top a.nav-toggle:after {
  position: absolute;
  display: inline-block;
  font-family: FontAwesome;
  font-weight: normal;
  content: "";
  top: 6px;
  right: 9px;
  transform: rotate(-180deg) translate(0, 0);
  transition: transform 0.33s;
}
.ap-sitemap li.top a.nav-toggle.collapsed:after {
  transform: rotate(0) translate(0, 0);
  transition: transform 0.33s;
}
.ap-sitemap li.top li a {
  color: #fff;
  background-color: #00565f;
  font-weight: normal;
  padding-top: 6px;
  padding-bottom: 6px;
}
.ap-sitemap li.top li a:hover {
  color: #fff;
  background-color: #009bab;
  text-decoration: none;
}
.ap-sitemap li.top li a.nav-toggle:after {
  top: 6px;
}
@media (min-width: 992px) {
  .ap-sitemap .col-6 > li.top {
    width: 50%;
    padding-right: 10px;
  }
  .ap-sitemap .col-6 > li.top:nth-of-type(2n+1) {
    clear: both;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .ap-sitemap .col-4 > li.top {
    width: 50%;
    padding-right: 10px;
  }
  .ap-sitemap .col-4 > li.top:nth-of-type(2n+1) {
    clear: both;
  }
}
@media (min-width: 992px) {
  .ap-sitemap .col-4 > li.top {
    width: 33.33333333%;
    padding-right: 10px;
  }
  .ap-sitemap .col-4 > li.top:nth-of-type(3n+1) {
    clear: both;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .ap-sitemap .col-3 > li.top {
    width: 50%;
    padding-right: 10px;
  }
  .ap-sitemap .col-3 > li.top:nth-of-type(2n+1) {
    clear: both;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .ap-sitemap .col-3 > li.top {
    width: 33%;
    padding-right: 10px;
  }
  .ap-sitemap .col-3 > li.top:nth-of-type(3n+1) {
    clear: both;
  }
}
@media (min-width: 1200px) {
  .ap-sitemap .col-3 > li.top {
    width: 25%;
    padding-right: 10px;
  }
  .ap-sitemap .col-3 > li.top:nth-of-type(4n+1) {
    clear: both;
  }
}
.ap-sitemap li.top > ul > li > ul li a {
  padding-left: 20px;
}
.ap-sitemap li.top > ul > li > ul > li > ul li a {
  padding-left: 30px;
}
.ap-sitemap li.top > ul > li > ul > li > ul > li > ul li a {
  padding-left: 40px;
}
.ap-sitemap li.top > ul > li > ul > li > ul > li > ul > li > ul li a {
  padding-left: 50px;
}

/* Colored sitemap options */
.ap-sitemap.color-grey-light li.top a {
  color: #585f69;
  background: #eeeeee;
}
.ap-sitemap.color-grey-light li.top li a {
  color: #585f69;
  background: #aeaeae;
}
.ap-sitemap.color-grey-light li.top li a:hover {
  color: #585f69;
  background: #eeeeee;
}

/**********
 * Source file: apollo-slider.scss
 *
 * Styles for the "simple slider", "complex slider" and "carousel" elements.
 */
/* Slider basic margin and font selection */
.ap-slider *:not(.fa),
.ap-complex-slider *:not(.fa),
.ap-carousel *:not(.fa) {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Slick slider settings */
@media (min-width: 992px) {
  .ap-header .pullnav-true .ap-slick-slider .ap-slick-slide .caption.bottom {
    bottom: calc($default-margin + $nav-header-total-height);
  }
  .ap-header .pullnav-true .ap-slick-slider .ap-slick-slide .copyright {
    bottom: 38px;
  }
  .ap-header .pullnav-true .ap-slick-slider .slick-dots {
    bottom: 48px;
  }
}
.ap-slick-slider .slick-slide .ap-slick-slide .caption.foreground, .ap-slick-slider .slick-slide .ap-slick-slide .caption.background {
  opacity: 0;
}
.ap-slick-slider .slick-slide.slick-current.slick-active .ap-slick-slide .caption.foreground, .ap-slick-slider .slick-slide.slick-current.slick-active .ap-slick-slide .caption.background {
  transition: opacity 1.5s linear;
}
.ap-slick-slider .slick-slide.slick-current.slick-active .ap-slick-slide .caption.foreground {
  opacity: 1;
}
.ap-slick-slider .slick-slide.slick-current.slick-active .ap-slick-slide .caption.background {
  opacity: 0.75;
}
.ap-slick-slider .slick-slide > div {
  display: flex;
}
.ap-slick-slider.ap-hero .ap-slick-slides:not(.slick-initialized) .ap-slick-slide.hide-noscript {
  visibiliy: hidden;
  max-height: 0px;
}
.ap-slick-slider.ap-logo.lm-5 {
  margin-left: -5px;
  margin-right: -5px;
}
.ap-slick-slider.ap-logo.lm-5 .ap-slick-slide .visual {
  margin: 5px;
}
.ap-slick-slider.ap-logo.lm-10 {
  margin-left: -10px;
  margin-right: -10px;
}
.ap-slick-slider.ap-logo.lm-10 .ap-slick-slide .visual {
  margin: 10px;
}
.ap-slick-slider.ap-logo.lm-15 {
  margin-left: -15px;
  margin-right: -15px;
}
.ap-slick-slider.ap-logo.lm-15 .ap-slick-slide .visual {
  margin: 15px;
}
.ap-slick-slider.ap-logo.lm-20 {
  margin-left: -20px;
  margin-right: -20px;
}
.ap-slick-slider.ap-logo.lm-20 .ap-slick-slide .visual {
  margin: 20px;
}
.ap-slick-slider.ap-logo .ap-slick-slides {
  max-height: 0;
  overflow: hidden;
}
.ap-slick-slider.ap-logo .ap-slick-slides.slick-initialized {
  max-height: none;
  overflow: visible;
}
.ap-slick-slider .ap-slick-slide {
  position: relative;
  overflow: hidden;
  z-index: 5;
}
.ap-slick-slider .ap-slick-slide .visual {
  position: relative;
}
.ap-slick-slider .ap-slick-slide .img-responsive {
  width: 100%;
}
.ap-slick-slider .ap-slick-slide img.lazyloading, .ap-slick-slider .ap-slick-slide img.lazyload {
  background-color: transparent;
  opacity: 1;
}
.ap-slick-slider .ap-slick-slide img.lazyloaded {
  transition: none;
}
.ap-slick-slider .ap-slick-slide a,
.ap-slick-slider .ap-slick-slide a:focus,
.ap-slick-slider .ap-slick-slide a:hover {
  text-decoration: none;
}
.ap-slick-slider .ap-slick-slide .caption {
  padding: 5px 0;
  position: absolute;
  max-width: calc(100% - 60px);
  background-color: #009bab;
  overflow: hidden;
  z-index: 5;
}
.ap-slick-slider .ap-slick-slide .caption.background {
  opacity: 0.75;
  background-color: #009bab;
}
.ap-slick-slider .ap-slick-slide .caption.foreground {
  background-color: transparent;
}
.ap-slick-slider .ap-slick-slide .caption.bottom {
  bottom: 20px;
}
.ap-slick-slider .ap-slick-slide .caption.top {
  top: 20px;
}
.ap-slick-slider .ap-slick-slide .caption.middle {
  top: 50%;
  transform: translate(0, -50%);
}
.ap-slick-slider .ap-slick-slide .caption.left {
  left: 20px;
}
.ap-slick-slider .ap-slick-slide .caption.right {
  right: 20px;
  text-align: right;
}
.ap-slick-slider .ap-slick-slide .caption.center {
  text-align: center;
  white-space: nowrap;
  left: 50%;
  transform: translate(-50%, 0);
}
.ap-slick-slider .ap-slick-slide .caption.middle.center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.ap-slick-slider .ap-slick-slide .caption .subcaption br {
  display: none;
}
.ap-slick-slider .ap-slick-slide .caption strong,
.ap-slick-slider .ap-slick-slide .caption small {
  display: block;
  margin: 0;
  padding: 0px 10px;
  color: transparent;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ap-slick-slider .ap-slick-slide .caption.foreground strong,
.ap-slick-slider .ap-slick-slide .caption.foreground small {
  color: #fff;
  text-shadow: 1px 1px 1px rgba(51, 51, 51, 0.5);
}
.ap-slick-slider .ap-slick-slide .caption strong {
  font-size: 1.8rem;
  line-height: 1.25;
}
.ap-slick-slider .ap-slick-slide .caption small {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  line-height: 1.5;
  margin-top: 2px;
}
.ap-slick-slider .ap-slick-slide .caption small:nth-of-type(2) {
  margin-top: 0;
}
@media (min-width: 768px) {
  .ap-slick-slider .ap-slick-slide .caption strong {
    font-size: 2.4rem;
    line-height: 1.25;
  }
}
@media (min-width: 992px) {
  .ap-slick-slider .ap-slick-slide .caption strong {
    font-size: 2.8rem;
    line-height: 1.25;
  }
  .ap-slick-slider .ap-slick-slide .caption small {
    font-size: 2rem;
    line-height: 1.25;
  }
}
.ap-slick-slider .ap-slick-slide.custom .caption {
  background-color: transparent;
  padding: 0;
  border-radius: 0;
}
.ap-slick-slider .ap-slick-slide.custom .caption strong,
.ap-slick-slider .ap-slick-slide.custom .caption small {
  display: inline-block;
  float: left;
  clear: both;
}
.ap-slick-slider .ap-slick-slide.custom .caption.right strong,
.ap-slick-slider .ap-slick-slide.custom .caption.right small {
  float: right;
}
.ap-slick-slider .ap-slick-slide.custom .caption.center strong,
.ap-slick-slider .ap-slick-slide.custom .caption.center small {
  float: none;
}
.ap-slick-slider .ap-slick-slide.custom .caption.background strong,
.ap-slick-slider .ap-slick-slide.custom .caption.background small {
  background-color: #009bab;
}
.ap-slick-slider .ap-slick-slide.custom .caption strong {
  padding-top: 5px;
  padding-bottom: 5px;
}
.ap-slick-slider .ap-slick-slide.custom .caption small {
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: 5px;
}
.ap-slick-slider .ap-slick-slide.custom .caption small:nth-of-type(2) {
  margin-top: 0;
}
@media (min-width: 768px) {
  .ap-slick-slider .ap-slick-slide.custom .caption.center .subcaption br {
    display: block;
  }
}
.ap-slick-slider .ap-slick-slide .copyright {
  display: inline-block;
  color: #000;
  background-color: rgba(255, 255, 255, 0.6);
  opacity: 0.8;
  position: absolute;
  bottom: 0;
  pointer-events: none;
  z-index: 7;
  box-shadow: none;
  right: 0;
  left: initial;
  text-align: right;
  padding: 2px 4px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 8px;
  line-height: 1;
}
@media (max-width: 767px) {
  .ap-slick-slider.text-below-on-xs .ap-slick-slide,
.ap-slick-slider.text-below-on-xs .image-src-box {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ap-slick-slider.text-below-on-xs .slick-dots {
    bottom: -10px;
  }
  .ap-slick-slider.text-below-on-xs .slick-arrow {
    display: none !important;
  }
  .ap-slick-slider.text-below-on-xs .slick-list {
    background-color: #009bab;
    overflow: hidden;
    z-index: 5;
  }
  .ap-slick-slider.text-below-on-xs .ap-slider-box.custom .slick-list {
    background-color: transparent;
  }
  .ap-slick-slider.text-below-on-xs .ap-slick-slide .caption {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    position: static;
    max-width: initial;
    min-height: calc(6rem + 10px);
    background-color: #009bab;
    opacity: 1 !important;
    text-align: initial !important;
    white-space: initial !important;
    transform: initial !important;
  }
  .ap-slick-slider.text-below-on-xs .ap-slick-slide .caption.foreground {
    display: none;
  }
  .ap-slick-slider.text-below-on-xs .ap-slick-slide .caption.background strong,
.ap-slick-slider.text-below-on-xs .ap-slick-slide .caption.background small {
    color: #fff;
    text-shadow: 1px 1px 1px rgba(51, 51, 51, 0.5);
  }
  .ap-slick-slider.text-below-on-xs .ap-slick-slide strong {
    font-size: 2.4rem;
    line-height: 1.25;
    font-weight: 400;
  }
  .ap-slick-slider.text-below-on-xs .ap-slick-slide small {
    display: inline;
    padding: 0;
  }
  .ap-slick-slider.text-below-on-xs .ap-slick-slide.custom .caption strong {
    display: block;
    padding: 7px 10px;
  }
  .ap-slick-slider.text-below-on-xs .ap-slick-slide.custom .caption strong, .ap-slick-slider.text-below-on-xs .ap-slick-slide.custom .caption small {
    float: none;
    clear: none;
  }
  .ap-slick-slider.text-below-on-xs .ap-slick-slide.custom .caption small {
    display: inline;
    margin-top: 0px;
  }
  .ap-slick-slider.text-below-on-xs .ap-slick-slide .subcaption {
    display: block;
    padding: 4px 10px;
  }
}

/* Width restritions */
html.noscript .slide-count, html.noscript .slide-count-12, html.noscript .slide-count-11, html.noscript .slide-count-10, html.noscript .slide-count-9, html.noscript .slide-count-8, html.noscript .slide-count-7, html.noscript .slide-count-6, html.noscript .slide-count-5, html.noscript .slide-count-4, html.noscript .slide-count-3, html.noscript .slide-count-2, html.noscript .slide-count-1 {
  float: left;
}
html.noscript .slide-count-1 {
  width: 100%;
}
html.noscript .slide-count-2 {
  width: 50%;
}
html.noscript .slide-count-3 {
  width: 33.3333333333%;
}
html.noscript .slide-count-4 {
  width: 25%;
}
html.noscript .slide-count-5 {
  width: 20%;
}
html.noscript .slide-count-6 {
  width: 16.6666666667%;
}
html.noscript .slide-count-7 {
  width: 14.2857142857%;
}
html.noscript .slide-count-8 {
  width: 12.5%;
}
html.noscript .slide-count-9 {
  width: 11.1111111111%;
}
html.noscript .slide-count-10 {
  width: 10%;
}
html.noscript .slide-count-11 {
  width: 9.0909090909%;
}
html.noscript .slide-count-12 {
  width: 8.3333333333%;
}

.ap-slider .slider,
.ap-complex-slider .slider {
  background-color: #f6f6f6;
}
.ap-slider .slider .caption.copyright,
.ap-complex-slider .slider .caption.copyright {
  padding: 2px 4px !important;
  font-size: 0.7em !important;
  background: rgba(255, 255, 255, 0.6);
  color: black;
  opacity: 0.9 !important;
}

/* Simple (Revolution) slider settings (caption, copyright) */
.ap-slider .slider .caption {
  padding: 5px 10px 5px 10px !important;
  background-color: #009bab;
  color: #2cebff;
}
.ap-slider .slider .caption h2,
.ap-slider .slider .caption h3 {
  margin: 0;
  padding: 0;
  color: #fff;
}
.ap-slider .slider .caption h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.25;
}
.ap-slider .slider .caption h3 {
  font-size: 1.4rem;
  line-height: 1.5;
  margin-top: 2px;
}
.ap-slider .slider .caption h3:nth-of-type(2) {
  margin-top: 0;
}
@media (min-width: 768px) {
  .ap-slider .slider .caption h2 {
    font-size: 2.4rem;
    line-height: 1.25;
  }
}
@media (min-width: 992px) {
  .ap-slider .slider .caption h2 {
    font-size: 2.8rem;
    line-height: 1.25;
  }
  .ap-slider .slider .caption h3 {
    font-size: 2rem;
    line-height: 1.25;
  }
}
.ap-slider .slider .slotholder {
  border-radius: 0;
}
.ap-slider .slider .slotholder .tp-bgimg {
  border-radius: 0;
}
.ap-slider .slider .revslider-initialised.tp-simpleresponsive {
  position: relative;
}

/* Styles to force text below slides on XS */
@media (max-width: 767px) {
  .ap-slider.text-below-on-xs .rev_slider .tp-parallax-wrap,
.ap-slider.text-below-on-xs .rev_slider .tp-loop-wrap {
    width: 100% !important;
  }
  .ap-slider.text-below-on-xs .rev_slider .tp-parallax-wrap {
    left: 0 !important;
    bottom: 0 !important;
    top: auto !important;
  }
  .ap-slider.text-below-on-xs .rev_slider .tp-revslider-mainul,
.ap-slider.text-below-on-xs .rev_slider .tp-revslider-slidesli {
    overflow: visible !important;
  }
  .ap-slider.text-below-on-xs .rev_slider .tp-mask-wrap {
    width: 100%;
  }
  .ap-slider.text-below-on-xs .rev_slider .tp-caption.maincontent {
    padding-left: 15px !important;
    padding-right: 15px !important;
    white-space: normal !important;
    height: 120px !important;
    opacity: 1 !important;
  }
  .ap-slider.text-below-on-xs .rev_slider .tp-caption.maincontent h2 {
    font-size: 2.4rem;
    line-height: 1.25;
    font-weight: 400;
  }
  .ap-slider.text-below-on-xs .rev_slider .tp-caption.copyright {
    line-height: 1 !important;
    bottom: 20px;
    display: inline-block;
    width: auto;
  }
  .ap-slider.text-below-on-xs .placeholder {
    height: 120px;
  }
}
/* Slider caption styles */
.tp-caption.mediumthickblackbg, .tp-caption.mediumthickgreybg, .tp-caption.mediumthickwhitebg {
  font-size: 36px;
  line-height: 36px;
  font-weight: bold;
  text-decoration: none;
  text-shadow: none;
  border-width: 0px none #fff;
  padding: 0 4px;
}
.tp-caption.mediumthickblackbg {
  color: #fff;
  background-color: #000;
}
.tp-caption.mediumthickgreybg {
  color: #fff;
  background-color: #aaa;
}
.tp-caption.blackbgtransparent {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 7px;
}
.tp-caption.mediumthickwhitebg {
  color: #000;
  background-color: #fff;
}

.ap-sl-responsive .tp-caption.mediumthickblackbg, .ap-sl-responsive .tp-caption.mediumthickgreybg, .ap-sl-responsive .tp-caption.mediumthickwhitebg {
  font-size: 16px !important;
  line-height: 16px !important;
}
@media (min-width: 768px) {
  .ap-sl-responsive .tp-caption.mediumthickblackbg, .ap-sl-responsive .tp-caption.mediumthickgreybg, .ap-sl-responsive .tp-caption.mediumthickwhitebg {
    font-size: 26px !important;
    line-height: 26px !important;
  }
}
@media (min-width: 992px) {
  .ap-sl-responsive .tp-caption.mediumthickblackbg, .ap-sl-responsive .tp-caption.mediumthickgreybg, .ap-sl-responsive .tp-caption.mediumthickwhitebg {
    font-size: 32px !important;
    line-height: 32px !important;
  }
}
@media (min-width: 1200px) {
  .ap-sl-responsive .tp-caption.mediumthickblackbg, .ap-sl-responsive .tp-caption.mediumthickgreybg, .ap-sl-responsive .tp-caption.mediumthickwhitebg {
    font-size: 36px !important;
    line-height: 36px !important;
  }
}

/* Carousel */
.ap-carousel .carousel-caption {
  padding: 2px 10px 8px 10px;
  opacity: 0.8;
  font-size: 15px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #009bab;
  opacity: 0.7;
}
.ap-carousel .carousel-caption.carousel-caption-copyright {
  bottom: 17px;
}
.ap-carousel .carousel-caption p {
  color: #fff;
  margin-bottom: 0;
}
.ap-carousel .carousel-caption h2,
.ap-carousel .carousel-caption h3 {
  color: #fff;
  margin: 0;
  padding: 6px 0 0 0;
  font-weight: bold;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}
.ap-carousel .carousel-caption h2 {
  font-size: 2rem;
  line-height: 1.25;
}
.ap-carousel .carousel-caption h3 {
  font-size: 15px;
  line-height: 1.1;
}
.ap-carousel .carousel-copyright {
  padding-top: 5px;
  z-Index: 2;
  font-size: 10px;
  font-style: italic;
  text-align: center;
  line-height: 11px;
  opacity: 0.6;
}
.ap-carousel .carousel-arrow a.carousel-control {
  opacity: 1;
  font-size: 30px;
  height: inherit;
  width: inherit;
  background: none;
  text-shadow: none;
  position: inherit;
}
.ap-carousel .carousel-arrow a.carousel-control:hover {
  color: #009bab;
}
.ap-carousel .carousel-arrow a span {
  top: 50%;
  opacity: 0.6;
  background: #000;
  margin-top: -18px;
  padding: 2px 12px;
  position: absolute;
}
.ap-carousel .carousel-arrow a span:hover {
  opacity: 0.8;
}
.ap-carousel .carousel-arrow a.left span {
  left: 0;
}
.ap-carousel .carousel-arrow a.right span {
  right: 0;
}

/**********
 * Source file: apollo-socialicons.scss
 *
 * Styles for the "shariff" and the "disqus" element as well as social networks in general.
 */
/* Default social icon style, grey and boxed */
.ap-social {
  list-style: none;
  font-size: 0;
  padding: 0;
}
.ap-social .fas,
.ap-social .fab {
  display: inline-block;
  font-family: FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.ap-social .fa-linkedin-in:before {
  content: "";
}
.ap-social ul {
  margin: 0;
  padding: 0;
}
.ap-social li {
  display: inline-block;
  margin-top: 0;
  margin-left: 0;
  margin-right: 4px;
  margin-bottom: 4px;
  vertical-align: middle;
}
.ap-social li a:not(.btn) {
  background-color: #aaa;
  width: 40px;
  height: 40px;
  display: block;
  border: none;
  font-size: 20px;
  line-height: 40px;
  transition: background-color 0.33s, background 0.33s;
}
.ap-social li a:not(.btn) * {
  color: #585f69;
}
.ap-social li a:not(.btn):hover {
  text-decoration: none;
}
.ap-social li a:not(.btn):hover * {
  color: #fff;
}
.ap-social li:last-of-type {
  margin-right: 0;
}
.ap-social .share_text {
  display: none;
  text-align: left;
  padding-right: 10px;
  font-size: 1.4rem;
  vertical-align: 2px;
}
.ap-social .fa, .ap-social .fas, .ap-social .fab {
  width: 40px;
  line-height: 40px;
  text-align: center;
}
.ap-social .bgi {
  width: 40px;
  height: 40px;
  vertical-align: top;
}

/* Add class 'verbose' to see the 'share' text right to the icons. */
.ap-social.verbose li a {
  width: initial;
}
.ap-social.verbose .share_text {
  display: inline;
}

/* Add class 'round ' for round icons instead of square icons. */
.ap-social.round li a {
  border-radius: 100%;
}
.ap-social.round.verbose li a {
  border-radius: 10px;
}

/* Add class 'stacked' to stack the icons vertically. */
.ap-social.stacked li {
  display: list-item;
  margin-right: 0;
}
.ap-social.stacked li a {
  min-width: initial;
}

/**
 * Responsive adjustmensts:
 *
 * Use 'stacked-sm', 'stacked-md' or 'stacked-lg' to start stacking from selected screen with.
 * Use 'verbose-sm', 'verbose-md' or 'verbose-lg' to show button 'share' text from selected screen with.
 */
@media (min-width: 768px) {
  .ap-social.verbose li a {
    min-width: 140px;
  }
  .ap-social.verbose-sm li a {
    min-width: 140px;
    width: initial;
  }
  .ap-social.verbose-sm .share_text {
    display: inline;
  }
  .ap-social.round.verbose-sm li a {
    border-radius: 10px;
  }
  .ap-social.stacked-sm li {
    display: list-item;
    margin-right: 0;
  }
  .ap-social.stacked-sm li a {
    min-width: initial;
  }
}
@media (min-width: 992px) {
  .ap-social.verbose li a {
    min-width: 184px;
  }
  .ap-social.verbose-md li a {
    min-width: 184px;
    width: initial;
  }
  .ap-social.verbose-md .share_text {
    display: inline;
  }
  .ap-social.round.verbose-md li a {
    border-radius: 10px;
  }
  .ap-social.stacked-md li {
    display: list-item;
    margin-right: 0;
  }
  .ap-social.stacked-md li a {
    min-width: initial;
  }
}
@media (min-width: 1200px) {
  .ap-social.verbose li a {
    min-width: 224px;
  }
  .ap-social.verbose-lg li a {
    min-width: 224px;
    width: initial;
  }
  .ap-social.verbose-lg .share_text {
    display: inline;
  }
  .ap-social.round.verbose-lg li a {
    border-radius: 10px;
  }
  .ap-social.stacked-lg li {
    display: list-item;
    margin-right: 0;
  }
  .ap-social.stacked-lg li a {
    min-width: initial;
  }
}

/* Social icons default style is grey and colored on hover */
.ap-social li.facebook a:hover {
  background-color: #3b5998;
}

.ap-social li.youtube a:hover {
  background-color: #ff0000;
}

.ap-social li.twitter a:hover {
  background-color: #55acee;
}

.ap-social li.github a:hover {
  background-color: #777777;
}

.ap-social li.xing a:hover {
  background-color: #126567;
}

.ap-social li.rss a:hover {
  background-color: #ee802f;
}

.ap-social li.whatsapp a:hover {
  background-color: #5cbe4a;
}

.ap-social li.linkedin a:hover {
  background-color: #0976b4;
}

.ap-social li.instagram a:hover {
  background-color: #d6249f;
}

.ap-social li.mail a:hover {
  background-color: #999999;
}

.ap-social li.wallsio a:hover {
  background-color: #000000;
}

.ap-social li.slideshare a:hover {
  background-color: #e98325;
}

/*  Add class 'themed' to use the main theme color for the icons */
.ap-social.themed li a {
  background-color: #009bab;
}
.ap-social.themed li a * {
  color: #fff;
}

/* Add class 'colored' for individually colored icons */
.ap-social.colored li a * {
  color: #fff;
}
.ap-social.colored li.facebook a {
  background-color: #3b5998;
}
.ap-social.colored li.facebook a:hover {
  background-color: #3467d2;
}
.ap-social.colored li.youtube a {
  background-color: #ff0000;
}
.ap-social.colored li.youtube a:hover {
  background-color: #ff3333;
}
.ap-social.colored li.twitter a {
  background-color: #55acee;
}
.ap-social.colored li.twitter a:hover {
  background-color: #77c4ff;
}
.ap-social.colored li.github a {
  background-color: #777777;
}
.ap-social.colored li.github a:hover {
  background-color: #a77a7a;
}
.ap-social.colored li.xing a {
  background-color: #126567;
}
.ap-social.colored li.xing a:hover {
  background-color: #08a0a4;
}
.ap-social.colored li.rss a {
  background-color: #ee802f;
}
.ap-social.colored li.rss a:hover {
  background-color: #ff9b51;
}
.ap-social.colored li.whatsapp a {
  background-color: #5cbe4a;
}
.ap-social.colored li.whatsapp a:hover {
  background-color: #70df5c;
}
.ap-social.colored li.linkedin a {
  background-color: #0976b4;
}
.ap-social.colored li.linkedin a:hover {
  background-color: #0099f0;
}
.ap-social.colored li.instagram a {
  background-color: #d6249f;
}
.ap-social.colored li.instagram a:hover {
  background-color: #f637bb;
}
.ap-social.colored li.mail a {
  background-color: #999999;
}
.ap-social.colored li.mail a:hover {
  background-color: #c2a3a3;
}
.ap-social.colored li.wallsio a {
  background-color: #000000;
}
.ap-social.colored li.wallsio a:hover {
  background-color: #1f1414;
}
.ap-social.colored li.slideshare a {
  background-color: #e98325;
}
.ap-social.colored li.slideshare a:hover {
  background-color: #ff9d42;
}

/* Individual icon adjustments per type */
.ap-social.colored li.instagram a,
.ap-social li.instagram a:hover {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}
.ap-social.colored li.instagram a:hover {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fff69a 5%, #ff6f61 45%, #f529b6 60%, #285AEB 90%);
}
.ap-social li.instagram a {
  font-size: 36px;
}
.ap-social.colored li.youtube a:hover {
  background-color: #c00;
}
.ap-social.colored li.wallsio a:hover {
  background-color: #444;
}

/**********
 * Source file: apollo-tabs.scss
 *
 * Styles used by the "Tabs / Accordion" element.
 */
/* Tabs */
.ap-tab-pane .row .ap:last-child {
  margin-bottom: 0;
}

.ap-tab .nav-tabs {
  border: none;
  background: none;
  border-bottom: solid 2px #009bab;
}
.ap-tab .nav-tabs a {
  font-size: 14px;
  padding: 5px 15px;
}
.ap-tab .nav-tabs > li {
  margin: 0;
}
.ap-tab .nav-tabs > li > a,
.ap-tab .nav-tabs > li > a * {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #585f69;
  border: none;
  text-transform: none;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
  line-height: 1;
  margin: 0;
}
.ap-tab .nav-tabs > .active > a,
.ap-tab .nav-tabs > .active > a *,
.ap-tab .nav-tabs > .active > a:focus,
.ap-tab .nav-tabs > .active > a:focus * {
  color: #fff;
  border: none;
  background: #009bab;
}
.ap-tab .nav-tabs > li > a:hover,
.ap-tab .nav-tabs > li > a:hover *,
.ap-tab .nav-tabs > .active > a:hover,
.ap-tab .nav-tabs > .active > a:hover * {
  color: #fff;
  border: none;
  background: #00565f;
}
.ap-tab .tab-content {
  padding: 10px 0;
}

/* Colored tabs */
.color-grey-light .ap-tab .nav-tabs {
  border-bottom: solid 2px #eeeeee;
}
.color-grey-light .ap-tab .nav-tabs > .active > a,
.color-grey-light .ap-tab .nav-tabs > .active > a:focus {
  background: #eeeeee;
  color: #585f69;
}
.color-grey-light .ap-tab .nav-tabs > li > a:hover,
.color-grey-light .ap-tab .nav-tabs > .active > a:hover {
  background: #00565f;
  color: #fff;
}

/* General panels, reset some Bootstrap values (also for other elements, not only Accordion) */
.panel-body .ap-detail-page:last-child,
.panel-body .ap-paragraph:last-child,
.panel-body .row .ap:last-child {
  margin-bottom: 0;
}

.panel-title {
  font-size: 1.8rem;
  line-height: 1.1;
  text-transform: none;
}

.panel,
.panel-group .panel {
  border-radius: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}
.panel + .panel,
.panel-group .panel + .panel {
  margin-top: 5px;
  margin-bottom: 5px;
}

.panel,
.panel-body,
.panel-heading + .panel-collapse > .panel-body {
  border-color: #eeeeee;
}

/* Accordion panels */
.ap-panel.panel-group {
  margin-bottom: 20px;
}
.ap-panel.panel-group .panel-heading {
  padding: 0;
  border-radius: 0;
}
.ap-panel.panel-group .panel-heading .panel-title a > * {
  display: block;
  color: inherit;
  background-color: inherit;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.ap-panel.panel-group .panel-heading .panel-title a,
.ap-panel.panel-group .panel-heading .panel-title a.accordion-toggle.collapsed {
  color: #585f69;
  background: #eee;
  transition: all 0.2s ease-in-out;
}
.ap-panel.panel-group .panel-heading .panel-title a.accordion-toggle.collapsed:hover,
.ap-panel.panel-group .panel-heading .panel-title a:hover {
  color: #fff;
  background: #009bab;
  text-decoration: none;
}

/* Colored accordion panels */
.color-grey-light .panel .panel-heading .panel-title a,
.color-grey-light .panel .panel-heading .panel-title a.accordion-toggle.collapsed {
  color: #585f69;
  background: #eeeeee;
}
.color-grey-light .panel .panel-heading .panel-title a.accordion-toggle.collapsed:hover,
.color-grey-light .panel .panel-heading .panel-title a:hover {
  color: #fff;
  background: #00565f;
}

/**********
 * Source file: apollo-webform.scss
 *
 * Styles used by the "Webform" element
 */
.ap-webform .ap-webform-captcha > img {
  max-width: 100% !important;
}
.ap-webform .btn.resetbutton, .ap-webform .ap.type-wallsio:not(.external-cookie-notice) .resetbutton.wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .ap-webform .resetbutton.wallsio-load-more-button {
  margin-left: 20px;
  background-color: #eeeeee;
  color: #585f69;
  border-color: #00565f;
}
.ap-webform .btn.resetbutton:focus, .ap-webform .ap.type-wallsio:not(.external-cookie-notice) .resetbutton.wallsio-load-more-button:focus, .ap.type-wallsio:not(.external-cookie-notice) .ap-webform .resetbutton.wallsio-load-more-button:focus, .ap-webform .btn.resetbutton:hover, .ap-webform .ap.type-wallsio:not(.external-cookie-notice) .resetbutton.wallsio-load-more-button:hover, .ap.type-wallsio:not(.external-cookie-notice) .ap-webform .resetbutton.wallsio-load-more-button:hover, .ap-webform .btn.resetbutton:active, .ap-webform .ap.type-wallsio:not(.external-cookie-notice) .resetbutton.wallsio-load-more-button:active, .ap.type-wallsio:not(.external-cookie-notice) .ap-webform .resetbutton.wallsio-load-more-button:active, .ap-webform .btn.resetbutton.active, .ap-webform .ap.type-wallsio:not(.external-cookie-notice) .resetbutton.active.wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .ap-webform .resetbutton.active.wallsio-load-more-button, .open .dropdown-toggle .ap-webform .btn.resetbutton, .open .dropdown-toggle .ap-webform .ap.type-wallsio:not(.external-cookie-notice) .resetbutton.wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .open .dropdown-toggle .ap-webform .resetbutton.wallsio-load-more-button {
  color: #fff;
  background-color: #00565f;
  border-color: #00565f;
  transition: all 0.33s;
}
.ap-webform .btn.resetbutton.disabled, .ap-webform .ap.type-wallsio:not(.external-cookie-notice) .resetbutton.disabled.wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .ap-webform .resetbutton.disabled.wallsio-load-more-button, .ap-webform .btn.resetbutton[disabled], .ap-webform .ap.type-wallsio:not(.external-cookie-notice) .resetbutton[disabled].wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .ap-webform .resetbutton[disabled].wallsio-load-more-button, fieldset[disabled] .ap-webform .btn.resetbutton, fieldset[disabled] .ap-webform .ap.type-wallsio:not(.external-cookie-notice) .resetbutton.wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) fieldset[disabled] .ap-webform .resetbutton.wallsio-load-more-button {
  background-color: #00565f;
  border-color: #00565f;
}

/**********
 * Source file: apollo-list.scss
 *
 * Styles used by the "List of contents" element.
 * Some of these styles are also used by the elements loadable from the list,
 * e.g. Blog, Event, Faq, Job etc.
 */
/* Main list content */
.ap-list-content .list-box.clearfix + .list-link {
  margin-top: 0;
}
.ap-list-content .list-box {
  position: relative;
  display: flex;
  flex-direction: column;
}
.ap-list-content .list-append-position {
  display: block;
}
.ap-list-content ul.list-entries {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.ap-list-content .list-spinner {
  display: none;
}
.ap-list-content .list-link {
  margin-top: 20px;
}

.ap-list-content.btn-block.ap-tiling-list .ap-list-tile .teaser-link,
.ap-list-content.btn-block .ap-teaser .btn,
.ap-list-content.btn-block .ap-teaser .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button,
.ap.type-wallsio:not(.external-cookie-notice) .ap-list-content.btn-block .ap-teaser .wallsio-load-more-button {
  display: block;
  width: 100%;
}

.ap-teaser .btn.teaser-btn, .ap-teaser .ap.type-wallsio:not(.external-cookie-notice) .teaser-btn.wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .ap-teaser .teaser-btn.wallsio-load-more-button {
  padding: 6px 12px;
}
.ap-teaser .teaser-date {
  font-size: 1.05rem;
  line-height: 1.575rem;
}
.ap-teaser .teaser-title {
  font-size: 2.4rem;
  line-height: 1.25;
}
@media (max-width: 991px) {
  .ap-teaser .teaser-title {
    font-size: 2.2rem;
  }
}
.ap-teaser .teaser-visual,
.ap-teaser .teaser-body .ap-image,
.ap-teaser .teaser-body > .teaser-title,
.ap-teaser .teaser-body .ap-list-group > .teaser-title,
.ap-teaser p.teaser-date {
  margin-bottom: 5px;
}
.ap-teaser p.teaser-headline {
  margin-bottom: 0;
}
.ap-teaser p.teaser-headline > a:not(.btn) {
  font-weight: bold;
  color: #585f69;
}
.ap-teaser p.teaser-headline > a:focus, .ap-teaser p.teaser-headline > a:hover {
  color: #00565f;
}
.ap-teaser p.teaser-headline > span {
  font-weight: bold;
  color: #585f69;
}
.ap-teaser p:last-of-type {
  margin-bottom: 5px;
}

.row > .ap-teaser::before, .row > .ap-teaser::after {
  content: " ";
  display: table;
}
.row > .ap-teaser:after {
  clear: both;
}

.ap-list-badge {
  margin-left: 10px;
  font-size: 0.7em;
  padding: 3px 6px;
  bottom: 2px;
}

/* Download lists */
.ap-download-list a:hover {
  text-decoration: none;
}
.ap-download-list a:hover .dl-content h3 {
  text-decoration: underline;
}
.ap-download-list a:hover .dl-content .dl-desc {
  color: #585f69;
}
.ap-download-list a:not(:hover) .dl-content .dl-desc,
.ap-download-list a:not(:hover) .dl-content .dl-file {
  color: #585f69;
}
.ap-download-list .dl-desc p {
  margin-bottom: 0;
}
.ap-download-list .dl-copy {
  font-size: 80%;
}
.ap-download-list.dl-list-elaborate li.list-entry {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.ap-download-list.dl-list-elaborate li.list-entry:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 20px;
}
.ap-download-list.dl-list-elaborate li.list-entry:first-child {
  padding-top: 0;
}
.ap-download-list.dl-list-elaborate .dl-content h3 {
  margin-top: 0;
  margin-bottom: 5px;
}
.ap-download-list.dl-list-elaborate .dl-content .dl-file {
  margin-bottom: 5px;
}
.ap-download-list.dl-list-elaborate .dl-content .dl-desc {
  margin-bottom: 8px;
}
.ap-download-list.dl-list-elaborate .dl-content .dl-btn {
  font-size: 1.2rem;
  padding: 2px 10px;
  min-width: 0;
}
.ap-download-list.dl-list-elaborate .dl-content .dl-btn .fa {
  padding-right: 10px;
}
.ap-download-list.dl-list-elaborate .dl-info {
  margin-top: 5px;
  text-align: center;
}
.ap-download-list.dl-list-elaborate .dl-info .fa {
  margin-bottom: 5px;
}
.ap-download-list.dl-list-elaborate .dl-stats .dl-type::after {
  content: " | ";
}
@media (max-width: 767px) {
  .ap-download-list.dl-list-elaborate .dl-stats .dl-type {
    display: none;
  }
  .ap-download-list.dl-list-elaborate .dl-stats .dl-type::after {
    display: none;
  }
}
.ap-download-list.dl-list-compact li:nth-child(odd) .dl-link {
  background-color: #eee;
}
.ap-download-list.dl-list-compact .list-pagination {
  margin-top: 20px;
}
.ap-download-list.dl-list-compact .dl-link {
  padding-top: 8px;
  padding-bottom: 8px;
}
.ap-download-list.dl-list-compact a.dl-link:hover h3 {
  color: #00565f;
}
.ap-download-list.dl-list-compact .dl-link {
  display: table;
  width: 100%;
}
.ap-download-list.dl-list-compact .dl-link > span {
  vertical-align: top;
  display: table-cell;
}
.ap-download-list.dl-list-compact .dl-link .fa {
  font-size: 1.5em;
}
.ap-download-list.dl-list-compact .dl-type {
  padding: 0 10px;
}
.ap-download-list.dl-list-compact .dl-dl {
  padding-right: 10px;
}
.ap-download-list.dl-list-compact .dl-content {
  width: 100%;
}
.ap-download-list.dl-list-compact .dl-content h3 {
  font-weight: bold;
  margin: 0;
}
.ap-download-list.dl-list-compact .dl-date,
.ap-download-list.dl-list-compact .dl-size {
  white-space: nowrap;
  float: right;
  min-width: 70px;
}
.ap-download-list.dl-list-compact .dl-info {
  min-width: 140px;
}
.ap-download-list.dl-list-compact .dl-show-file .dl-link .fa {
  padding-top: 4px;
  font-size: 2.2em;
}
.ap-download-list.dl-list-compact .dl-show-file .dl-info {
  min-width: 70px;
}
.ap-download-list.dl-list-compact .dl-show-file .dl-date,
.ap-download-list.dl-list-compact .dl-show-file .dl-size {
  display: block;
  float: left;
}
@media (max-width: 991px) {
  .ap-download-list.dl-list-compact .dl-date,
.ap-download-list.dl-list-compact .dl-size {
    min-width: 80px;
  }
}
@media (max-width: 767px) {
  .ap-download-list.dl-list-compact .dl-link > span.dl-dl {
    display: none;
  }
  .ap-download-list.dl-list-compact .dl-show-file .dl-link > span.dl-dl {
    display: block;
  }
}
@media (max-width: 496px) {
  .ap-download-list.dl-list-compact .dl-info {
    min-width: 70px;
  }
  .ap-download-list.dl-list-compact .dl-date,
.ap-download-list.dl-list-compact .dl-size {
    display: block;
    float: left;
  }
  .ap-download-list.dl-list-compact .dl-show-file .dl-link .fa {
    font-size: 1.5em;
  }
  .ap-download-list.dl-list-compact .dl-show-file .dl-link > span.dl-dl {
    display: none;
  }
}

/* Tiling lists with flex box layout */
.ap-list-content.ap-tiling-list .list-entries {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
.ap-list-content.ap-tiling-list .ap-tile {
  position: relative;
  display: flex;
  align-items: stretch;
}
.ap-list-content.ap-tiling-list .ap-tile:empty {
  display: none;
}
.ap-list-content.ap-tiling-list .teaser-visual > a:hover {
  text-decoration: initial;
}
.ap-list-content.ap-tiling-list .teaser-head-link {
  display: block;
}
.ap-list-content.ap-tiling-list .ap-teaser {
  overflow: hidden;
  position: relative;
  z-index: 5;
}
@media (min-width: 992px) {
  .ap-list-content.ap-tiling-list .ap-list-tile .teaser-body .teaser-title {
    line-height: 1.25;
    min-height: 6rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.ap-list-content.ap-tiling-list .ap-list-tile .teaser-body > *:last-child {
  display: block;
}
.ap-list-content.ap-tiling-list .ap-list-tile.ap-teaser-title-compact .teaser-body .teaser-title {
  line-height: initial;
  min-height: initial;
}
.ap-list-content.ap-tiling-list .ap-list-tile.has-button .teaser-body > *:last-child {
  padding-bottom: calc(2.1rem + 16px);
}
.ap-list-content.ap-tiling-list .ap-list-tile .teaser-link {
  left: 0;
  bottom: 0;
  position: absolute;
}
.ap-list-content.ap-tiling-list .list-box {
  margin-left: -10px;
  margin-right: -10px;
}
.ap-list-content.ap-tiling-list .list-editbox,
.ap-list-content.ap-tiling-list .list-pagination,
.ap-list-content.ap-tiling-list .ap-teaser {
  margin-left: 10px;
  margin-right: 10px;
}

/* Masonry lists */
.ap-list-content.ap-masonry-list .ap-tile {
  float: none;
}
.ap-list-content.ap-masonry-list .ap-tile .teaser-body a.btn, .ap-list-content.ap-masonry-list .ap-tile .teaser-body .ap.type-wallsio:not(.external-cookie-notice) a.wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .ap-list-content.ap-masonry-list .ap-tile .teaser-body a.wallsio-load-more-button {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .ap-list-content.ap-tiling-list.tile-margin-0 .list-box,
.ap-list-content.ap-masonry-list.tile-margin-0 .list-box {
    margin-left: -0px;
    margin-right: -0px;
  }
  .ap-list-content.ap-tiling-list.tile-margin-0 .list-editbox,
.ap-list-content.ap-tiling-list.tile-margin-0 .list-pagination,
.ap-list-content.ap-tiling-list.tile-margin-0 .ap-teaser,
.ap-list-content.ap-masonry-list.tile-margin-0 .list-editbox,
.ap-list-content.ap-masonry-list.tile-margin-0 .list-pagination,
.ap-list-content.ap-masonry-list.tile-margin-0 .ap-teaser {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.ap-list-content.ap-tiling-list.tile-margin-0 .ap.ap-teaser,
.ap-list-content.ap-masonry-list.tile-margin-0 .ap.ap-teaser {
  margin-bottom: 0px;
}
.ap-list-content.ap-tiling-list.tile-margin-0 .teaser-body,
.ap-list-content.ap-masonry-list.tile-margin-0 .teaser-body {
  margin-right: 15px;
}
@media (min-width: 768px) {
  .ap-list-content.ap-tiling-list.tile-margin-5 .list-box,
.ap-list-content.ap-masonry-list.tile-margin-5 .list-box {
    margin-left: -5px;
    margin-right: -5px;
  }
  .ap-list-content.ap-tiling-list.tile-margin-5 .list-editbox,
.ap-list-content.ap-tiling-list.tile-margin-5 .list-pagination,
.ap-list-content.ap-tiling-list.tile-margin-5 .ap-teaser,
.ap-list-content.ap-masonry-list.tile-margin-5 .list-editbox,
.ap-list-content.ap-masonry-list.tile-margin-5 .list-pagination,
.ap-list-content.ap-masonry-list.tile-margin-5 .ap-teaser {
    margin-left: 5px;
    margin-right: 5px;
  }
}
.ap-list-content.ap-tiling-list.tile-margin-5 .ap.ap-teaser,
.ap-list-content.ap-masonry-list.tile-margin-5 .ap.ap-teaser {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .ap-list-content.ap-tiling-list.tile-margin-10 .list-box,
.ap-list-content.ap-masonry-list.tile-margin-10 .list-box {
    margin-left: -10px;
    margin-right: -10px;
  }
  .ap-list-content.ap-tiling-list.tile-margin-10 .list-editbox,
.ap-list-content.ap-tiling-list.tile-margin-10 .list-pagination,
.ap-list-content.ap-tiling-list.tile-margin-10 .ap-teaser,
.ap-list-content.ap-masonry-list.tile-margin-10 .list-editbox,
.ap-list-content.ap-masonry-list.tile-margin-10 .list-pagination,
.ap-list-content.ap-masonry-list.tile-margin-10 .ap-teaser {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.ap-list-content.ap-tiling-list.tile-margin-10 .ap.ap-teaser,
.ap-list-content.ap-masonry-list.tile-margin-10 .ap.ap-teaser {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .ap-list-content.ap-tiling-list.tile-margin-15 .list-box,
.ap-list-content.ap-masonry-list.tile-margin-15 .list-box {
    margin-left: -15px;
    margin-right: -15px;
  }
  .ap-list-content.ap-tiling-list.tile-margin-15 .list-editbox,
.ap-list-content.ap-tiling-list.tile-margin-15 .list-pagination,
.ap-list-content.ap-tiling-list.tile-margin-15 .ap-teaser,
.ap-list-content.ap-masonry-list.tile-margin-15 .list-editbox,
.ap-list-content.ap-masonry-list.tile-margin-15 .list-pagination,
.ap-list-content.ap-masonry-list.tile-margin-15 .ap-teaser {
    margin-left: 15px;
    margin-right: 15px;
  }
}
.ap-list-content.ap-tiling-list.tile-margin-15 .ap.ap-teaser,
.ap-list-content.ap-masonry-list.tile-margin-15 .ap.ap-teaser {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .ap-list-content.ap-tiling-list.tile-margin-20 .list-box,
.ap-list-content.ap-masonry-list.tile-margin-20 .list-box {
    margin-left: -20px;
    margin-right: -20px;
  }
  .ap-list-content.ap-tiling-list.tile-margin-20 .list-editbox,
.ap-list-content.ap-tiling-list.tile-margin-20 .list-pagination,
.ap-list-content.ap-tiling-list.tile-margin-20 .ap-teaser,
.ap-list-content.ap-masonry-list.tile-margin-20 .list-editbox,
.ap-list-content.ap-masonry-list.tile-margin-20 .list-pagination,
.ap-list-content.ap-masonry-list.tile-margin-20 .ap-teaser {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.ap-list-content.ap-tiling-list.tile-margin-20 .ap.ap-teaser,
.ap-list-content.ap-masonry-list.tile-margin-20 .ap.ap-teaser {
  margin-bottom: 40px;
}

/* Compact list items */
.ap-compact {
  color: #585f69;
}
.ap-compact .teaser-date {
  font-style: italic;
}
.ap-compact .teaser-title {
  margin-top: 5px;
  margin-bottom: 5px;
}
.ap-compact .btn.teaser-btn, .ap-compact .ap.type-wallsio:not(.external-cookie-notice) .teaser-btn.wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .ap-compact .teaser-btn.wallsio-load-more-button {
  font-size: 12px;
  padding: 2px 10px;
  min-width: 0;
}
.ap-compact.disabled {
  background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px);
}

/* List teaser bottom margins */
.ap-teaser [class~=ap][class~=ap-list-group]:last-of-type {
  margin-bottom: 0;
}

.ap-list-content .ap.ap-teaser {
  margin-bottom: 30px;
}
.ap-list-content .ap.ap-compact.ap-teaser {
  margin-bottom: 15px;
}
.ap-list-content.ap-tiling-list .ap.ap-teaser {
  margin-bottom: 20px;
}

/* List pagination */
.pagination {
  display: initial;
  margin: 0;
}
.pagination > li > a {
  background-color: #fff;
  border-color: #eeeeee;
  margin-left: 0;
  margin-right: -1px;
  color: #585f69;
  text-align: center;
  padding: 5px 12px;
  font-size: 1.4rem;
  line-height: 2.1rem;
  min-width: calc(2.1rem + 10px);
}
.pagination > li > a .number {
  font-weight: normal;
}
.pagination > li.disabled > a,
.pagination > li.disabled > a:hover {
  background-color: #fff;
  border-color: #eeeeee;
  color: #eeeeee;
}
.pagination > li:first-child > a {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination > li:last-child > a {
  margin-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.pagination > li > a:hover,
.pagination > li.active > a:hover {
  color: #fff;
  background: #00565f;
  border-color: #00565f;
}
.pagination > li.active > a {
  background-color: #009bab;
  border-color: #009bab;
}

.ap-list-content.list-aside .pagination > li > a,
aside .ap-list-content:not(.list-important) .pagination > li > a,
.ap-list-content.pagination-small .pagination > li > a {
  padding: 2px 6px;
  min-width: 28px;
}
.ap-list-content.list-aside .pagination > li:not(.disabled) > a:not(:hover),
aside .ap-list-content:not(.list-important) .pagination > li:not(.disabled) > a:not(:hover),
.ap-list-content.pagination-small .pagination > li:not(.disabled) > a:not(:hover) {
  background-color: #fff;
  border-color: #eeeeee;
  color: #585f69;
}
.ap-list-content.list-aside .pagination > li.active:not(.disabled) > a:not(:hover),
aside .ap-list-content:not(.list-important) .pagination > li.active:not(.disabled) > a:not(:hover),
.ap-list-content.pagination-small .pagination > li.active:not(.disabled) > a:not(:hover) {
  background-color: #eeeeee;
  border-color: #eeeeee;
}

.ap-list-content.list-aside .ap-teaser .btn.teaser-btn, .ap-list-content.list-aside .ap-teaser .ap.type-wallsio:not(.external-cookie-notice) .teaser-btn.wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .ap-list-content.list-aside .ap-teaser .teaser-btn.wallsio-load-more-button,
aside .ap-list-content:not(.list-important) .ap-teaser .btn.teaser-btn,
aside .ap-list-content:not(.list-important) .ap-teaser .ap.type-wallsio:not(.external-cookie-notice) .teaser-btn.wallsio-load-more-button,
.ap.type-wallsio:not(.external-cookie-notice) aside .ap-list-content:not(.list-important) .ap-teaser .teaser-btn.wallsio-load-more-button {
  padding: 2px 10px;
}
.ap-list-content.list-aside .ap-teaser .btn.teaser-btn:not(:hover), .ap-list-content.list-aside .ap-teaser .ap.type-wallsio:not(.external-cookie-notice) .teaser-btn.wallsio-load-more-button:not(:hover), .ap.type-wallsio:not(.external-cookie-notice) .ap-list-content.list-aside .ap-teaser .teaser-btn.wallsio-load-more-button:not(:hover),
aside .ap-list-content:not(.list-important) .ap-teaser .btn.teaser-btn:not(:hover),
aside .ap-list-content:not(.list-important) .ap-teaser .ap.type-wallsio:not(.external-cookie-notice) .teaser-btn.wallsio-load-more-button:not(:hover),
.ap.type-wallsio:not(.external-cookie-notice) aside .ap-list-content:not(.list-important) .ap-teaser .teaser-btn.wallsio-load-more-button:not(:hover) {
  background-color: #eeeeee;
  color: #585f69;
}
.ap-list-content.list-aside .ap-compact.ap-teaser .btn.teaser-btn, .ap-list-content.list-aside .ap-compact.ap-teaser .ap.type-wallsio:not(.external-cookie-notice) .teaser-btn.wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .ap-list-content.list-aside .ap-compact.ap-teaser .teaser-btn.wallsio-load-more-button,
aside .ap-list-content:not(.list-important) .ap-compact.ap-teaser .btn.teaser-btn,
aside .ap-list-content:not(.list-important) .ap-compact.ap-teaser .ap.type-wallsio:not(.external-cookie-notice) .teaser-btn.wallsio-load-more-button,
.ap.type-wallsio:not(.external-cookie-notice) aside .ap-list-content:not(.list-important) .ap-compact.ap-teaser .teaser-btn.wallsio-load-more-button {
  padding: 0 8px;
}

/* Centered or right aligned pagination below list */
.pagination-center .list-dynamic.list-paginate .list-pagination {
  display: flex;
  justify-content: center;
}
@media (min-width: 992px) {
  .pagination-center .list-dynamic:not(.list-paginate) .list-pagination {
    display: flex;
    justify-content: center;
  }
  .pagination-center .list-dynamic:not(.list-paginate) .list-pagination .list-append-position {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .pagination-center .list-dynamic:not(.list-paginate) .list-pagination .list-append-position {
    width: 40%;
  }
}

.pagination-right .list-dynamic.list-paginate .list-pagination {
  display: flex;
  justify-content: flex-end;
}

/* Colored teaser elements, shared with event, blog and other detail formatters */
.color-grey-light .ap-teaser a:not(.btn):not(.uncolored) {
  color: #eeeeee;
}
.color-grey-light .ap-teaser a:not(.btn):not(.uncolored):hover {
  color: #00565f;
}
.color-grey-light .pagination > li.active > a:not(:hover) {
  background-color: #eeeeee;
  border-color: #eeeeee;
}
.color-grey-light .pagination > li:not(.disabled) > a:hover {
  background: #00565f;
  border-color: #00565f;
}

/* List archive filter menu */
.ap-list-archive .filterbox {
  margin-bottom: 20px;
  background-color: #fff;
}
.ap-list-archive .filterbox .dialog {
  display: none;
}
.ap-list-archive .filterbox.search {
  padding: 0;
}
.ap-list-archive .filterbox.search .input {
  margin: 0;
}
.ap-list-archive .filterbox.search input,
.ap-list-archive .filterbox.search .icon-prepend {
  border-color: #eeeeee;
}
.ap-list-archive .filterbox.search input {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}
.ap-list-archive .filterbox .btn.li-label, .ap-list-archive .filterbox .ap.type-wallsio:not(.external-cookie-notice) .li-label.wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .ap-list-archive .filterbox .li-label.wallsio-load-more-button {
  display: block;
  position: relative;
  text-align: left;
}
.ap-list-archive .filterbox .btn.li-label::before, .ap-list-archive .filterbox .ap.type-wallsio:not(.external-cookie-notice) .li-label.wallsio-load-more-button::before, .ap.type-wallsio:not(.external-cookie-notice) .ap-list-archive .filterbox .li-label.wallsio-load-more-button::before {
  font-family: FontAwesome;
  font-weight: normal;
  padding-right: 10px;
}
.ap-list-archive .filterbox .btn.li-label::after, .ap-list-archive .filterbox .ap.type-wallsio:not(.external-cookie-notice) .li-label.wallsio-load-more-button::after, .ap.type-wallsio:not(.external-cookie-notice) .ap-list-archive .filterbox .li-label.wallsio-load-more-button::after {
  position: absolute;
  display: inline-block;
  font-family: FontAwesome;
  font-weight: normal;
  content: "";
  right: 15px;
  transform: rotate(-180deg) translate(0, 0);
  transition: transform 0.33s;
}
.ap-list-archive .filterbox .btn.li-label.collapsed::after, .ap-list-archive .filterbox .ap.type-wallsio:not(.external-cookie-notice) .li-label.collapsed.wallsio-load-more-button::after, .ap.type-wallsio:not(.external-cookie-notice) .ap-list-archive .filterbox .li-label.collapsed.wallsio-load-more-button::after {
  transform: rotate(0) translate(0, 0);
  transition: transform 0.33s;
}
.ap-list-archive .filterbox.categories > div {
  border: 1px solid #eeeeee;
  border-top: none;
  border-radius: 0;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  padding: 15px;
}
.ap-list-archive .filterbox.categories .btn.li-label:before, .ap-list-archive .filterbox.categories .ap.type-wallsio:not(.external-cookie-notice) .li-label.wallsio-load-more-button:before, .ap.type-wallsio:not(.external-cookie-notice) .ap-list-archive .filterbox.categories .li-label.wallsio-load-more-button:before {
  content: "";
}
.ap-list-archive .filterbox.categories ul {
  margin: 0;
  padding: 0;
  list-style: outside none none;
}
.ap-list-archive .filterbox.categories ul li {
  margin-top: 2px;
}
.ap-list-archive .filterbox.categories ul li a {
  cursor: pointer;
}
.ap-list-archive .filterbox.categories ul li.active a {
  font-weight: bold;
}
.ap-list-archive .filterbox.categories ul li .li-entry {
  white-space: nowrap;
  display: inline-block;
  line-height: 1;
  font-size: 12px;
  padding: 4px 7px;
  border-radius: 4px;
  color: #585f69;
  background-color: #eee;
}
.ap-list-archive .filterbox.categories ul li .li-entry:hover {
  background-color: #eeeeee;
  transition: background-color 0.33s;
}
.ap-list-archive .filterbox.categories ul li .li-entry:before {
  font-family: FontAwesome;
  content: "";
  padding-right: 4px;
}
.ap-list-archive .filterbox.categories ul li .li-count:before {
  padding-left: 4px;
  content: "(";
}
.ap-list-archive .filterbox.categories ul li .li-count:after {
  content: ")";
}
.ap-list-archive .filterbox.folders {
  padding: 0;
}
.ap-list-archive .filterbox.folders .btn.li-label:before, .ap-list-archive .filterbox.folders .ap.type-wallsio:not(.external-cookie-notice) .li-label.wallsio-load-more-button:before, .ap.type-wallsio:not(.external-cookie-notice) .ap-list-archive .filterbox.folders .li-label.wallsio-load-more-button:before {
  content: "";
}
.ap-list-archive .filterbox.folders .sidebar-nav {
  border-top: none;
  margin-left: 0;
  margin-right: 0;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
}
.ap-list-archive .filterbox.folders .sidebar-nav li a.nav-handle {
  width: 42px;
}
.ap-list-archive .filterbox.folders .sidebar-nav li a.nav-handle::after {
  right: 16px;
}
.ap-list-archive .filterbox.archive > div {
  border: 1px solid #eeeeee;
  border-top: none;
  border-radius: 0;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  padding: 0;
}
.ap-list-archive .filterbox.archive button.year {
  color: #585f69;
  background-color: #eee;
  padding-left: 15px;
}
.ap-list-archive .filterbox.archive button.year:hover {
  background-color: #eeeeee;
}
.ap-list-archive .filterbox.archive button.year::before {
  content: "";
}
.ap-list-archive .filterbox.archive button.year::after {
  right: 14px;
}
.ap-list-archive .filterbox.archive ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.ap-list-archive .filterbox.archive ul li {
  display: inline-block;
  padding: 7px 0px;
  width: 16.666666%;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}
.ap-list-archive .filterbox.archive ul li a {
  color: #585f69;
}
.ap-list-archive .filterbox.archive ul li a:hover {
  text-decoration: none;
}
.ap-list-archive .filterbox.archive ul li.active a {
  font-weight: bold;
}
.ap-list-archive .filterbox.archive ul li .li-entry {
  position: relative;
}
.ap-list-archive .filterbox.archive ul li .li-count {
  display: none;
  position: absolute;
  left: calc(100% - 2px);
  top: -7px;
  z-index: 10;
  font-size: 10px;
}
.ap-list-archive .filterbox.archive ul li .li-count:before {
  content: "(";
}
.ap-list-archive .filterbox.archive ul li .li-count:after {
  content: ")";
}
.ap-list-archive .filterbox.archive ul li:hover {
  background-color: #eee;
  transition: background-color 0.33s;
}
.ap-list-archive .filterbox.archive ul li:hover .li-count {
  display: block;
}

/* Override some bootstrap defaults for the dropdown menu */
.dropdown-menu {
  padding: 0;
  border-radius: 0;
}
.dropdown-menu > li > a {
  padding: 5px 16px;
  color: #585f69;
}
.dropdown-menu > li > a:hover {
  background-color: #eeeeee;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #fff;
  background-color: #009bab;
}
.dropdown-menu .divider {
  margin: 5px 0;
}

/* List facet / sort options */
.ap-list-options .list-option {
  min-height: 36px;
  margin-bottom: 20px;
}
.ap-list-options .list-option.btn-group {
  margin-left: 10px;
}
.ap-list-options .list-option .fa {
  text-align: right;
}

/* List button styling */
.ap-disqus button,
.ap-list-archive button,
.ap-list-options button {
  border: none;
  padding-left: 16px;
  padding-right: 16px;
}
.ap-disqus button .fa,
.ap-list-archive button .fa,
.ap-list-options button .fa {
  padding-top: 3px;
}
.ap-disqus i.fa,
.ap-disqus span.fa,
.ap-list-archive i.fa,
.ap-list-archive span.fa,
.ap-list-options i.fa,
.ap-list-options span.fa {
  transform: rotate(0) translate(0px, 0px);
  transition: transform 0.33s;
}
.ap-disqus .open span.fa,
.ap-disqus i.fa.open,
.ap-disqus span.fa.open,
.ap-list-archive .open span.fa,
.ap-list-archive i.fa.open,
.ap-list-archive span.fa.open,
.ap-list-options .open span.fa,
.ap-list-options i.fa.open,
.ap-list-options span.fa.open {
  transform: rotate(-180deg) translate(0px, -4px);
  transition: transform 0.33s;
}

/* Booking status information */
.book-info .book-msg {
  background-color: #e8e8e8;
  font-weight: normal;
}
.book-info .ap-meta-info {
  margin-left: 10px;
}

.ap-teaser .book-info {
  font-size: 1rem;
  line-height: 2rem;
  margin: 8px 0;
}
.ap-teaser .book-info .ap-meta-info,
.ap-teaser .book-info .book-msg {
  display: inline-block;
  padding: 1px 8px;
}

.ap-tile .book-info .book-msg::after {
  content: "\a";
  white-space: pre;
}
.ap-tile .book-info .ap-meta-info {
  margin-top: 5px;
  margin-left: 0;
}

.ap-detail-page .book-info .ap-meta-info,
.ap-detail-page .book-info .book-msg {
  padding: 8px 16px;
}

/**********
 * Source file: apollo-generics.scss
 *
 * Generic CSS selectors that can apply to most block elements.
 * These usually are to be used as "CSS Wrapper" in the Apollo element settings.
 */
/* Link text colors */
.links-white a:not(.btn):not(.uncolored) {
  color: #fff;
}
.links-white a:not(.btn):not(.uncolored):focus, .links-white a:not(.btn):not(.uncolored):hover {
  color: #eeeeee;
}

/* Colored backgrounds (new style) */
.box-online-warning,
.box {
  background-color: #eee;
  color: #585f69;
  overflow: hidden;
  padding: 20px;
}
.box-online-warning *:not(a):not(span),
.box *:not(a):not(span) {
  color: #585f69;
}

.box-grey-light {
  background-color: #eeeeee;
  color: #585f69;
}
.box-grey-light *:not(a):not(span) {
  color: #585f69;
}
.box-grey-light.transparent {
  background-color: rgba(238, 238, 238, 0.7);
}

.bc-grey-light {
  background-color: #eeeeee;
}
.bc-grey-light.transparent {
  background-color: rgba(238, 238, 238, 0.7);
}

/* Colored text */
.text-grey-light {
  color: #eeeeee;
}
.text-grey-light *:not(a):not(span) {
  color: #eeeeee;
}

.text-white {
  color: #fff;
}
.text-white *:not(a):not(span) {
  color: #fff;
}

/* Font sizes */
/* Line heights */
/* Text alignment */
.fa-left {
  text-align: left;
}

.fa-center {
  text-align: center;
}

.fa-right {
  text-align: right;
}

.va-middle {
  vertical-align: middle;
}

/* Border base */
/* Borders with round corners */
/* Colored borders */
.bo-grey-light {
  border-color: #eeeeee;
}

/* Borders styles */
.bo-none {
  border: none;
}

/* Borders positions */
/* Image sizes */
/* Block display settings */
.disp-i {
  display: inline;
}

.disp-ib {
  display: inline-block;
}

.disp-b {
  display: block;
}

.disp-n {
  display: none;
}

/* Block position settings */
.pos-abs {
  position: absolute;
}

.pos-rel {
  position: relative;
}

.pos-initial,
.pos-static,
.pos-stat {
  position: static;
}

/* Responsive floats, based on bootstrap 4. */
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 768px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
/* Hide overflow */
.overflow-hidden {
  overflow: hidden;
  z-index: 5;
}

/* Drop shadow effect */
.ap-shadow {
  box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.3);
}

/* Margins and paddings */
.p-0 {
  padding: 0px;
}

.ph-0,
.pl-0 {
  padding-left: 0px;
}

.ph-0,
.pr-0 {
  padding-right: 0px;
}

.pv-0,
.pt-0 {
  padding-top: 0px;
}

.pv-0,
.pb-0 {
  padding-bottom: 0px;
}

.p-0-i {
  padding: 0px !important;
}

.ph-0-i,
.pl-0-i {
  padding-left: 0px !important;
}

.ph-0-i,
.pr-0-i {
  padding-right: 0px !important;
}

.pv-0-i,
.pt-0-i {
  padding-top: 0px !important;
}

.pv-0-i,
.pb-0-i {
  padding-bottom: 0px !important;
}

.m-0 {
  margin: 0px;
}

.mh-0,
.ml-0 {
  margin-left: 0px;
}

.mh-0,
.mr-0 {
  margin-right: 0px;
}

.mv-0,
.mt-0 {
  margin-top: 0px;
}

.mv-0,
.mb-0 {
  margin-bottom: 0px;
}

.m-0-i {
  margin: 0px !important;
}

.mh-0-i,
.ml-0-i {
  margin-left: 0px !important;
}

.mh-0-i,
.mr-0-i {
  margin-right: 0px !important;
}

.mv-0-i,
.mt-0-i {
  margin-top: 0px !important;
}

.mv-0-i,
.mb-0-i {
  margin-bottom: 0px !important;
}

.m-neg-0 {
  margin: -0px !important;
}

.mh-neg-0-i,
.ml-neg-0-i {
  margin-left: -0px !important;
}

.mh-neg-0-i,
.mr-neg-0-i {
  margin-right: -0px !important;
}

.mv-neg-0,
.mt-neg-0 {
  margin-top: -0px !important;
}

.mv-neg-0,
.mb-neg-0 {
  margin-bottom: -0px !important;
}

.p-5 {
  padding: 5px;
}

.ph-5,
.pl-5 {
  padding-left: 5px;
}

.ph-5,
.pr-5 {
  padding-right: 5px;
}

.pv-5,
.pt-5 {
  padding-top: 5px;
}

.pv-5,
.pb-5 {
  padding-bottom: 5px;
}

.p-5-i {
  padding: 5px !important;
}

.ph-5-i,
.pl-5-i {
  padding-left: 5px !important;
}

.ph-5-i,
.pr-5-i {
  padding-right: 5px !important;
}

.pv-5-i,
.pt-5-i {
  padding-top: 5px !important;
}

.pv-5-i,
.pb-5-i {
  padding-bottom: 5px !important;
}

.m-5 {
  margin: 5px;
}

.mh-5,
.ml-5 {
  margin-left: 5px;
}

.mh-5,
.mr-5 {
  margin-right: 5px;
}

.mv-5,
.mt-5 {
  margin-top: 5px;
}

.mv-5,
.mb-5 {
  margin-bottom: 5px;
}

.m-5-i {
  margin: 5px !important;
}

.mh-5-i,
.ml-5-i {
  margin-left: 5px !important;
}

.mh-5-i,
.mr-5-i {
  margin-right: 5px !important;
}

.mv-5-i,
.mt-5-i {
  margin-top: 5px !important;
}

.mv-5-i,
.mb-5-i {
  margin-bottom: 5px !important;
}

.m-neg-5 {
  margin: -5px !important;
}

.mh-neg-5-i,
.ml-neg-5-i {
  margin-left: -5px !important;
}

.mh-neg-5-i,
.mr-neg-5-i {
  margin-right: -5px !important;
}

.mv-neg-5,
.mt-neg-5 {
  margin-top: -5px !important;
}

.mv-neg-5,
.mb-neg-5 {
  margin-bottom: -5px !important;
}

.p-10 {
  padding: 10px;
}

.ph-10,
.pl-10 {
  padding-left: 10px;
}

.ph-10,
.pr-10 {
  padding-right: 10px;
}

.pv-10,
.pt-10 {
  padding-top: 10px;
}

.pv-10,
.pb-10 {
  padding-bottom: 10px;
}

.p-10-i {
  padding: 10px !important;
}

.ph-10-i,
.pl-10-i {
  padding-left: 10px !important;
}

.ph-10-i,
.pr-10-i {
  padding-right: 10px !important;
}

.pv-10-i,
.pt-10-i {
  padding-top: 10px !important;
}

.pv-10-i,
.pb-10-i {
  padding-bottom: 10px !important;
}

.m-10 {
  margin: 10px;
}

.mh-10,
.ml-10 {
  margin-left: 10px;
}

.mh-10,
.mr-10 {
  margin-right: 10px;
}

.mv-10,
.mt-10 {
  margin-top: 10px;
}

.mv-10,
.mb-10 {
  margin-bottom: 10px;
}

.m-10-i {
  margin: 10px !important;
}

.mh-10-i,
.ml-10-i {
  margin-left: 10px !important;
}

.mh-10-i,
.mr-10-i {
  margin-right: 10px !important;
}

.mv-10-i,
.mt-10-i {
  margin-top: 10px !important;
}

.mv-10-i,
.mb-10-i {
  margin-bottom: 10px !important;
}

.m-neg-10 {
  margin: -10px !important;
}

.mh-neg-10-i,
.ml-neg-10-i {
  margin-left: -10px !important;
}

.mh-neg-10-i,
.mr-neg-10-i {
  margin-right: -10px !important;
}

.mv-neg-10,
.mt-neg-10 {
  margin-top: -10px !important;
}

.mv-neg-10,
.mb-neg-10 {
  margin-bottom: -10px !important;
}

.p-20 {
  padding: 20px;
}

.ph-20,
.pl-20 {
  padding-left: 20px;
}

.ph-20,
.pr-20 {
  padding-right: 20px;
}

.pv-20,
.pt-20 {
  padding-top: 20px;
}

.pv-20,
.pb-20 {
  padding-bottom: 20px;
}

.p-20-i {
  padding: 20px !important;
}

.ph-20-i,
.pl-20-i {
  padding-left: 20px !important;
}

.ph-20-i,
.pr-20-i {
  padding-right: 20px !important;
}

.pv-20-i,
.pt-20-i {
  padding-top: 20px !important;
}

.pv-20-i,
.pb-20-i {
  padding-bottom: 20px !important;
}

.m-20 {
  margin: 20px;
}

.mh-20,
.ml-20 {
  margin-left: 20px;
}

.mh-20,
.mr-20 {
  margin-right: 20px;
}

.mv-20,
.mt-20 {
  margin-top: 20px;
}

.mv-20,
.mb-20 {
  margin-bottom: 20px;
}

.m-20-i {
  margin: 20px !important;
}

.mh-20-i,
.ml-20-i {
  margin-left: 20px !important;
}

.mh-20-i,
.mr-20-i {
  margin-right: 20px !important;
}

.mv-20-i,
.mt-20-i {
  margin-top: 20px !important;
}

.mv-20-i,
.mb-20-i {
  margin-bottom: 20px !important;
}

.m-neg-20 {
  margin: -20px !important;
}

.mh-neg-20-i,
.ml-neg-20-i {
  margin-left: -20px !important;
}

.mh-neg-20-i,
.mr-neg-20-i {
  margin-right: -20px !important;
}

.mv-neg-20,
.mt-neg-20 {
  margin-top: -20px !important;
}

.mv-neg-20,
.mb-neg-20 {
  margin-bottom: -20px !important;
}

.p-30 {
  padding: 30px;
}

.ph-30,
.pl-30 {
  padding-left: 30px;
}

.ph-30,
.pr-30 {
  padding-right: 30px;
}

.pv-30,
.pt-30 {
  padding-top: 30px;
}

.pv-30,
.pb-30 {
  padding-bottom: 30px;
}

.p-30-i {
  padding: 30px !important;
}

.ph-30-i,
.pl-30-i {
  padding-left: 30px !important;
}

.ph-30-i,
.pr-30-i {
  padding-right: 30px !important;
}

.pv-30-i,
.pt-30-i {
  padding-top: 30px !important;
}

.pv-30-i,
.pb-30-i {
  padding-bottom: 30px !important;
}

.m-30 {
  margin: 30px;
}

.mh-30,
.ml-30 {
  margin-left: 30px;
}

.mh-30,
.mr-30 {
  margin-right: 30px;
}

.mv-30,
.mt-30 {
  margin-top: 30px;
}

.mv-30,
.mb-30 {
  margin-bottom: 30px;
}

.m-30-i {
  margin: 30px !important;
}

.mh-30-i,
.ml-30-i {
  margin-left: 30px !important;
}

.mh-30-i,
.mr-30-i {
  margin-right: 30px !important;
}

.mv-30-i,
.mt-30-i {
  margin-top: 30px !important;
}

.mv-30-i,
.mb-30-i {
  margin-bottom: 30px !important;
}

.m-neg-30 {
  margin: -30px !important;
}

.mh-neg-30-i,
.ml-neg-30-i {
  margin-left: -30px !important;
}

.mh-neg-30-i,
.mr-neg-30-i {
  margin-right: -30px !important;
}

.mv-neg-30,
.mt-neg-30 {
  margin-top: -30px !important;
}

.mv-neg-30,
.mb-neg-30 {
  margin-bottom: -30px !important;
}

.p-40 {
  padding: 40px;
}

.ph-40,
.pl-40 {
  padding-left: 40px;
}

.ph-40,
.pr-40 {
  padding-right: 40px;
}

.pv-40,
.pt-40 {
  padding-top: 40px;
}

.pv-40,
.pb-40 {
  padding-bottom: 40px;
}

.p-40-i {
  padding: 40px !important;
}

.ph-40-i,
.pl-40-i {
  padding-left: 40px !important;
}

.ph-40-i,
.pr-40-i {
  padding-right: 40px !important;
}

.pv-40-i,
.pt-40-i {
  padding-top: 40px !important;
}

.pv-40-i,
.pb-40-i {
  padding-bottom: 40px !important;
}

.m-40 {
  margin: 40px;
}

.mh-40,
.ml-40 {
  margin-left: 40px;
}

.mh-40,
.mr-40 {
  margin-right: 40px;
}

.mv-40,
.mt-40 {
  margin-top: 40px;
}

.mv-40,
.mb-40 {
  margin-bottom: 40px;
}

.m-40-i {
  margin: 40px !important;
}

.mh-40-i,
.ml-40-i {
  margin-left: 40px !important;
}

.mh-40-i,
.mr-40-i {
  margin-right: 40px !important;
}

.mv-40-i,
.mt-40-i {
  margin-top: 40px !important;
}

.mv-40-i,
.mb-40-i {
  margin-bottom: 40px !important;
}

.m-neg-40 {
  margin: -40px !important;
}

.mh-neg-40-i,
.ml-neg-40-i {
  margin-left: -40px !important;
}

.mh-neg-40-i,
.mr-neg-40-i {
  margin-right: -40px !important;
}

.mv-neg-40,
.mt-neg-40 {
  margin-top: -40px !important;
}

.mv-neg-40,
.mb-neg-40 {
  margin-bottom: -40px !important;
}

/* OpenCms reset rules */
html .opencms:focus,
html .opencms *:focus {
  outline: none;
  box-shadow: none;
}
html.ap-keyboard-nav .opencms:focus, html.ap-keyboard-nav .opencms *:focus {
  outline-color: #00565f;
  outline-width: 3px;
  outline-style: solid;
  outline-offset: -2px;
  box-shadow: none;
}
html.hasscript .hide-hasscript {
  display: none;
}

/**********
 * Source file: apollo-jsondata.scss
 *
 * Data about the CSS encoded in JSON so that it can be easily consumed by JavaScript.
 */
/* Page info element */
#apollo-info {
  visibility: hidden;
}
#apollo-info::before {
  display: none;
  content: '{"main-theme":"#009bab", "main-theme-complementary":"#00565f", "main-theme-hover":"#00565f", "map-color":["#009bab","#00565f","#00565f","#009bab","#00565f","#00565f","#aaa"], "map-style":[{"featureType":"all","elementType":"all","stylers":[{"saturation":"-100"}]},{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#009bab"}]},{"featureType":"landscape.natural","elementType":"all","stylers":[{"lightness":"75"},{"gamma":"1.00"}]},{"featureType":"poi","elementType":"geometry.fill","stylers":[{"color":"#d8ded8"},{"saturation":"-30"},{"lightness":"0"},{"gamma":"1"}]},{"featureType":"poi.business","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi.park","elementType":"geometry.fill","stylers":[{"color":"#d8ded8"}]},{"featureType":"road","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#fafafa"}]},{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#a5b1be"}]}]}';
}

.apollo-grid-info::before {
  content: "xs";
}
@media (min-width: 768px) {
  .apollo-grid-info::before {
    content: "sm";
  }
}
@media (min-width: 992px) {
  .apollo-grid-info::before {
    content: "md";
  }
}
@media (min-width: 1200px) {
  .apollo-grid-info::before {
    content: "lg";
  }
}

#apollo-grid-info::before {
  display: none;
}
#apollo-grid-info::after {
  display: none;
  content: '{"xsMax":767px, "smMin":768px, "smMax":991px, "mdMin":992px, "mdMax":1199px, "lgMin":1200px, "navMobMax":991px, "navDeskMin":992px, "navMobPos":"right","navFixHeader":"upscroll"}';
}

/* Page info elements providing JavaScript access to selected styles */
.apollo-info.many-colors::before {
  display: none;
  content: '[{"name": "grey-light","value": "#eeeeee"}, {}]';
}
.apollo-info.text-colors::before {
  display: none;
  content: '[{"name": "white","value": "#fff"}, {}]';
}
.apollo-info.link-colors::before {
  display: none;
  content: '[{"name": "white","value": "#fff"}, {}]';
}
.apollo-info.reduced-colors::before {
  display: none;
  content: '[{"name": "grey-light","value": "#eeeeee"}, {}]';
}

/*
 * Alkacon Apollo CSS - Bistuemer Imports
 *
 * -----------------------------------------------------------------------------
 *
 * Alkacon Software - The OpenCms experts.
 *
 * http://www.alkacon.com
 * http://www.opencms.org
 */
.ap-calendarview {
  /* Optional rules for the different calendar entry types */
}
.ap-calendarview #calendarviewload {
  text-align: center;
}
.ap-calendarview .fc-h-event,
.ap-calendarview .fc-event {
  background-color: #eeeeee;
  border: #eeeeee;
  color: #585f69;
  padding: 5px;
}
.ap-calendarview .cal-holiday {
  background-color: #009bab;
  border: #009bab;
  color: #fff;
  padding: 5px;
}
.ap-calendarview .cal-maybeholiday {
  background-color: rgba(0, 155, 171, 0.5);
  border: rgba(0, 155, 171, 0.5);
  color: #fff;
  padding: 5px;
}
.ap-calendarview .fc-today {
  background-color: #009bab;
  color: #fff;
}
/** Day Readings */
.ap-dayreadings .readingdate {
  margin-bottom: 8px;
}
.ap-dayreadings .pericopes {
  margin-top: 12px;
  border-top: 1px solid #eee;
  display: inline-block;
  width: 100%;
}
.ap-dayreadings .pericopes .title {
  margin-top: 12px;
}
.ap-dayreadings .pericopes .title .type {
  font-weight: bold;
}
.ap-dayreadings .pericopes .motto {
  font-style: italic;
  margin-top: 8px;
}
.ap-dayreadings .verse {
  padding-top: 8px;
  clear: both;
}
.ap-dayreadings .verse .number {
  float: left;
  display: inline-block;
  width: 10%;
  font-weight: bold;
}
.ap-dayreadings .verse .text {
  float: right;
  display: inline-block;
  width: 90%;
}
.ap-dayreadings .verselast div {
  padding-bottom: 0;
}

/** Liturgy calendar */
.ap-liturgy .calendar-simple {
  font-size: 2rem;
  line-height: 1.25;
  margin-bottom: 0.5em;
}
@media (min-width: 768px) {
  .ap-liturgy .calendar-simple {
    font-size: 2.4rem;
    line-height: 1.25;
  }
}

.direktorium .dday {
  position: relative;
}
.direktorium .dday:last-of-type, .direktorium .dday.lestrue {
  margin-bottom: 10px;
}
.direktorium .grad1 .dheader,
.direktorium .grad2 .dheader,
.direktorium .grad3 .dheader,
.direktorium .grad4 .dheader,
.direktorium .grad5 .dheader {
  font-weight: bold;
}
.direktorium .farbew,
.direktorium .farber,
.direktorium .farbev,
.direktorium .farbeg,
.direktorium .farbet {
  padding-left: 1.5rem;
}
.direktorium .farbew::before,
.direktorium .farber::before,
.direktorium .farbev::before,
.direktorium .farbeg::before,
.direktorium .farbet::before {
  content: "";
  display: block;
  border: 1px solid #989fa9;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 0.6rem;
}
.direktorium .farbew::before {
  background-color: #fff;
}
.direktorium .farber::before {
  background-color: #faa;
}
.direktorium .farbev::before {
  background-color: #dad;
}
.direktorium .farbeg::before {
  background-color: #afa;
}
.direktorium .farbet::before {
  border-color: transparent;
  background-color: inherit;
}
.direktorium .rangF .dheader {
  color: #585f69;
  font-weight: normal;
  margin-bottom: 0;
}
.direktorium .rangF.farbew::before, .direktorium .rangF.farber::before, .direktorium .rangF.farbev::before, .direktorium .rangF.farbeg::before, .direktorium .rangF.farbet::before {
  margin-bottom: 0.1rem;
}
.direktorium .rangH .dheader {
  color: #585f69;
  font-weight: bold;
  margin-bottom: 0;
}
.direktorium .rangH.farbew::before, .direktorium .rangH.farber::before, .direktorium .rangH.farbev::before, .direktorium .rangH.farbeg::before, .direktorium .rangH.farbet::before {
  margin-bottom: 0.2rem;
}
.direktorium .dheader {
  margin-bottom: 0;
}
.direktorium .dlesung {
  margin-top: 5px;
  font-size: 1.26rem;
  line-height: 1.35;
  color: #989fa9;
}
.direktorium .dlesung .dl1::before,
.direktorium .dlesung .dl2::before,
.direktorium .dlesung .dap::before,
.direktorium .dlesung .dev::before {
  display: inline-block;
  min-width: 100px;
}
.direktorium .dlesung .dl1::before {
  content: "1. Lesung: ";
}
.direktorium .dlesung .dl2::before {
  content: "2. Lesung: ";
}
.direktorium .dlesung .dap:before {
  content: "Antwortpsalm: ";
}
.direktorium .dlesung .dev:before {
  content: "Evangelium: ";
}

/** Liturgy calendar */
.ap-teaser.ap-service .ap.ap-list-group {
  margin-bottom: 10px;
}
.ap-teaser.ap-service [class~=ap][class~=ap-list-group]:last-of-type {
  margin-bottom: 0;
}
.ap-teaser.ap-service .ser-nocalendar .calendar-simple {
  font-size: 2rem;
  line-height: 1.25;
  margin-bottom: 0.5em;
}
.ap-teaser.ap-service .ser-btn {
  margin-top: 5px;
}
@media (min-width: 768px) {
  .ap-teaser.ap-service .ser-head {
    font-size: 1.8rem;
    line-height: 1.25;
  }
  .ap-teaser.ap-service .ser-nocalendar .calendar-simple {
    font-size: 2.4rem;
    line-height: 1.25;
  }
}

.ap-detail-page.ap-service .ser-head {
  margin-bottom: 10px;
}
.ap-detail-page.ap-service .ser-lead {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 1.4;
}
@media (min-width: 768px) {
  .ap-detail-page.ap-service .ser-lead {
    font-size: 2rem;
  }
}

/**********
 * Source file: quiz.scss
 */
.type-quiz.ap-detail-page {
  margin-bottom: 20px;
}
.type-quiz .quiz-start {
  padding-bottom: 20px;
}
.type-quiz .quiz-start,
.type-quiz .quiz-content {
  display: none;
}
.type-quiz .quiz-start.active,
.type-quiz .quiz-content.active {
  display: block;
}
.type-quiz .answers li,
.type-quiz .counter {
  margin-bottom: 10px;
}
.type-quiz .btn-quiz {
  font-weight: bold;
}
.type-quiz .next-item {
  margin-top: 20px;
  font-size: 120%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.type-quiz .next-item > .btn-quiz {
  display: inline;
}
.type-quiz .next-item > .btn-quiz::after {
  display: inline-block;
  font-family: FontAwesome;
  content: "";
  margin-left: 5px;
  color: #009bab;
  text-decoration: none;
}
.type-quiz .animation.enter {
  animation: fadeIn 1s;
}
.type-quiz .animation.enter.slow {
  animation: fadeIn 2s;
}
.type-quiz .animation.leave {
  animation: fadeOut 0.5s;
}
.type-quiz .animation.quick {
  animation: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/**********
 * Source file: mercury-compatibility.scss
 */
.ap-detail-page .visual {
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.ap-detail-page .visual .subtitle {
  margin-top: 10px;
  line-height: 1.15;
  font-style: italic;
}

/**********
 * Theme file: all-variables.scss
 *
 * ALL Bistuemers - Common CSS classes.
 */
.h1 a, .ap-header .bis-site .sitename h2 a, .h1 a:active, .h2 a, .onclick-activation .oa-heading a, .h2 a:active, .h3 a, .ap-compact .teaser-title a, .direktorium .rangH .dheader a, .h3 a:active, .h4 a, .image-info .subtitle.header a, .image-info .subtitle.hcentered a, .direktorium .rangF .dheader a, .h4 a:active,
h1 a,
h1 a:active, h2 a, h2 a:active, h3 a, h3 a:active, h4 a, h4 a:active,
.ap-teaser p.teaser-headline > a,
.ap-teaser p.teaser-headline > a:active {
  color: #585f69;
  outline: 0 !important;
}
.h1 a:focus, .ap-header .bis-site .sitename h2 a:focus, .h1 a:hover, .ap-header .bis-site .sitename h2 a:hover, .h2 a:focus, .onclick-activation .oa-heading a:focus, .h2 a:hover, .onclick-activation .oa-heading a:hover, .h3 a:focus, .ap-compact .teaser-title a:focus, .direktorium .rangH .dheader a:focus, .h3 a:hover, .ap-compact .teaser-title a:hover, .direktorium .rangH .dheader a:hover, .h4 a:focus, .image-info .subtitle.header a:focus, .image-info .subtitle.hcentered a:focus, .direktorium .rangF .dheader a:focus, .h4 a:hover, .image-info .subtitle.header a:hover, .image-info .subtitle.hcentered a:hover, .direktorium .rangF .dheader a:hover,
h1 a:focus,
h1 a:hover, h2 a:focus, h2 a:hover, h3 a:focus, h3 a:hover, h4 a:focus, h4 a:hover,
.ap-teaser p.teaser-headline > a:focus,
.ap-teaser p.teaser-headline > a:hover {
  text-decoration: none;
}

.ap-linksequence-hf ul.links li > a {
  font-size: 14px;
  color: #898989;
}

.ap-header .nav-sidelogo {
  margin: 5px 30px 5px 15px;
}
.ap-header .nav-sidelogo > .sidelogo {
  display: inline-block;
}
.ap-header .nav-sidelogo > .sidelogo .img-responsive {
  max-width: 265px;
}
@media (min-width: 992px) {
  .ap-header .bis-site {
    float: none;
    position: absolute;
    right: 0;
    bottom: -20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .ap-header .bis-site {
    padding-top: 60px;
  }
}
.ap-header .bis-site .sitename {
  float: right;
}
.ap-header .bis-site .sitename h2 {
  color: #898989;
  letter-spacing: 0.06em;
}
.ap-header .bis-logogroup {
  position: relative;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .ap-header .head.notfixed .bis-logogroup .bis-logo .ap-section.image-only {
    margin-bottom: 20px;
  }
}
@media (max-width: 991px) {
  .ap-header .bis-logogroup {
    margin-bottom: 0;
  }
  .ap-header .head-toggle {
    bottom: auto;
    top: 40px;
  }
  .ap-header .head-navbar {
    border-bottom: none;
  }
}
@media (max-width: 991px) and (min-width: 992px) {
  .ap-header .head-navbar .nav > li:nth-last-child(2) > a {
    border-bottom: solid 1px #eeeeee;
  }
}
.ap-header.bis-header-fullimage .bis-logogroup {
  margin-bottom: 0;
}
.ap-header.bis-header-fullimage .bis-site {
  bottom: 0;
}
.ap-header.bis-header-schmuckband .bis-schmuckband {
  margin-bottom: 0;
}
.ap-header.bis-header-schmuckband .bis-schmuckband .image-outer-box {
  background-color: #009bab;
  border-radius: 0;
}
.ap-header.bis-header-schmuckband .bis-schmuckband .image-outer-box .image-src-box {
  padding-bottom: initial !important;
  max-height: 50px;
  min-height: 50px;
}
.ap-header.bis-header-schmuckband .bis-schmuckband .image-outer-box .image-src-box .image-inner-box {
  opacity: 0.2;
  border-radius: 0;
  left: initial;
  right: 0;
  height: 50px;
  width: 691px;
  min-width: 691px;
}
@media (min-width: 768px) {
  .ap-header.bis-header-schmuckband .bis-schmuckband .image-outer-box .image-src-box {
    max-height: 60px;
    min-height: 60px;
    width: 750px;
    margin-left: auto;
    margin-right: auto;
  }
  .ap-header.bis-header-schmuckband .bis-schmuckband .image-outer-box .image-src-box .image-inner-box {
    height: 60px;
    width: 737px;
    min-width: 737px;
  }
}
@media (min-width: 992px) {
  .ap-header.bis-header-schmuckband .bis-schmuckband .image-outer-box .image-src-box {
    max-height: 80px;
    min-height: 80px;
    width: 970px;
  }
  .ap-header.bis-header-schmuckband .bis-schmuckband .image-outer-box .image-src-box .image-inner-box {
    height: 80px;
    width: 1106px;
    min-width: 1106px;
  }
}
@media (min-width: 1200px) {
  .ap-header.bis-header-schmuckband .bis-schmuckband .image-outer-box .image-src-box {
    width: 1170px;
    margin-left: auto;
    margin-right: auto;
  }
}
.ap-header.bis-header-schmuckband .sticky .isfixed .bis-site {
  bottom: 0;
}
.ap-header.bis-header-schmuckband .sticky .isfixed .bis-logogroup {
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .ap-header.bis-header-schmuckband .head-container {
    padding-bottom: 0;
  }
}
.ap-header .breadcrumbs ul.breadcrumb {
  padding: 14px 0;
}

.isfixed .head-visual .head-container {
  padding-top: 0;
  padding-bottom: 0;
}
.isfixed .head-visual .head-container .head-visual-container .head-visual-group {
  margin-bottom: 0;
}
.isfixed .head-visual .head-container .head-visual-right {
  bottom: 0;
}
.isfixed .head-visual .head-container .head-visual-row.bis-top,
.isfixed .head-visual .head-container .head-visual-row.bis-bottom {
  display: none;
}

.area-foot .headline {
  border-bottom: none;
  margin-bottom: 7px;
}
.area-foot .headline h1,
.area-foot .headline h2,
.area-foot .headline h3,
.area-foot .headline h4 {
  display: block;
  border-bottom: 2px solid #009bab;
}

@media (max-width: 991px) {
  .ap-nav-header > .ap-social {
    float: right;
    margin-right: 30px;
    margin: 4px 30px 0 0;
  }
}
#apollo-page .ap.type-rs > .btn-wrapper {
  min-height: 30px;
}
#apollo-page .ap.type-rs > .btn-wrapper > .rsbtn {
  line-height: 1;
  padding-bottom: 0 !important;
}
#apollo-page .ap.type-rs > .btn-wrapper .rsbtn_play:not([data-rsevent-id]) {
  color: transparent;
}
#apollo-page .ap.type-rs.btn-right, #apollo-page .ap.type-rs.btn-center {
  display: flex;
}
#apollo-page .ap.type-rs.btn-right > .btn-wrapper .rsbtn.rsexpanded .rsbtn_exp, #apollo-page .ap.type-rs.btn-center > .btn-wrapper .rsbtn.rsexpanded .rsbtn_exp {
  display: inline-block;
}
#apollo-page .ap.type-rs.btn-right {
  justify-content: flex-end;
}
#apollo-page .ap.type-rs.btn-center {
  justify-content: center;
}
#apollo-page .ap.type-rs.presized > .btn-wrapper {
  max-height: 30px;
}
#apollo-page .ap.type-rs .external-cookie-notice {
  padding: 2px 5px;
  justify-content: flex-start;
}

.ap.type-wallsio:not(.external-cookie-notice).remove-margin .wallsio-container {
  margin-left: -21px;
  margin-right: -21px;
}
.ap.type-wallsio:not(.external-cookie-notice).remove-margin .wallsio-container .wallsio-load-more-button {
  margin-left: 21px;
}
.headline * {
  color: #009bab;
}

.area-foot .ap-download-list .dl-content .dl-file {
  display: none;
}
.area-foot .ap-download-list .dl-info .dl-date {
  display: none;
}
.area-foot .ap-download-list .dl-dl {
  display: none !important;
}

.ap-google-map .mapbuttons .btn:nth-of-type(2), .ap-google-map .mapbuttons .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button:nth-of-type(2), .ap.type-wallsio:not(.external-cookie-notice) .ap-google-map .mapbuttons .wallsio-load-more-button:nth-of-type(2) {
  background-color: #00565f;
}
.ap-google-map .mapbuttons .btn:nth-of-type(3), .ap-google-map .mapbuttons .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button:nth-of-type(3), .ap.type-wallsio:not(.external-cookie-notice) .ap-google-map .mapbuttons .wallsio-load-more-button:nth-of-type(3) {
  background-color: #00565f;
}
.ap-google-map .mapbuttons .btn:nth-of-type(3), .ap-google-map .mapbuttons .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button:nth-of-type(3), .ap.type-wallsio:not(.external-cookie-notice) .ap-google-map .mapbuttons .wallsio-load-more-button:nth-of-type(3) {
  background-color: #00565f;
}
.ap-google-map .mapbuttons .btn:nth-of-type(2), .ap-google-map .mapbuttons .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button:nth-of-type(2), .ap.type-wallsio:not(.external-cookie-notice) .ap-google-map .mapbuttons .wallsio-load-more-button:nth-of-type(2) {
  background-color: #00565f;
}
.ap-google-map .mapbuttons .btn:nth-of-type(3), .ap-google-map .mapbuttons .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button:nth-of-type(3), .ap.type-wallsio:not(.external-cookie-notice) .ap-google-map .mapbuttons .wallsio-load-more-button:nth-of-type(3) {
  background-color: #00565f;
}
.ap-google-map .mapbuttons .btn:nth-of-type(3), .ap-google-map .mapbuttons .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button:nth-of-type(3), .ap.type-wallsio:not(.external-cookie-notice) .ap-google-map .mapbuttons .wallsio-load-more-button:nth-of-type(3) {
  background-color: #00565f;
}

.ap-header .bis-top .ap-linksequence-hf {
  margin-top: 5px;
}
.ap-header .bis-top .ap-linksequence-hf ul.links li > a {
  font-size: 12px;
}
.ap-header .isfixed .isfixed-hidden,
.ap-header .isfixed .ap-slick-slider {
  display: none;
}
.ap-header .breadcrumbs ul.breadcrumb {
  float: right !important;
}

@media (min-width: 992px) {
  .ap-header .bis-logogroup {
    display: flex;
  }
  .ap-header .bis-logogroup .bis-logo .ap-image-section .ap-image .image-src-box {
    height: auto;
    padding-bottom: 0 !important;
  }
  .ap-header .bis-logogroup .bis-logo .ap-image-section .ap-image .image-src-box .image-inner-box {
    max-height: 250px;
    width: 100%;
    position: relative;
  }
  .ap-header .bis-logogroup .bis-site {
    position: relative;
    align-self: flex-end;
    margin-top: 50px;
  }
  .ap-header .bis-logogroup .bis-site .sitename {
    text-align: right;
  }
}
.area-foot .subfoot .ap-linksequence-row {
  margin-bottom: 0;
}

.ap-teaser .teaser-body .teaser-title {
  font-size: 1.68rem;
}
.headline * {
  color: #009bab;
}

a.external:after {
  font-size: 0.6em;
  vertical-align: super;
  padding-left: 0.2em;
  display: inline-block;
}

.direktorium {
  font-weight: 400;
  line-height: 1.5;
}
.direktorium .rangg, .direktorium .rangg .dheader {
  font-size: 1.2rem;
  color: #313131;
}
.direktorium .rang, .direktorium .rang .dheader,
.direktorium .rangG,
.direktorium .rangG .dheader {
  font-size: 1.6rem;
  color: #313131;
}
.direktorium .rangF, .direktorium .rangF .dheader,
.direktorium .rangH,
.direktorium .rangH .dheader {
  font-size: 1.8rem;
  color: #313131;
}
.direktorium .farbeg,
.direktorium .farber,
.direktorium .farbev,
.direktorium .farbew {
  padding-left: 1.25rem;
}
.direktorium .farbeg:before,
.direktorium .farber:before,
.direktorium .farbev:before,
.direktorium .farbew:before {
  border: none;
  border-radius: 0;
  height: 1em;
  width: 5px;
  top: 0.25em;
}
.direktorium .farbeg:before {
  background-color: #01842d;
}
.direktorium .farber:before {
  background-color: #a50202;
}
.direktorium .farbev:before {
  background-color: #660099;
}
.direktorium .farbew:before {
  background-color: #fff;
  border: 1px solid #989fa9;
}

.ap-list-group .ser-head a {
  color: #009bab;
}
.ap-list-group .ser-loc a {
  color: #585f69;
}

.ap-slick-slider .ap-slick-slide.custom .caption.center strong,
.ap-slick-slider .ap-slick-slide.custom .caption.center small {
  float: left;
  width: 100%;
}

.privacy-policy-banner .banner {
  background-color: rgba(37, 37, 37, 0.9);
}
.privacy-policy-banner .banner .title {
  font-size: 2rem;
}
.privacy-policy-banner .banner .buttons .btn, .privacy-policy-banner .banner .buttons .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .privacy-policy-banner .banner .buttons .wallsio-load-more-button {
  border-radius: 2px;
  font-size: 1.5rem;
  line-height: 1em;
}
.privacy-policy-banner .banner .buttons .btn-decline {
  background-color: #b83232;
}
.privacy-policy-banner .banner .buttons .btn-decline:hover, .privacy-policy-banner .banner .buttons .btn-decline:focus {
  background-color: #cf4e4e;
  color: #fff;
}
.privacy-policy-banner .banner .buttons .btn-accept {
  background-color: #28ad61;
}
.privacy-policy-banner .banner .buttons .btn-accept:hover, .privacy-policy-banner .banner .buttons .btn-accept:focus {
  background-color: #37d179;
  color: #fff;
}
.privacy-policy-banner .banner .message {
  font-size: 1.3rem;
}
.privacy-policy-banner .banner .message a {
  text-decoration: underline;
  font-weight: initial;
}

.privacy-policy-banner--light .banner {
  background-color: #eee;
  border-top: 3px solid #009bab;
}
.privacy-policy-banner--light .banner * {
  color: #585f69;
}
.privacy-policy-banner--light .banner .title {
  font-size: 2rem;
}
.privacy-policy-banner--light .banner .buttons .btn, .privacy-policy-banner--light .banner .buttons .ap.type-wallsio:not(.external-cookie-notice) .wallsio-load-more-button, .ap.type-wallsio:not(.external-cookie-notice) .privacy-policy-banner--light .banner .buttons .wallsio-load-more-button {
  border-radius: 2px;
  font-size: 1.5rem;
  line-height: 1em;
}
.privacy-policy-banner--light .banner .buttons .btn-decline {
  background-color: #b83232;
}
.privacy-policy-banner--light .banner .buttons .btn-decline:hover, .privacy-policy-banner--light .banner .buttons .btn-decline:focus {
  background-color: #cf4e4e;
  color: #fff;
}
.privacy-policy-banner--light .banner .buttons .btn-accept {
  background-color: #28ad61;
}
.privacy-policy-banner--light .banner .buttons .btn-accept:hover, .privacy-policy-banner--light .banner .buttons .btn-accept:focus {
  background-color: #37d179;
  color: #fff;
}
.privacy-policy-banner--light .banner .message {
  font-size: 1.3rem;
}
.privacy-policy-banner--light .banner .message a {
  text-decoration: underline;
  font-weight: initial;
}

#privacy-policy-toggle .toggle-box {
  border: none;
  border-radius: 5px;
}
#privacy-policy-toggle .toggle-box .toggle-inner:before {
  background-color: #28ad61;
  color: #fff;
}
#privacy-policy-toggle .toggle-box .toggle-inner:after {
  background-color: #b83232;
  color: #fff;
}
#privacy-policy-toggle .toggle-box .toggle-slider {
  border-radius: 5px;
  border: none;
}

.stu_header {
  margin-bottom: 45px;
}
.stu_header_start {
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .stu_header {
    border-bottom: 15px solid #009bab;
  }
}
.stu_header_bistumslinks {
  margin-bottom: 0;
}
.stu_header_logoarea {
  transition: max-height 2s ease;
  max-height: 250px;
  overflow: hidden;
}
.stu_header_logoarea .bis-slider {
  display: flex;
}
.stu_header_logo {
  width: 95px;
  margin-top: -10px;
  margin-bottom: -1px;
}
@media (max-width: 767px) {
  .stu_header_logo {
    margin-top: 5px;
  }
}
.stu_header_text {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 0;
  padding-bottom: 40px;
}
@media (max-width: 991px) {
  .stu_header_text {
    padding-bottom: 30px;
  }
}
.stu_header_text .head {
  min-height: 0;
}
.stu_header_text .head > * {
  color: #585f69;
  font-size: 1.5rem;
  margin-bottom: 0;
}
.stu_header_text .text {
  margin-top: 0 !important;
}
.stu_header_text .text h1, .stu_header_text .text h2, .stu_header_text .text h3, .stu_header_text .text h4, .stu_header_text .text h5 {
  color: #009bab;
  font-size: 3rem;
  margin-bottom: 0;
  font-weight: 800;
}
.stu_header_text .text p {
  font-size: 1.5rem;
  margin-top: -0.2rem;
  margin-bottom: 0;
  font-weight: 400;
}
.stu_header_secondnav {
  position: absolute;
  bottom: 0;
  margin-bottom: 5px;
  padding-right: 30px;
}
.stu_header_secondnav ul.links li > a {
  font-size: 12px;
}
.stu_header .ap-slick-slider {
  margin-bottom: 0;
}
.stu_header .nav-sidelogo .img-responsive {
  width: 400px;
  padding: 1rem;
}
.stu_header .isfixed .stu_header_logoarea {
  max-height: 0;
  transition: max-height 0s;
}
.stu_header .breadcrumbs ul.breadcrumb {
  padding: 1rem 0;
}

.ap-header .head-navbar {
  background-color: #009bab;
}
@media (max-width: 991px) {
  .ap-header .head-navbar .nav > li > a {
    background-color: #009bab;
  }
  .ap-header .head-navbar .nav > li > a.dropdown-toggle::after {
    background-color: transparent !important;
  }
  .ap-header .head-navbar .nav > li:hover > a, .ap-header .head-navbar .nav > li.active > a {
    background-color: #00565f;
  }
  .ap-header .head-navbar .nav > li.open > a {
    background-color: #fff;
  }
  .ap-header .head-navbar .nav > li.open > a.dropdown-toggle::after {
    color: #009bab !important;
  }
  .ap-header .head-navbar .nav .head {
    padding-left: 15px;
  }
  .ap-header .head-navbar .nav .head h1, .ap-header .head-navbar .nav .head h2, .ap-header .head-navbar .nav .head h3, .ap-header .head-navbar .nav .head h4, .ap-header .head-navbar .nav .head h5 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-top: 1rem;
  }
}

.stu_area {
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-bottom: 2rem;
}
.stu_area:nth-of-type(even) {
  background-color: #eee;
}
.stu_area_title {
  text-align: center;
}
.stu_area_title .headline {
  border-bottom: 0;
}
.stu_area_title .headline h1, .stu_area_title .headline h2, .stu_area_title .headline h3 {
  font-size: 34px;
  padding-bottom: 20px;
}
.stu_area_news .list-append .list-entries {
  margin-bottom: 0 !important;
}
.stu_area_news .list-append .list-pagination {
  margin-bottom: 40px;
}
.stu_area_dates .ap-list-content {
  margin-bottom: 0;
}
.stu_area_dates .calendar-sheet {
  height: 100%;
}
.stu_area_dates .list-entry  {
  width: 50%;
  display: inline-block;
  padding-right: 45px;
  padding-left: 15px;
}
@media (max-width: 991px) {
  .stu_area_dates .list-entry  {
    width: 100%;
  }
}
.stu_area_dates .list-entry  .ap-teaser {
  background: #F9F9F9;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
@media (max-width: 991px) {
  .stu_area_dates .list-entry  .ap-teaser {
    flex-wrap: wrap;
  }
}
.stu_area_dates .list-entry  .ap-teaser .teaser-visual {
  margin-bottom: 0;
  padding-left: 0;
}
.stu_area_dates .list-entry  .ap-teaser .teaser-body {
  margin-top: 15px;
  padding-right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.stu_area_dates .list-entry  .ap-teaser .teaser-body .ap-list-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.stu_area_dates .list-entry  .ap-teaser .teaser-body .ap-list-group .fully {
  display: flex;
  flex-direction: column;
  padding-right: 15px;
}
.stu_area_dates .list-entry  .ap-teaser .teaser-body .ap-list-group .fully .teaser-date {
  order: 1;
}
.stu_area_dates .list-entry  .ap-teaser .teaser-body .ap-list-group .fully .teaser-title {
  order: 2;
}
.stu_area_dates .list-entry  .ap-teaser .teaser-body .ap-list-group .teaser-btn {
  align-self: flex-end;
}
.stu_area_partner .ap-slick-slider {
  margin-bottom: 0;
}

/*# sourceMappingURL=style-aachen-custom_gym-st-ursula.css.map */